import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import theme from '../../styles/theme.styles'
import { Formik, Field } from 'formik'
import Stepper from '../../components/_FormElements/Stepper'
import InputCheckbox from '../../components/_FormElements/InputCheckBox'
import Popup from '../../components/_FormElements/Popup'
import LegalText from './LegalText'
import {createCoach} from '../../custom_graphql_queries/coachs'
import { changeIsActive, signUp, updateSDKToken } from '../../services/authService'
import { API } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';

import {
    FormLayout,
    HeaderTitle,
    HeaderInfoText,
    Form,
    Button,
    ErrorText,
    OpenModal,
    Text,
    DisclaimerText,
} from './OnBoarding.style'
import { listAuxCollegeCoachInfos } from '../../custom_graphql_queries/auxCollegeCoachInfo'
import { listTeamsWithNoSubscription } from '../../custom_graphql_queries/teams'

const AcceptTermsStep = ({
        setStep, 
        step, 
        data, 
        setData,
        stepData,
        setStepData
    }) => {   

    const [termshavebeenreading, setTermshavebeenreading] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [errorTextValue, setErrorTextValue] = useState(false)
    const [waitingToSignUp, setWaitingToSignUp] = useState(false)
    const [teamsList, setTeamsList] = useState([])
    const [auxCollegeCoachInfo, setAuxCollegeCoachInfo] = useState([])

    const onChangeValueFormik = ({form, fieldName, value}) => {
		form.setFieldValue(fieldName, value)
	}
    const handleReadAcceptTerms = () => {
        setIsOpen(true);
        // setTermshavebeenreading(true)
    };

    const InputCheckboxFieldAcceptTerms = ({ field, colors, form, ...props }) => {
        const { values, errors } = form
    
        return (
            <>
            <InputCheckbox value={values.acceptTerms} fieldName={'acceptTerms'} formikForm={form} onChangeValueFormik={onChangeValueFormik} disabled={!termshavebeenreading} textAlign='center' termshavebeenreading={termshavebeenreading}>
                <Text>I have read and I approve the Alliance Athletes App</Text> <OpenModal onClick={handleReadAcceptTerms}>privacy terms</OpenModal>
            </InputCheckbox>
            </>				
        )
    }

    // I don't understand SignupSchema
    // const SignupSchema = Yup.object().shape({
    //     acceptTerms: Yup.bool().oneOf([true], 'Please, make sure you have read and approved the privacy terms')
    // })

    // Fetch aux information about coaches
    const fetchAuxCollegeCoachInfo = async() => {
        try{
            
            let auxCollegeCoach = [];
            let response = {};
            let params = {
                query: listAuxCollegeCoachInfos,
                authMode: 'API_KEY',
                apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY,
                variables: {
                    limit: 500
                }
            }

            response = await API.graphql(params)
            auxCollegeCoach = response.data.listAuxCollegeCoachInfos.items
            
            while(response.data.listAuxCollegeCoachInfos.nextToken) { 
                params.variables.nextToken = response.data.listAuxCollegeCoachInfos.nextToken;
                response = await API.graphql(params);
                auxCollegeCoach = [...auxCollegeCoach, ...response.data.listAuxCollegeCoachInfos.items];
            }
    
            return auxCollegeCoach
        } catch(error) {console.log('[ERROR] Fetch Aux College Coach Info: ',error)}
    }

    // Fetch aux information about teams
    const fetchTeamsInfo = async() => {
        try{
            let teams = [];
            let response = {};
            let params = {
                query: listTeamsWithNoSubscription,
                authMode: 'API_KEY',
                apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY,
                variables: {
                    limit: 500
                }
            }

            response = await API.graphql(params)
            teams = response.data.listTeams.items
            
            while(response.data.listTeams.nextToken) { 
                params.variables.nextToken = response.data.listTeams.nextToken;
                response = await API.graphql(params);
                teams = [...teams, ...response.data.listTeams.items];
            }
            
            return teams

        } catch(error) {console.log('[ERROR] Fetch Teams information: ',error)}
    }

    const createProfile = async(data, profile_id) => {
        // Get coach data
        const { firstname, email, division, gender, password, phone, role, whatsapp } = data;

        // Direct register
        const email_domain = email.split('@').pop()
        const email_extension = email.split('.').pop()
        // Check if the coach is already pre-registered in AuxCollegeCoachInfo
        let email_preregistered = auxCollegeCoachInfo?.find(item => item.contact_email === email)

        // First find the team by the school name and the one that matches the sport program
        let teamObj = teamsList?.find(item => item.name.includes(email_preregistered?.school) && item.genre === (email_preregistered?.sport === "Men's Soccer" ? 'male' : 'female')) 
        // If we don't find the team by the school name we can to find it by the url_domain
        if(teamObj === undefined) {
            // The condition item.url_domain === email_domain can return more than one team with the same domain so we need to filter by program and school name
            const teamsByDomain = teamsList?.filter(item => item.url_domain === email_domain)
            if(teamsByDomain.length > 1) {
                // we need to find the team program that matches the school program
                teamObj = teamsByDomain?.find(item => item.genre === (email_preregistered?.sport === "Men's Soccer" ? 'male' : 'female') && item.name.includes(email_preregistered?.school))
            }
        }

        const profile_data = {
            id: profile_id,
            name: firstname,
            ...(teamObj !== undefined && email_extension === "edu" && { division: teamObj.division }),
            ...(teamObj !== undefined && email_extension === "edu" && {
                gender: teamObj.genre === "Men" ? "male" : "female",
            }),
            contact_email: email,
            contact_phone: phone,
            allow_whatsapp_contact: whatsapp,
            role: role,
            // If the coach is not validated and doesn't have .edu --> not active and we can find the real team
            is_active: (email_preregistered !== undefined && email_extension === 'edu' && teamObj !== undefined) ? true : false,
            teamID: (teamObj !== undefined) ? teamObj.id : '0e231f9a-73f6-4476-ad38-adb3f93c2513',
            // Soccer web 
            sport: 'Soccer'
        }
        
        try{
            const res = await API.graphql({
                query: createCoach,
                variables: { input: profile_data },
                authMode: 'API_KEY',
                apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY,
            })
            return res
        } catch(error) {
            console.log("[ERROR] Create profile: ",error)
            throw new Error(error)
        }
    }

    // First render
    useEffect(()=>{
        fetchTeamsInfo().then(res=>{setTeamsList(res)})
        fetchAuxCollegeCoachInfo().then(res=>{setAuxCollegeCoachInfo(res)})
    },[])

    return (
        <FormLayout>
            {isOpen && <Popup
                isOpen={isOpen}
                bgcolor={theme.colors.brandDark}
                textAlign={'left'}
                content={<LegalText setTermshavebeenreading={setTermshavebeenreading} setIsOpen={setIsOpen}/>}
                handleClose={()=>setIsOpen(!isOpen)}
            />}
            <HeaderTitle>Privacy Terms</HeaderTitle>
                <HeaderInfoText>You must read and agree with the privacy terms before moving forward</HeaderInfoText>
                {errorTextValue && <ErrorTextResponse>{errorTextValue}</ErrorTextResponse>}
                <Formik
                    initialValues={{ terms: '' }}
                    onSubmit={async (values, { setSubmitting }) => {
                        const profile_id = uuidv4()
                        const { email, password } = data;

                        setWaitingToSignUp(true) // To avoid multiple click in submit button

                        // Direct register refactor
                        signUp(email, password, profile_id).then((responseCognito) => {
                            // Cognito profile created correctly
                            console.log("[OK] Create profile cognito:",responseCognito)
                            createProfile(data, profile_id).then((dynamodbProfile) => {
                                // DynamoDB profile created correctly
                                console.log("[OK] Create profile dynamodb: ",dynamodbProfile)
                                
                                setStepData({
                                    email: email,
                                    password: password,
                                    responseCognito: responseCognito,
                                    dynamodbProfile: dynamodbProfile
                                })
                                setSubmitting(false);
                                setWaitingToSignUp(false)
                                setStep(step + 1);

                            }).catch((error) => {
                                console.log("[ERROR] Create profile dynamodb: ",error)
                                setErrorTextValue(error.message)
                                throw new Error(error)

                            }) 
                        }).catch((error) => {
                            console.log("[ERROR] Create profile cognito:",error)
                            setErrorTextValue(error.message)
                            throw new Error(error)

                        })                       
                    }}
                    validate={values => {
                        const errors = {};
                        if (!values.acceptTerms)
                            errors.acceptTerms = 'Please, make sure you have read and approved the privacy terms';
                        
                        return errors;
                    }}
                >
                {({ handleChange, handleBlur, handleSubmit, errors,  values, isSubmitting, touched }) => (
                    <Form onSubmit={handleSubmit}>
                        <Field name="acceptTerms" component={InputCheckboxFieldAcceptTerms} />
                        <DisclaimerText>
                        To check the box, click on the words "privacy terms" above
                        </DisclaimerText>
                        {touched.acceptTerms && errors.acceptTerms && (<ErrorText>{errors.acceptTerms}</ErrorText>)}
                        <Button type="submit" disabled={waitingToSignUp} step={step}>Next</Button>
                        <Stepper active={step - 1} />
                    </Form>
                )}
                </Formik>
        </FormLayout>
    )

}

export default AcceptTermsStep;

const ErrorTextResponse = styled(ErrorText)`
    margin-top: 30px;
`;
