import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme.styles';

function Popup(props) {
  const {
    bgcolor = theme.colors.royalBlue,
    textColor = theme.colors.white,
    textAlign = 'left',
    isOpen = null,
    hasCloseButton = true,
  } = props;

  const [visible, setVisible] = useState(null);
  setTimeout(() => {
    setVisible(props.isOpen);
  }, 100);

  return (
    <PopupBox state={visible}>
      <Box bgcolor={bgcolor}>
        {
          hasCloseButton && (
            <CloseIcon onClick={props.handleClose}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.717 1.697L12.303 0.283001L7 5.586L1.697 0.283001L0.283005 1.697L5.58601 7L0.283005 12.303L1.697 13.717L7 8.414L12.303 13.717L13.717 12.303L8.414 7L13.717 1.697Z" fill="white" />
              </svg>
            </CloseIcon>
          )
        }
        <BoxContent textAlign={textAlign}>{props.content}</BoxContent>
      </Box>
    </PopupBox>
  );
}

const PopupBox = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
    height: 100%;
	margin: 0;
    padding: 0;
	top: 0;
	left: 0;
	Z-index: 9999;
	color: ${theme.colors.white};
	margin: 0 auto;
	${(props) => (props.state === true ? 'transition: opacity 0.3s;' : 'opacity: 0;')}
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    ${(props) => (props.state === true ? 'display: flex;' : 'display: none;')}
    align-items: center;
    justify-content: center;
`;
const Box = styled.div`
	width: auto;
	background: ${(props) => props.bgcolor};
	position: relative;
	max-width: 80%;
	max-height: 80%;
`;
const BoxContent = styled.div`
    padding: 30px;
    width: 100%;
    height: 20%;
    color: white;
	text-align: ${(props) => props.textAlign};
`;
const CloseIcon = styled.span`
	cursor: pointer;
	position: absolute;
	right: 3%;
	top:  5%;
	width: 25px;
	height: 25px;
	line-height: 20px;
	text-align: center;
	font-size: 20px;
`;

export default Popup;
