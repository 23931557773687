import React, { useContext, useEffect } from 'react'
import { DataContext } from '../../contexts/dataContext'
import { useLocation, useNavigate } from 'react-router-dom'

import styled from 'styled-components'
import theme from '../../styles/theme.styles'

import { API } from 'aws-amplify'
import { updateTeam } from '../../custom_graphql_queries/teams'
import {
  getStripeCustomerID,
  listStripeSubscriptions,
} from '../../services/stripeServices'

import Layout from '../../components/_Layout/Layout'
import RightColumn from '../../components/_Layout/RightColumn'
import ContentWrapper from '../../components/_Layout/ContentWrapper'
import Button from '../../components/_FormElements/Button'

const Success = () => {
  const dataContext = useContext(DataContext)

  const coachIdFromPath = useLocation().pathname.split('/').at(-1)
  const navigate = useNavigate()

  useEffect(() => {
    const updateSuscriptionData = async () => {
      const stripeId = await getStripeCustomerID().then(
        (res) =>
          res.data.find(
            (it) => it.email === dataContext.userCoachData?.contact_email
          )?.id
      )
      //We need to wait until context is loadded
      if (
        dataContext.userCoachData &&
        (dataContext.userCoachData?.team?.suscription_name === 'FREE_PERIOD' ||
          dataContext.userCoachData?.team?.suscription_name === 'SEASON_PASS')
      ) {
        // If a user tries the url success/<coachId> and is not himself or does not have a stripe customer created
        // ==> He has tried to access this page incorrectly!!
        if (!stripeId || dataContext.userCoachData?.id !== coachIdFromPath) {
          navigate('/app/')
        } else {
          // Get all the subscriptions
          const stripeSubscriptions = await listStripeSubscriptions().then(
            (res) => res.data
          )
          // Get the latest subscription associated with this coach
          const actualSubscription = stripeSubscriptions.find(
            (it) =>
              new Date(it.created * 1000).toLocaleDateString() ===
                new Date().toLocaleDateString() && it.customer === stripeId
          )

          // Check Recurrency
          const startDate = new Date(
            actualSubscription.current_period_start * 1000
          )
          const endDate = new Date(actualSubscription.current_period_end * 1000)

          // Calcula la diferencia en días
          const diffDays = Math.floor(
            (endDate - startDate) / (1000 * 60 * 60 * 24)
          )

          // Define el tipo de suscripción según la diferencia de días
          let subscriptionType = ''
          if (diffDays >= 364) {
            subscriptionType = 'ANNUAL'
          } else if (diffDays >= 28) {
            // Consideramos un mes como 28 días para simplificar
            subscriptionType = 'MONTHLY'
          }

          // Update team
          try {
            await API.graphql({
              query: updateTeam,
              variables: {
                input: {
                  id: dataContext.userCoachData?.teamID,
                  suscription_name: 'PREMIUM_PASS',
                  suscription_stripe_id: actualSubscription?.id,
                  suscription_premium_start_date: new Date(
                    actualSubscription.current_period_start * 1000
                  )
                    .toJSON()
                    .split('T')[0],
                  suscription_premium_end_date: new Date(
                    actualSubscription.current_period_end * 1000
                  )
                    .toJSON()
                    .split('T')[0],
                  subscriberID: dataContext.userCoachData?.id,
                  suscription_recurrency: subscriptionType,
                  subscription_initial_date: new Date(
                    actualSubscription.current_period_start * 1000
                  )
                    .toJSON()
                    .split('T')[0],
                },
              },
            })
              .then(() => {
                dataContext.setUserCoachData({
                  ...dataContext.userCoachData,
                  team: {
                    ...dataContext.userCoachData.team,
                    suscription_name: 'PREMIUM_PASS',
                    suscription_stripe_id: actualSubscription?.id,
                    suscription_premium_start_date: new Date(
                      actualSubscription.current_period_start * 1000
                    )
                      .toJSON()
                      .split('T')[0],
                    suscription_premium_end_date: new Date(
                      actualSubscription.current_period_end * 1000
                    )
                      .toJSON()
                      .split('T')[0],
                  },
                })
                window.location.reload(true)
              })
              .catch((error) => {
                console.log('[ERROR] Updating team to premium:', error)
              })
          } catch (e) {
            console.log('[ERROR] Updating team to premium:', e)
          }
        }
      }
    }

    updateSuscriptionData()
  }, [dataContext.userCoachData])

  return (
    <Layout>
      <RightColumn />
      <ContentWrapper>
        <CenterContainer>
          <HeaderSpacer />
          <Header>
            <Title>Subscription success!</Title>
          </Header>
          <Body>
            <BodyTitle>
              We have successfully registered your subscription. The Premium
              version of Alliance Athletes includes features such as:
            </BodyTitle>
            <List>
              <ListItem>Exclusive filters</ListItem>
              <ListItem>Additional PSA information</ListItem>
              <ListItem>Organise your favourite PSAs in folders</ListItem>
              <ListItem>And much more</ListItem>
            </List>
          </Body>
          <Button
            onClick={() => navigate('/app/')}
            label="Try out premium version"
          />
        </CenterContainer>
      </ContentWrapper>
    </Layout>
  )
}

export default Success

const CenterContainer = styled.div`
  position: relative;
`

const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`
const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  width: 100%;
  top: 0;
  left: 0;
  background: rgb(6, 14, 40);
  background: linear-gradient(
    180deg,
    rgba(6, 14, 40, 1) 0%,
    rgba(6, 14, 40, 1) 89%,
    rgba(6, 14, 40, 0) 100%
  );
  z-index: 2;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`
const Title = styled.h1`
  margin: 30px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5% 0;
`
const BodyTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.35px;
`
const List = styled.ul`
  list-style-type: circle;
  padding: 20px;
  margin-left: 20px;
`
const ListItem = styled.li`
  font-size: 16px;
  line-height: 41px;
  letter-spacing: 0.374px;
`
