import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import theme from '../../styles/theme.styles'
import PropTypes from 'prop-types'
import { Collapse } from 'react-collapse'
import { DataContext } from '../../contexts/dataContext'
import { Helmet } from "react-helmet";

import {
  getAge,
  positionHumanize,
  convertToUserUnitHeight,
  positionAcronym,
  phoneSplitToCountryCodeAndNumber,
} from '../../helpers';
import { getImageWithEffects, getImageAtSize } from '../../helpers/cloudinaryHelpers';

import InfoBitBox from './InfoBitBox';
import InfoBitRow from './InfoBitRow';
import InfoCard from './InfoCard';
import MediaBox from './MediaBox';
import ScoreBox from './ScoreBox';
import Button from '../_FormElements/Button';
import TextAreaForm from '../_FormElements/TextAreaForm';

import InfoSmallAgency from '../Agencies/InfoSmallAgency';
import Agent from '../Agencies/Agent';
import { Tooltip } from '@mui/material'
import shareIcon from '../../assets/icons/share-white.png'

function AthleteInfo({ athlete, favoriteToggle }) {
  // const [isRequestInfoFormOpen, setRequestInfoFormOpen] = useState(false);
  const dataContext = useContext(DataContext);
  const isPremium =   (dataContext.userCoachData?.team?.suscription_name === 'PREMIUM_PASS' || (dataContext.userCoachData?.team?.suscription_name === 'SEASON_PASS' && dataContext.userCoachData?.team?.had_trial !== null))

  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => { setOpen(true); };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [open]);

  // Moved to AthleteView.js
  // const onRequestInfoSubmit = async (values, isSubmiting) => {
  //   try {
  //     const execDataContentFunction = await dataContext.requestInfoOfAthleteFormSend(
  //       { message: values.message, athleteId: athlete.id },
  //     );
  //     if (execDataContentFunction) {
  //       return true;
  //     }
  //     return false;
  //   } catch (error) {
  //     return false;
  //   }
  // };

  let availablePic = athlete.card_pic || athlete.profile_pic;
  if (availablePic) {
    availablePic = (athlete.current_status === 'COMMITTED') ? getImageAtSize({ url: getImageWithEffects(availablePic), w: 350, h: 500 }) : getImageAtSize({ url: availablePic, w: 350, h: 500 });
    // availablePic.replaceAll(
    //   'image/upload/',
    //   'image/upload/c_thumb,e_auto_brightness,w_350,h_500/'
    // )
  }
  const profileImage = availablePic || (athlete.gender === 'female'
    ? '/images/avatar_girl_horizontal.svg'
    : '/images/avatar_boy_horizontal.svg');

  const athleteFormated = {
    header: {
      name: athlete.name,
      mainImage: profileImage,
      birthdate: new Date(athlete.birthdate).getFullYear(),
    },

    infoBitGrid: {
      ...(athlete.position ? { position1: positionAcronym(athlete.position) } : { position1: '-' }),
      ...(athlete.second_position ? { position2: positionAcronym(athlete.second_position) } : { position2: '-' }),
      ...(athlete.height ? { height: convertToUserUnitHeight(athlete.height) } : { height: '-' }),
      ...(athlete.height ? { heightCm: `${athlete.height}cm` } : {}),
      semester: athlete.start_date?.split('_').join(' '),
      satScore: athlete.sat_score ? String(athlete.sat_score) : '-',
      ...(athlete.estimated_gpa ? { gpa: athlete.estimated_gpa } : { gpa: '-' }),
    },
    education: {
      sat: {
        title: 'SAT score',
        min: 800,
        max: 1600,
        score: athlete.sat_score,
      },
      toefl: {
        title: 'TOEFL score',
        min: 0,
        max: 120,
        score: athlete.toefl_score,
      },
      duolingo: {
        title: 'Duolingo score',
        min: 0,
        max: 160,
        ...(athlete.duolingo ? { score: String(athlete.duolingo) } : { score: '-' }),
      },
      gpa: {
        title: 'GPA',
        min: 0,
        max: 4.0,
        ...(athlete.estimated_gpa ? { score: String(athlete.estimated_gpa) } : { score: '-' }),
        float: true,
      },
    },
    gallery: {
      hasVideo: Boolean(athlete.featured_video),
      featured_video: athlete.featured_video,
      other_videos: athlete.other_videos,
      other_nice_pics: athlete.other_nice_pics,
    },
    othervideos: {
      title: 'Some external videos',
      body: athlete.other_videos,
      type: 'videolist',
    },
    availability: {
      title: 'Availability',
      type: 'avalability',
      ...(athlete.current_status ? { value: athlete.current_status } : { value: '-' }),
    },
    showcases: {
      title: 'Showcases participation',
      type: 'showcase',
      value: (isPremium && athlete.showcases_participation?.items?.length > 0) ? `${athlete.showcases_participation?.items[0]?.showcase?.name}  ${athlete.showcases_participation?.items[0]?.color !== null ? ` | ${athlete.showcases_participation?.items[0]?.color}` : ''}${athlete.showcases_participation?.items[0]?.number !== null ? ` | ${athlete.showcases_participation?.items[0]?.number}` : ''}` : 'Premium content',
      onClickIfNoPremium: !isPremium ? ()=>dataContext.setOpenPromotionPremiumPass(true) : null
    },
    destinationCollege: {
      title: 'Destination college',
      type: 'destination',
      value: (isPremium && athlete.destination_college) ? athlete.destination_college : 'Premium content',
      onClickIfNoPremium: !isPremium ? ()=>dataContext.setOpenPromotionPremiumPass(true) : null
    },
    country: {
      title: 'Country of competition',
      icon: true,
      type: 'country',
      value: athlete.country_of_competition,
    },
    currentClub: {
      title: 'Current club',
      type: 'level',
      value: athlete.current_club,
    },
    compliance: {
      title: 'Compliance',
      type: 'avalability',
      value:
        athlete.compliance
        && athlete.compliance.map((item) => item.split('_').join(' ')).join(' | '),
    },
    targetDivision: {
      title: 'Target division',
      type: 'avalability',
      value:
        athlete.target_division
        && athlete.target_division
          .map((item) => item.split('_').join(' '))
          .join(' | '),
    },
    levelOfCompetition: {
      title: 'Level of competition',
      type: 'level',
      value: athlete.level_of_competition,
    },
    handedness: {
      title: 'Dominant Foot',
      type: 'handedness',
      value: athlete.dominant_foot,
    },
    intendedDegree: {
      title: 'Intended degree',
      type: 'text',
      body: athlete.intended_degree,
    },
    intendedFieldOfStudies: {
      title: 'Intended field of studies',
      type: 'text',
      body: athlete.intended_field_of_studies,
    },
    budget: {
      title: 'Budget',
      type: 'budget',
      budget: athlete.budget,
      currency: 'US$',
      value: 'Up To',
    },
    assesment: {
      title: 'Agency Assessment',
      body: athlete.agency_assesment,
      type: 'text',
    },
    eligibility: {
      title: 'Eligibility Notes',
      body: athlete.elegibility_notes,
      type: 'text',
    },
    participation: {
      title: 'Showcase Participacion',
      body: athlete.showcase_participation,
      type: 'showcase',
    },
    background: {
      title: 'Sports Background',
      body: athlete.sports_background,
      type: 'text',
    },
    agency: {
      name: athlete.agency.name,
      countries: athlete.agency.country,
      phone: phoneSplitToCountryCodeAndNumber(athlete.agency.contact_phone),
      athletes: athlete.agency.athleteCount,
      icon: athlete.agency.logo,
    },
    agent: {
      image: athlete.agent.profile_pic,
      name: athlete.agent.name,
      value: athlete.agent.contact_email,
      type: 'basic',
    },
  };

  // const onRequestInfo = () => {
  //   setRequestInfoFormOpen(!isRequestInfoFormOpen);
  // };

  const getImageUrl = (url) => {
    
    let position = url.search("/upload/");
    
    var a = (url.slice(0, position + 8));
    var b = (url.slice(position + 8));
    var cropped_image = a + 'c_thumb,g_face,h_450,w_350/' + b
    
    return cropped_image;
  }

  const [allowFavorite, setAllowFavorite] = useState(true);

  const handleFavoriteToggle = async (athlete) => {
    setAllowFavorite(false);
    favoriteToggle(athlete).then(() => setAllowFavorite(true));
  };
  console.log("==>", athlete.showcases_participation?.items[0]?.color === null)
  return (
    <AthleteInfoContainer id='prueba'>
    	<Helmet>
        <title>Alliance Atheletes | {athlete.name}</title>
        <meta property="og:title"       content={athlete.name} />
        <meta property="og:description" content={athlete.agency_assesment} />
        <meta property="og:image"       content={ getImageUrl(athlete.profile_pic) } />
      </Helmet>
      <FavoriteButton
        onClick={() => allowFavorite && handleFavoriteToggle(athlete)}
        isFavoritedLoading={athlete.isFavoritedLoading}
        isFavorited={athlete.isFavorited}
      >
        <svg
          width={20}
          height={18}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.238 3.309a4.471 4.471 0 00-6.327 0l-.862.862-.863-.862A4.474 4.474 0 002.86 9.636l7.19 7.19 7.189-7.19a4.472 4.472 0 000-6.327z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </FavoriteButton>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied to clipboard"
      >
        <ShareButton
          onClick={() => {navigator.clipboard.writeText(`${window.location.origin}/app/athlete_${athlete.id}`); handleTooltipOpen()}}
        >
          <img src={shareIcon} alt="Share" width={26} height={26} />
        </ShareButton>
      </Tooltip>
      
      <HeaderGridBox>
        <TitleBox gridArea="title">
          <strong>{athleteFormated.header.name}</strong>
          {' '}
          <span>
            {athleteFormated.header.birthdate}
            {' '}
          </span>
        </TitleBox>
        <ImageBox gridArea="image">
          <Avatar src={athleteFormated.header.mainImage} />
        </ImageBox>
        <SmallBox gridArea="box1">
          <InfoBitBox
            infoTitle="1st Position"
            value1={athleteFormated.infoBitGrid.position1}
          />
        </SmallBox>
        <SmallBox gridArea="box2">
          <InfoBitBox
            infoTitle="2nd Position"
            value1={athleteFormated.infoBitGrid.position2}
          />
        </SmallBox>
        <SmallBox gridArea="box3">
          <InfoBitBox
            infoTitle="Height"
            value1={athleteFormated.infoBitGrid.height}
            value2={athleteFormated.infoBitGrid.heightCm}
          />
        </SmallBox>
        <SmallBox gridArea="box4">
          <InfoBitBox
            infoTitle="Semester"
            value2={athleteFormated.infoBitGrid.semester}
          />
        </SmallBox>
        <SmallBox gridArea="box5">
          <InfoBitBox
            infoTitle="SAT Score"
            value1={athleteFormated.infoBitGrid.satScore}
          />
        </SmallBox>
        <SmallBox gridArea="box6">
          <InfoBitBox
            infoTitle="GPA"
            value1={athleteFormated.infoBitGrid.gpa}
          />
        </SmallBox>
        <BigBox gridArea="bigbox">
          <InfoAvailability status={athleteFormated.availability.value}>
            <strong>Availability</strong>
            <div>{athleteFormated.availability.value}</div>
          </InfoAvailability>
        </BigBox>
      </HeaderGridBox>
      {/* <Collapse isOpened={!isRequestInfoFormOpen}>
        <Actions>
          <Button onClick={onRequestInfo} label="Request more information" />
        </Actions>
      </Collapse>

      <Collapse isOpened={isRequestInfoFormOpen}>
        <RequestInfoFormBox>
          <RequestInfoFormBoxHeader>
            <RequestInfoFormBoxHeaderText>
              This message will be sent to:
            </RequestInfoFormBoxHeaderText>
            <RequestInfoFormBoxHeaderAgent>
              <Agent agent={athlete.agent} />
            </RequestInfoFormBoxHeaderAgent>
          </RequestInfoFormBoxHeader>
          <TextAreaForm
            onCancel={onRequestInfo}
            onSubmit={onRequestInfoSubmit}
            placeholder={`Hi ${athlete.agent.name}! \nI want more informatios about ${athlete.name}...`}
            submitLabel="Send!"
          />
        </RequestInfoFormBox>
      </Collapse> */}
      <Header2>Athletic Information</Header2>
      <TheColumns>
        <div>
          {athlete.showcases_participation?.items?.length > 0 && (
            <InfoBitRow
              title={athleteFormated.showcases.title}
              type={athleteFormated.showcases.type}
              value={athleteFormated.showcases.value}
              onClick={athleteFormated.showcases.onClickIfNoPremium}
            />
          )}
          {athlete.destination_college && (
            <InfoBitRow
              title={athleteFormated.destinationCollege.title}
              type={athleteFormated.destinationCollege.type}
              value={athleteFormated.destinationCollege.value}
              onClick={athleteFormated.destinationCollege.onClickIfNoPremium}
            />
          )}
          <InfoBitRow
            title={athleteFormated.country.title}
            icon={athleteFormated.country.icon}
            type={athleteFormated.country.type}
            value={athleteFormated.country.value}
          />
          <InfoBitRow
            title={athleteFormated.currentClub.title}
            type={athleteFormated.currentClub.type}
            value={athleteFormated.currentClub.value}
          />
          <InfoCard
            title={athleteFormated.background.title}
            type={athleteFormated.background.type}
            body={athleteFormated.background.body}
          />
          <InfoBitRow
            title={athleteFormated.levelOfCompetition.title}
            type={athleteFormated.levelOfCompetition.type}
            value={athleteFormated.levelOfCompetition.value}
          />
          <InfoCard
            title={athleteFormated.assesment.title}
            type={athleteFormated.assesment.type}
            body={athleteFormated.assesment.body}
          />
          <InfoBitRow
            title={athleteFormated.handedness.title}
            type={athleteFormated.handedness.type}
            value={athleteFormated.handedness.value}
          />
          {/* athleteFormated.gallery.other_nice_pics */}

          <InfoBitRow
            title={athleteFormated.compliance.title}
            type={athleteFormated.compliance.type}
            value={athleteFormated.compliance.value}
          />
          <InfoBitRow
            title={athleteFormated.targetDivision.title}
            type={athleteFormated.targetDivision.type}
            value={athleteFormated.targetDivision.value}
          />
          <InfoCard
            title={athleteFormated.eligibility.title}
            type={athleteFormated.eligibility.type}
            body={athleteFormated.eligibility.body}
          />
          <InfoCard
            title={athleteFormated.participation.title}
            type={athleteFormated.participation.type}
            body={athleteFormated.participation.body}
          />
        </div>
        <div>
          <MediaBox featured_video={athleteFormated.gallery.featured_video} />
          <InfoCard
            title={athleteFormated.othervideos.title}
            type={athleteFormated.othervideos.type}
            body={athleteFormated.othervideos.body}
          />
          <ScoreBox {...athleteFormated.education.sat} />
          <ScoreBox {...athleteFormated.education.toefl} />
          <ScoreBox {...athleteFormated.education.duolingo} />
          <ScoreBox {...athleteFormated.education.gpa} />
          <InfoCard
            title={athleteFormated.intendedDegree.title}
            type={athleteFormated.intendedDegree.type}
            body={athleteFormated.intendedDegree.body}
          />
          <InfoCard
            title={athleteFormated.intendedFieldOfStudies.title}
            type={athleteFormated.intendedFieldOfStudies.type}
            body={athleteFormated.intendedFieldOfStudies.body}
          />

          <InfoBitRow
            title={athleteFormated.currentClub.title}
            type={athleteFormated.currentClub.type}
            value={athleteFormated.currentClub.value}
          />
        </div>
      </TheColumns>

      {athleteFormated.budget.budget !== null && (
        <>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
            <Header2>Financial Information</Header2>
            <Tooltip 
              title={<TooltipText>The budget is meant as a guide for what the PSA can afford or wants to spend per academic year, but will in some cases not be fully accurate. We do our best to work with the PSA’s to determine a budget, but might be an educated guess/estimate. Budgets can vary pending on specific preferences of the PSA, currency exchange rates, and changes to the family’s situation. We recommend contacting the Consultant working with the PSA to obtain more information.</TooltipText>}
            >
              <div style={{backgroundColor: 'royalblue', height: '25px', width: '25px',borderRadius: '50%', textAlign: 'center'}}>
                <span style={{fontSize:'24px',fontWeight: 'bold'}}>?</span>
              </div>
            </Tooltip>
          </div>
          <TheColumns>
            <div>
              <InfoBitRow
                title={athleteFormated.budget.title}
                type={athleteFormated.budget.type}
                value={athleteFormated.budget.value}
                currency={athleteFormated.budget.currency}
                budget={athleteFormated.budget.budget}
              />
            </div>
          </TheColumns>
        </>
      )}

      <Header2>Agency Information</Header2>
      <TheColumn>
        <InfoSmallAgency agency={athlete.agency} />
      </TheColumn>
      <Header3>Agency Advisor</Header3>
      <TheAgentColumn>
        <Agent agent={athlete.agent} />
      </TheAgentColumn>
    </AthleteInfoContainer>
  );
}
const FavoriteButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${(props) => (props.isFavorited ? theme.colors.royalBlue : 'rgba(255, 255, 255, 0.1)')};
  cursor: pointer;
  & > svg {
    margin: 9px 0 0 8px;
    opacity: ${(props) => (props.isFavorited ? 1 : 0.2)};
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  ${(props) => props.isFavoritedLoading
    && `
  & > svg{
      animation: blink normal 0.5s infinite ease-in-out;
  }
  `}

  z-index: 5;
`;
const ShareButton = styled.div`
  position: absolute;
  top: 10px;
  right: 60px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${(props) => (props.isFavorited ? theme.colors.royalBlue : 'rgba(255, 255, 255, 0.1)')};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  & > svg {
    margin: 9px 0 0 8px;
    opacity: ${(props) => (props.isFavorited ? 1 : 0.2)};
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  ${(props) => props.isFavoritedLoading
    && `
  & > svg{
      animation: blink normal 0.5s infinite ease-in-out;
  }
  `}

  z-index: 5;
`;
const HeaderGridBox = styled.div`
  display: grid;
  grid-template-columns: 1.9fr 1fr 1fr 1fr 2.2fr;
  grid-template-rows: 1fr 2fr 2fr;
  gap: 13px 13px;
  grid-auto-flow: row;
  grid-template-areas:
    'image title title title title'
    'image box1 box2 box3 bigbox'
    'image box4 box5 box6 bigbox';
`;
const TitleBox = styled.div`
  grid-area: ${(props) => props.gridArea};
  padding-top: 18px;
  & strong {
    font-size: 25px;
    font-weight: 700;
  }
  & span {
    margin-left: 4px;
    font-size: 21px;
    font-weight: 400;
  }
`;
const ImageBox = styled.div`
  grid-area: ${(props) => props.gridArea};
  position: relative;
`;
const SmallBox = styled.div`
  grid-area: ${(props) => props.gridArea};
`;
const BigBox = styled.div`
  grid-area: ${(props) => props.gridArea};
`;

AthleteInfo.propTypes = {
  athlete: PropTypes.object,
};

AthleteInfo.defaultProps = {
  athlete: {},
};

export default AthleteInfo;

const AthleteInfoContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const Avatar = styled.img`
  border-radius: 10px;
  width: 100%;
  height: auto;
`;

// ---
const InfoAvailability = styled.div`
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90px;
  height: 100%;
  & strong {
    width: 100%;
    font-size: 16px;
    border-radius: 20px;
    padding: 10px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & div {
    width: 100%;
    background-color: ${theme.colors.transparentYellow};
    font-size: 16px;
    font-weight: bold;
    ${'' /* color: ${(props) =>
      props.aviability === 'STANDBY' ? theme.colors.gold : theme.colors.green}; */}
    
    ${(props) => props.status === 'COMMITTED' && ` color: ${theme.colors.red}`};
    ${(props) => props.status === 'ON_HOLD' && ` color: ${theme.colors.gold}`};
    ${(props) => props.status === 'AVAILABLE' && ` color: ${theme.colors.green}`};
    border-radius: 20px;
    padding: 10px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const TheColumns = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 13px 13px;
  grid-auto-flow: row;
  & > div {
    & > * {
      margin-bottom: 13px;
    }
  }
`;
const TheColumn = styled.div`
  margin-top: 24px;
  display: box;
  & > div {
    & > * {
      margin-bottom: 13px;
    }
  }
`;
const TheAgentColumn = styled.div`
  margin-top: 24px;
  display: box;
  & > * {
    margin-bottom: 32px;
    display: flex;
    @media ${theme.mq.desktop} {
      display: flex;
      width: 100%;
      margin: 0 0 32px 0;
    }
  }
`;

// const Actions = styled.div`
//   margin: 24px 0;
//   & > * {
//     width: calc(50% - 7px);
//     min-width: 314px;
//   }
// `;
// const RequestInfoFormBoxHeader = styled.div`
//   margin-bottom: 14px;
// `;
// const RequestInfoFormBoxHeaderText = styled.div`
//   font-size: 16px;
//   margin-bottom: 14px;
// `;
// const RequestInfoFormBoxHeaderAgent = styled.div`
//   & > * {
//     margin-bottom: 12px;
//     display: flex;
//     @media ${theme.mq.desktop} {
//       display: flex;
//       width: 100%;
//       margin: 0 0 12px 0;
//     }
//   }
// `;

const Header2 = styled.div`
  font-size: 21px;
  line-height: 30px;
  font-weight: 600;
  margin: 32px 45px 2px 14px;
`;
const Header3 = styled.div`
  font-size: 13px;
  line-height: 1.5em;
  font-weight: 600;
  margin: 40px 52px 2px 14px;
`;
// const RequestInfoFormBox = styled.div`
//   margin: 24px 0;
// `;
const TooltipText = styled.h1`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding:5px;
`;