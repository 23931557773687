import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

function FullScreenModal({ children, isVisible, onClose }) {
  if (!isVisible) return null;
  return (
    <FullScreenModalContainer>
      <CloseButton onClick={onClose}>
        <svg
          width={18}
          height={18}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M.967 15.734a.927.927 0 1 0 1.31 1.312l6.534-6.534 6.923 6.924a.927.927 0 1 0 1.311-1.312l-6.923-6.923 7.235-7.234A.927.927 0 1 0 16.045.656L8.811 7.89 1.967 1.046a.927.927 0 0 0-1.312 1.31L7.5 9.202.967 15.734Z"
            fill="#fff"
          />
        </svg>
      </CloseButton>
      <Content>{children}</Content>
    </FullScreenModalContainer>
  );
}

export default FullScreenModal;

const FullScreenModalContainer = styled.div`
  position: fixed;
	display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: rgba(6, 14, 40, 0.5);
  backdrop-filter: blur(4px) brightness(40%);

	& video{
		width: 100%;
		height: 100%;
		flex: 1;
	}
`;
const CloseButton = styled.div`
  position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
  top: 16px;
  right: 16px;
	width: 36px;
	height: 36px;
	background-color: ${theme.colors.buttonBackground};
	border-radius: 16px;
  z-index: 2;
  cursor: pointer;
	&:hover{
		background-color: ${theme.colors.buttonBackground};
	}
`;
const Content = styled.div`
  width: 100vw;
  height: 100vh;
`;
