import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import theme from '../../styles/theme.styles'
import LogoLogin from '../../components/assets/LogoLogin.svg.js'

import { DataContext } from '../../contexts/dataContext'
import InputField from '../../components/_FormElements/InputField'
import { completeNewPassword } from '../../services/authService'
import { Auth } from 'aws-amplify'

const initialFormState = {
  newpassword: '',
}
export default function NewPasswordRequiredScreen(props) {
  const navigate = useNavigate()

  const dataContext = useContext(DataContext)
  const { user, updateUser } = dataContext
  const [formState, updateFormState] = useState(initialFormState)
  const [errorTextValue, setErrorTextValue] = useState(null)
  const [validationError, setValidationError] = useState(null)
  const [submitedForm, setSubmitedForm] = useState(false)

  const completeNewPasswordUser = async (newpassword) => {
    completeNewPassword(user.user, newpassword)
      .then(async (r) => {
        const authUser = await Auth.currentAuthenticatedUser()
        updateUser(authUser)
      })
      .catch((error) => {
        setErrorTextValue(error.message)
      })
    // .finally(() => setSignInLoading(false))
  }

  return (
    <LoginScreenLayout>
      <LoginScreenLayoutContent>
        <LogoColumn>
          <LogoLogin />
        </LogoColumn>
        <LoginColumn>
          <div>
            <Formik
              initialValues={{ newpassword: '' }}
              validate={(values) => {
                const errors = {}
                if (!values.newpassword) {
                  errors.newpassword = 'Required'
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  completeNewPasswordUser(values.newpassword)
                  setSubmitting(false)
                }, 400)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <HeaderTitle>Enter New Password</HeaderTitle>
                  <ErrorText>{errorTextValue}</ErrorText>
                  <InputField
                    label="New Password"
                    placeholder="your new password"
                    isPassword
                    value={values.password}
                    onChange={handleChange('newpassword')}
                    onBlur={handleBlur('newpassword')}
                  />
                  <ErrorText>
                    {errors.newpassword &&
                      touched.newpassword &&
                      errors.newpassword}
                  </ErrorText>
                  <Actions>
                    <Button type="submit" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </Actions>
                </form>
              )}
            </Formik>
          </div>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.415 1.415L6 0L0 6L6 12L7.415 10.585L2.83 6L7.415 1.415Z"
              fill="white"
            />
          </svg>
          <BackLink onClick={() => navigate('/login/')}>Back to Login</BackLink>
        </LoginColumn>
      </LoginScreenLayoutContent>
    </LoginScreenLayout>
  )
}

const LoginScreenLayout = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  background-image: url('https://res.cloudinary.com/dsvz8cktr/image/upload/c_scale,q_auto:low,w_1026/v1640345226/UI_things/WebBackground_zdwymz.jpg');
  backgroung-position: center;
  background-size: cover;
`
const LoginScreenLayoutContent = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  font-family: ${theme.fonts.family1};
  align-items: flex-start;
`
const LogoColumn = styled.div`
  text-align: right;
  margin-right: 56px;
`
const LoginColumn = styled.div`
  width: 460px;
  margin-left: 56px;
  padding: 104px 68px 24px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 13px;
`
const HeaderTitle = styled.h3`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 32px;
`

const Label = styled.label`
  display: block;
  margin: 24px 0 8px;
  font-size: 13px;
`

const STextInput = styled.input`
  width: 100%;
  padding: 13px 20px 11px 20px;
  margin-bottom: 10px;
  line-height: 16px;
  font-size: 13px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.5);
  &::placeholder,
  &:focus,
  &:active {
    color: rgba(255, 255, 255, 1);
  }
`
const Actions = styled.div`
  margin: 24px 0 60px;
`
const ForgotPassword = styled.p`
  display: inline;
  font-size: 13px;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: ${theme.colors.white};
`
const ForgotPasswordLink = styled.p`
  display: inline;
  font-size: 13px;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: ${theme.colors.white};
  font-weight: 700;
  cursor: pointer;
`
const BackLink = styled.h1`
  display: inline;
  font-size: 16px;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: ${theme.colors.white};
  font-weight: 700;
  cursor: pointer;
  margin-left: 10px;
`
const ErrorText = styled.p`
  display: inline;
  font-size: 13px;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: red;
`
const ValidationErrorText = styled.p`
  display: block;
  font-size: 16px;
  font-weight: 700;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: red;
`
const Button = styled.button`
  display: block;
  width: 100%;
  border-radius: 8px;
  border-width: 0px;
  cursor: pointer;
  font-family: ${theme.fonts.family1};
  font-size: 17px;
  font-weight: 700;
  line-height: 48px;
  min-height: 48px;
  text-align: center;
  background-color: ${theme.colors.royalBlue};
  color: ${theme.colors.white};
  ${(props) =>
    props.disabled &&
    `
			opacity: 0.4;
		`}
`
