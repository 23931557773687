import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import theme from '../../styles/theme.styles';
import LayoutWrapper from './LayoutWrapper';

function RightColumn({ children, open }) {
  return (
    <RightColumnContainer open={open}>
      <LayoutWrapper>
        <RightColumnContent>
          <SRightColumn>
            {children}
          </SRightColumn>
        </RightColumnContent>
      </LayoutWrapper>
    </RightColumnContainer>
  );
}

RightColumn.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
};

RightColumn.defaultProps = {
  menuItems: [],
};

export default RightColumn;

const RightColumnContainer = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 10px;
    ${(props) => (props.open ? 'display: block;' : 'display: none;')}
`;
const RightColumnContent = styled.div`
    position: relative;
`;
const SRightColumn = styled.div`
    position: absolute;
    padding-left: 8px;
    right: 0;
    bottom: 0;
    width: ${theme.sizes.columns.col1.tabletPortrait*1.1}px;
    height: calc(100vh - ${theme.sizes.header.height.tabletPortrait}px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: inherit transparent;
    scrollbar-width: thin;
    padding-right: 10px;
    ::-webkit-scrollbar {
      width: 20px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #2D334A;
      border: 5px solid transparent;
      border-radius: 18px;
      background-clip: padding-box;  
      width: 5px;
    }
    @media ${theme.mq.desktop} { 
        width: ${theme.sizes.columns.col1.desktop*1.1}px;
        height: calc(100vh - ${theme.sizes.header.heightBig.desktop}px);
    }
`;
