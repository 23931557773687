import React, { useContext } from 'react'
import { DataContext } from "../../../src/contexts/dataContext"
import styled from 'styled-components'
import selectorsContent from '../../contexts/selectorsContent'
import _ from 'lodash'
import themeStyles from '../../styles/theme.styles'

const SavedFiltersList = ({savedFilters, setModalSavedFiltersVisible, onDeleteFilter}) => {

  const dataContext = useContext(DataContext)
  
  const convertFiltersToString = (filters) => {
    let filterString = ""
    Object.entries(filters).forEach((filter, index) => {
      filterString += index > 0 ? ', ' : ''
      switch (filter[0]) {
        case 'target_division':
          filterString += 'Division ' + humanizaStringDeFiltro('target_division', filter[1].eq)
          break;
        case 'start_date':
          filterString += 'Season ' + humanizaStringDeFiltro('start_date', filter[1].eq)
          break;
        case 'position':
          filterString += humanizaStringDeFiltro('position', filter[1].eq)
          break;
        case 'country_of_competition':
          filterString += 'From ' + humanizaStringDeFiltro('country_of_competition', filter[1].eq)
          break;
        case 'agencyID':
          filterString += 'Agency ' + humanizaStringDeFiltro('agencyID', filter[1].eq)
          break;
        case 'dominant_foot':
          filterString += humanizaStringDeFiltro('dominant_foot', filter[1].eq)
          break;
        case 'gender':
          filterString += humanizaStringDeFiltro('gender', filter[1].eq)
          break;
        case 'current_status':
          filterString += humanizaStringDeFiltro('current_status', filter[1].eq)
          break;
        case 'type_of_admission':
          filterString += 'Admission: ' + humanizaStringDeFiltro('type_of_admission', filter[1].eq)
          break;
        case 'sat_score':
          filterString += 'Sat: ' + filter[1].ge + ' or more'
          break;
        case 'toefl_score':
          filterString += 'TOEFL: ' + filter[1].ge + ' or more'
          break;
        case 'estimated_gpa':
          filterString += 'GPA: ' + filter[1].ge + ' or more'
          break;
        case 'budget':
          filterString += 'Budget: ' + filter[1].ge + ' or more'
          break;
        case 'showcase_participation':
          filterString += humanizaStringDeFiltro('showcase_participation', filter[1].eq)
          break;
        default:
          break;
      }
    })
    return filterString + '.'
  }
  
  const humanizaStringDeFiltro = (variable, valor) => {
    return _.find(selectorsContent[variable]["valueSets"],item => { return item[0] === valor })[1]
  }

	const setUserSavedFilter = (filters) =>{
		setModalSavedFiltersVisible(false)
		dataContext.updateFilters({
			...dataContext.filters, 
    		filters: JSON.parse(filters),
		})
	}

  const ConfirmDeleteFilter = (itemID) => {
		if(window.confirm(`You will delete a saved filter. This action cannot be undone.\n\nDo you want to delete?`)){
			onDeleteFilter(itemID)
		}
		else{console.log("[Delete saved filter]: Canceled")}
  }

	return (
		<SavedFiltersListContainer>
			<Title>Select a filter</Title>
			<ScrollFilterList noResults={true}>
				{
					(savedFilters && savedFilters.length > 0) ? (savedFilters.map((item,index)=>{
						return (
							<FilterButtonContainer key={index}>
								<TextContent onClick={()=>setUserSavedFilter(item.filter_body)}>
									<FilterName>{item.name}</FilterName>
									<FilterBody>
										{convertFiltersToString(JSON.parse(item.filter_body))}
									</FilterBody>
								</TextContent>
								<IconCircle onClick={()=>ConfirmDeleteFilter(item.id)}>
									<svg width="16" height="18" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 5H3H19" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5M17 5V19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5H17Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
								</IconCircle>
							</FilterButtonContainer>
						)
					})) : (<NoResultsView><NoResultsText>SORRY, YOU DON'T HAVE ANY FILTERS SAVED YET.</NoResultsText></NoResultsView>)
				}
			</ScrollFilterList>
		</SavedFiltersListContainer>
	)
}
export default SavedFiltersList


const SavedFiltersListContainer = styled.div`
	width: 100%;
	padding: 0 20px;
	margin-bottom: 10px;
	text-align: left;
`;
const Title = styled.h1`
	color: ${themeStyles.colors.white};
	margin: 0 0 20px 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const ScrollFilterList = styled.div`
	max-height: 50vh;
	overflow-y: ${(props) => (props.noResults ? `hidden;`: `scroll;`)}
`;
const FilterButtonContainer = styled.div`
	width: 50vw;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${themeStyles.colors.transparentWhite2};
	border:1px solid rgba(31,38,62,1); 
	padding: 15px; 
	border-radius: 10px;
	margin: 8px 0;
`;
const FilterName = styled.h1`
	color: ${themeStyles.colors.white};
	font-size: 20px;
`;
const TextContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: baseline;  
	min-width: 200px;
	max-width: 80%;
	cursor: pointer;    
`;
const IconCircle = styled.div`
	width: 36px;
	height: 36px;
	border-radius: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${themeStyles.colors.transparentWhite3};
	margin-left: 20px;
	cursor: pointer;    
`;
const FilterBody = styled.h6`
	color: ${themeStyles.colors.transparentWhite6};
	font-size: 14px;
`;
const NoResultsView = styled.div`
  align-items: center;
  justify-content: center;
`;
const NoResultsText = styled.span`
	padding-bottom: 18px;
	font-size: 14px;
	line-height : 20px;
	font-weight: 500;
	text-transform: uppercase;
	color: ${themeStyles.colors.transparentWhite4};
`;
