import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { API } from 'aws-amplify';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';

// filters
import RightColumn from '../../components/_Layout/RightColumn';

// AthleteInfo
import AthleteInfo from '../../components/Athletes/AthleteInfo';
// Collapse, request athlete info form
import { Collapse } from 'react-collapse'
import Agent from '../../components/Agencies/Agent';
import Button from '../../components/_FormElements/Button';
import TextAreaForm from '../../components/_FormElements/TextAreaForm';
import Popup from '../../components/_FormElements/Popup';
import { getAthlete } from '../../custom_graphql_queries/athletes';

function AthleteView() {
  // Extract information from url
  const params = useParams();
  const { athleteId } = params;
  // With react router dom v6 we can not use partial routes (app/athlete_) so we need to split the url
  const [section,athleteIdFromUrl] = athleteId.split('_');
  const location = useLocation();
  // Check if we are in search athlete page or favorites athlete page
  const isInSearchAthletePage = () => location.pathname.substr(0, 13) === '/app/athlete_';
  const isInFavoritesAthletePage = () => location.pathname.substr(0, 19) === '/favorites/athlete_';

  const dataContext = useContext(DataContext);
  const { athleteIsLoaded, setAthleteIsLoaded, setPromotionalModalVisible } = dataContext;
  const [athleteData, setAthleteData] = useState(null);
  const [ athlete, setAthlete ] = useState([])
  // Resquest athlete info form state
  const [isRequestInfoFormOpen, setRequestInfoFormOpen] = useState(false);
  
  const onRequestInfoSubmit = async (values, isSubmiting) => {
    try {
      const execDataContentFunction = await dataContext.requestInfoOfAthleteFormSend(
        { message: values.message, athleteId: athleteData?.id },
      );
      if (execDataContentFunction) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const onRequestInfo = () => {
    setRequestInfoFormOpen(!isRequestInfoFormOpen);
  };

  const backRoute = isInSearchAthletePage()
    ? {
      url: '/app/',
      title: 'Athletes',
    }
    : isInFavoritesAthletePage
      ? {
        url: '/favorites/',
        title: 'Favorites',
      }
      : {
        url: '',
        title: '',
      };

  const getAthleteInfo = async () => {
    const athleteInf = await API.graphql({
      query: getAthlete,
      variables: { id: athleteIdFromUrl },
    })
    if(athleteInf.data.getAthlete){
      return athleteInf
    }
    throw new Error('No athlete data')
    //return athleteData
  }

  let athleteRecord = find(dataContext.athletes, { id: athleteIdFromUrl });

  if(athleteRecord === undefined){
    athleteRecord = {}
  }

  useEffect(()=> {
    async function getAthleteData() {
      if(Object.keys(athleteRecord).length > 0){
        console.log("entro")
        setAthlete(athleteRecord)
        setAthleteData(athleteRecord)
        setAthleteIsLoaded(true)
        setPromotionalModalVisible(false);
      } else {
        await getAthleteInfo()
        .then(r=> {
            setAthlete(r.data.getAthlete)
            setAthleteData(r.data.getAthlete)
            setAthleteIsLoaded(true)
            setPromotionalModalVisible(false);
          }
        )
      }
    }
    getAthleteData()
    
  }, [params.athleteId])

  // useEffect(() => {
   
  //   const athlete = find(dataContext.athletes, { id: athleteId });
   
  //     setPromotionalModalVisible(false);
   
  //   if (athlete) {
  //     setAthleteIsLoaded(true)
  //     setAthleteData(athlete);
  //   }
  // }, [dataContext.athletes]);
  return (
    <AthleteContainer>
      <Popup
        isOpen={!athleteIsLoaded}
        bgcolor={"transparent"}
        textAlign="center"
        content={<div><span style={{color: "#fff",  fontSize: "1.5rem", fontWeight: "bold"}}>Loading Athlete ....</span></div>}
        hasCloseButton={false}
      />

      { 
        (athleteData && Object.keys(athleteData).length > 0 ) && (
          <motion.div
            initial={{
              y: '100vh',
            }}
            animate={{
              y: '0vh',
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              y: '100vh',
              transition: {
                duration: 0.3,
              },
            }}
          >
            <ContentWrapper>
              <AthleteContent1>
                <Header>
                  <TitleNav to={backRoute.url}>
                    <svg
                      width={8}
                      height={12}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.035 11.995c-.29 0-.523-.1-.723-.29L.383 6.831C.125 6.574.008 6.317 0 6.002c0-.316.125-.59.374-.83L5.312.298c.2-.2.44-.299.723-.299.572 0 1.037.465 1.037 1.038 0 .282-.124.547-.332.755L2.457 6.001 6.74 10.21c.216.207.332.465.332.755 0 .565-.465 1.03-1.037 1.03Z"
                        fill="#fff"
                      />
                    </svg>
                    {backRoute.title}
                  </TitleNav>
                </Header>
                <AthleteContent2>
                  <AthleteLayout>
                    <HeaderSpacer />
                    <LayoutContent>
                      <AthleteInfo
                        favoriteToggle={dataContext.favoriteToggle}
                        athlete={athleteData}
                      />
                    </LayoutContent>
                    {/* View of closed collapse */}
                    <Collapse isOpened={!isRequestInfoFormOpen}>
                      <Actions>
                        <Button onClick={onRequestInfo} label="Request more information" />
                      </Actions>
                    </Collapse>
                    {/* View of opened collapse */}
                    <Collapse isOpened={isRequestInfoFormOpen}>
                      <RequestInfoFormBox>
                        <RequestInfoFormBoxHeader>
                          <RequestInfoFormBoxHeaderText>
                            This message will be sent to:
                          </RequestInfoFormBoxHeaderText>
                          <RequestInfoFormBoxHeaderAgent>
                            <Agent agent={athleteData.agent} />
                          </RequestInfoFormBoxHeaderAgent>
                        </RequestInfoFormBoxHeader>
                        <TextAreaForm
                          onCancel={onRequestInfo}
                          onSubmit={onRequestInfoSubmit}
                          placeholder={`Hi ${athleteData.agent.name}! \nI want more informatios about ${athleteData.name}...`}
                          submitLabel="Send!"
                        />
                      </RequestInfoFormBox>
                    </Collapse>

                  </AthleteLayout>
                </AthleteContent2>
              </AthleteContent1>
            </ContentWrapper>
          </motion.div>
        )
      }
    </AthleteContainer>
  );
}

AthleteView.propTypes = {};

AthleteView.defaultProps = {};

export default AthleteView;

const AthleteLayout = styled.div``;
const AthleteContainer = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
`;
const AthleteContent1 = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;
const AthleteContent2 = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${theme.colors.midnightBlue};
  overflow-y: auto;
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2D334A;
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;
const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: absolute;
  width: 100% ;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  top: 0;
  background: rgb(6, 14, 40);
  background: linear-gradient(
    180deg,
    rgba(6, 14, 40, 1) 0%,
    rgba(6, 14, 40, 1) 89%,
    rgba(6, 14, 40, 0) 100%
  );
  z-index: 10;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const TitleNav = styled(NavLink)`
  margin: 30px 0 23px 0;
  font-size: 21px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
  text-decoration: none;
  color: white;
  & svg {
    margin-right: 12px;
  }
`;
const LayoutContent = styled.div`
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 20px;
  padding: 12px;
  height: 66vh;
  overflow-y: auto;
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2D334A;
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;

const Actions = styled.div`
  margin: 24px 0;
  & > * {
    width: calc(100% - 7px);
    min-width: 314px;
  }
`;
const RequestInfoFormBoxHeader = styled.div`
  margin-bottom: 14px;
`;
const RequestInfoFormBoxHeaderText = styled.div`
  font-size: 16px;
  margin-bottom: 14px;
`;
const RequestInfoFormBoxHeaderAgent = styled.div`
  & > * {
    margin-bottom: 12px;
    display: flex;
    @media ${theme.mq.desktop} {
      display: flex;
      width: 100%;
      margin: 0 0 12px 0;
    }
  }
`;
const RequestInfoFormBox = styled.div`
  margin: 24px 0;
`;