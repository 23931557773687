import React from 'react'
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components'
import theme from '../../styles/theme.styles';

import ContentWrapper from '../../components/_Layout/ContentWrapper'
import Layout from '../../components/_Layout/Layout'
import RightColumn from '../../components/_Layout/RightColumn'
import Button from '../../components/_FormElements/Button';

const Cancel = () => {

  const navigate = useNavigate()

  return (
    <Layout>
      <RightColumn />
      <ContentWrapper>
        <CenterContainer>
          <HeaderSpacer />
          <Header>
            <Title>Subscription process canceled</Title>
          </Header>
          <Body>
            <BodyTitle>
              Your subscription process has been successfully cancelled, we are sorry to hear this. Please note that the premium version of Alliance Athletes includes features such as:
            </BodyTitle>
            <List>
              <ListItem>Exclusive filters</ListItem>
              <ListItem>Additional PSA information</ListItem>
              <ListItem>Organise your favourite PSAs in folders</ListItem>
              <ListItem>And much more</ListItem>
            </List>
          </Body>
          <ButtonGroup>
            <ButtonContainer>
            <Button onClick={()=>navigate('/app/')} label="Return to the app" />
            </ButtonContainer>
            <ButtonContainer>
            <Button onClick={()=>navigate('/feedback/')} label="Leave your feedback to help us improve" />
            </ButtonContainer>
          </ButtonGroup>
        </CenterContainer>
      </ContentWrapper>
    </Layout>
  )
}

export default Cancel


const CenterContainer = styled.div`
  position: relative;
`;

const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  width: 100% ;
  top: 0;
  left: 0;
  background: rgb(6,14,40);
  background: linear-gradient(180deg, rgba(6,14,40,1) 0%, rgba(6,14,40,1) 89%, rgba(6,14,40,0) 100%);
  z-index: 2;
  @media ${theme.mq.desktop} { 
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Title = styled.h1`
  margin: 30px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5% 0;
`;
const BodyTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.35px;
`;
const List = styled.ul`
  list-style-type: circle;
  padding:20px;
  margin-left: 20px;
`;
const ListItem = styled.li`
  font-size: 16px;
  line-height: 41px;
  letter-spacing: 0.374px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;
const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 10px;
`;
