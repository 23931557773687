import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

function ScoreBar({ calculatedScore }) {
  return (
    <StyledScoreBar>
      <StyledScore calculatedScore={`${calculatedScore}%`} percentile={calculatedScore}>
        <StyledEnd />
      </StyledScore>
    </StyledScoreBar>
  );
}

function ScoreBox({
  min, max, score, title, float,
}) {
  const scoreLength = (minVal, maxVal, scoreVal) => {
    const percentile = (scoreVal - minVal) * 100 / (maxVal - minVal);
    return percentile;
  };

  const calculatedScore = scoreLength(min, max, score);
  return (
    <StyledInfoCard>
      <InfoTitle>{title}</InfoTitle>
      <ScoreBar calculatedScore={calculatedScore} />
      <ScoreData>
        <ValueText children={min} />
        <ValueText isBoxed children={score || 'N/A'} />
        <ValueText children={float ? Number(max).toFixed(2) : max} />
      </ScoreData>
    </StyledInfoCard>
  );
}

export default ScoreBox;

export const StyledInfoCard = styled.div`
background-color: ${theme.colors.transparentWhite2};
border-radius: 6px;
padding: 10px;
display: flex;
flex-direction: column;
align-items: stretch;
justify-content: center;

`;
export const InfoTitle = styled.div`
font-family: "Open Sans";
font-style: normal;
font-weight: bold;
font-size: 21px;
color: ${theme.colors.white};
text-align: left;
padding-bottom: 16px;

`;
export const StyledScoreBar = styled.div`

height:4px;
flex:1;
width: 100%;
background-color:${theme.colors.transparentWhite5};
display:flex;
flex-direction: row;
border-radius:4px;
`;
export const StyledScore = styled.div`

width:4px;
height:4px;

display:flex;
flex-direction: row;
border-radius:4px;
width:${(props) => props.calculatedScore};
justify-content:flex-end;
${(props) => props.percentile >= 70 && 'background-color:#45D900'}
${(props) => props.percentile >= 35 && props.percentile < 69 && 'background-color:#FF9800'}
${(props) => props.percentile < 35 && 'background-color:red'}	
`;
export const StyledEnd = styled.div`
width:4px;
height:4px;
background-color:${theme.colors.white};
border-radius:4px;
`;

export const ScoreData = styled.div`
margin-top:16px;
display: flex;
flex-direction: row;
align-items: center;
justify-content:space-between;
flex:1
`;

export const ValueText = styled.div`
text-align: center; 
font-family: ${theme.fonts.valueBoxText.family};
font-size: ${theme.fonts.valueBoxText.size};
font-weight: ${theme.fonts.valueBoxText.weight};
line-height: ${theme.fonts.valueBoxText.lineHeight};
color: ${theme.colors.white};
${(props) => props.valueText === 'Standby'
    && `color: ${props.theme.colors.gold};`
};
${(props) => props.isBoxed === true
    && ` 
    width:30%;
    background-color:rgba(255,255,255,0.1);
    font-weight:bold;
border-radius: 20px;
padding:6px;
display: flex;
align-items: center;
justify-content: center;
flex-direction:row;`
}
`;
