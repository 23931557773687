import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import {
  filter,
} from 'lodash';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import ListItem from '../../components/Athletes/ListItem';
import theme from '../../styles/theme.styles';
import RightColumn from '../../components/_Layout/RightColumn';
import { createFavoritesFolderAthleteRelation, deleteFavoritesFolderAthleteRelation, listFavoritesFolders } from '../../custom_graphql_queries/coachs';
import { DragDropContext } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import Popup from '../../components/_FormElements/Popup';
import CreateFolderNameForm from '../../components/Favorites/CreateFolderNameForm';
import DroppableArea, { DroppableAreaRoot } from '../../components/Favorites/DroppableArea';
import AthleteView from '../AthleteView/AthleteView';

function FavoritesFolder() {
  const params = useParams();
  const { folderId, name } = params;
  // With react router dom v6 we can not use partial routes (app/athlete_) so we need to split the url
  const [section, folderIdFromUrl] = folderId.split('_');
  
  const location = useLocation();
  const folder_name = location.state.name
  
  const dataContext = useContext(DataContext)
  const { userCoachData, athletes, dragFavoriteItemRootFolder, setDragFavoriteItemRootFolder, isDataProcessed, totalFavorites, setTotalFavorites, athletesInFolder, setAthletesInFolder } = dataContext

	const [receivingItemList, setReceivedItemList] = useState([{
		"id": folderIdFromUrl,
		"name": 'Upper folder'
	}])
	const [dragItemInFolderList, setDragItemInFolderList] = useState([])
	const [relationData, setRelationData] = useState([])
	const [ratingIsChanged, setRatingIsChanged] = useState(false)

  const deleteFolderRelation = async ({relation}) => {
		try {
			const response = await API.graphql({
        query: deleteFavoritesFolderAthleteRelation, 
        variables: { 
          input: {
            id:  relation[0].id 
          }
        }
      })
			
			return response

		} catch (e) {
			console.log(e);
      throw new Error('ERROR EXTRAYENDO DE CARPETA!!');
		}
	}

  const isInSearchAthletePage = () => location.pathname.substr(0, 13) === '/app/athlete_';
  const isInFavoritesAthletePage = () => location.pathname.substr(0, 19) === '/favorites/folder_';

  const backRoute = isInSearchAthletePage()
    ? {
      url: '/app/',
      title: 'Athletes',
    }
    : isInFavoritesAthletePage
      ? {
        url: '/favorites/',
        title: 'Favorites',
      }
      : {
        url: '',
        title: '',
      };
  
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    // id de la carpeta
    const sourceFolderID = source.droppableId
    const destinationFolderID = destination.droppableId
    // id del atleta
    const athleteID = draggableId
    const relation = athletesInFolder.filter(res=> res.athleteID === draggableId)

    if (sourceFolderID !== destinationFolderID) {
      // const relation = relationData.filter(item => {
      //   if(item.favoritesFolderID === destinationFolderID && item.athleteID === athleteID)				
      //     return item
      // })
      
      deleteFolderRelation({relation}).then(r=>{

        let newFolderDragItemlist = dragItemInFolderList.filter(item => item.athleteID !== athleteID)
        // let newDragItemlist = dragFavoriteItemRootFolder.filter(item => item.athleteID !== athleteID)
        setDragFavoriteItemRootFolder([...dragFavoriteItemRootFolder, relation[0]].sort((a, b) => { return a.rating - b.rating; }))
			  setAthletesInFolder(athletesInFolder.filter(res=> res.athleteID !== athleteID ))
        setDragItemInFolderList(newFolderDragItemlist)
        // setDragFavoriteItemRootFolder([...newDragItemlist, relation[0]].sort((a, b) => { return b.rating - a.rating; }))
      }).catch(e=>{
        console.log(e.message)
      })
    }
  };

  useEffect(() => {
    const results = athletesInFolder.filter(res=> res.favoritesFolderID === folderIdFromUrl)
    const athletesInThisFolder = totalFavorites.filter(({ athleteID: id1 }) => results.some(({ athleteID: id2 }) => id2 === id1));
    setRelationData(athletesInFolder)
    setDragItemInFolderList(athletesInThisFolder.sort((a, b) => { return b.rating - a.rating; }) )
  },[userCoachData.favorites.items, ratingIsChanged, athletesInFolder])


  return (
    <FolderContainer>
      {/* {!athleteData && ( */}
        <motion.div
          initial={{
            y: '100vh',
          }}
          animate={{
            y: '0vh',
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            y: '100vh',
            transition: {
              duration: 0.3,
            },
          }}
        >
          <ContentWrapper>
            <AthleteContent1>
              <Header>
                <TitleNav to={backRoute.url}>
                  <svg
                    width={8}
                    height={12}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.035 11.995c-.29 0-.523-.1-.723-.29L.383 6.831C.125 6.574.008 6.317 0 6.002c0-.316.125-.59.374-.83L5.312.298c.2-.2.44-.299.723-.299.572 0 1.037.465 1.037 1.038 0 .282-.124.547-.332.755L2.457 6.001 6.74 10.21c.216.207.332.465.332.755 0 .565-.465 1.03-1.037 1.03Z"
                      fill="#fff"
                    />
                  </svg>
                  {backRoute.title}
                </TitleNav>
              </Header>
              <AthleteContent2>
                <AthleteLayout>
                  <HeaderSpacer />

                  <LayoutContent>
                    <div style={{marginBottom: 20}}>
                      <span style={{fontSize: 20}}>
                        {folder_name}
                      </span>
                    </div>
                    <DragDropContext
                      onDragEnd={(result) => onDragEnd(result)}
                    >
                      {
                        receivingItemList.map((item, index)=>{
                          return (
                            <DroppableArea
                              droppableId={item.id}
                              key={index}
                              index={index}
                              name={item.name}
                              placeholder={''}
                              isInsideFolder={true}
                            />
                          )
                        })
                      }
                      {
                        dragItemInFolderList && (
                          <DroppableAreaRoot
                            droppableId={uuid()}
                            key={uuid()}
                            index={1111}
                            items={dragItemInFolderList}
                            isDropDisabled={true}
                            placeholder={''}
                            ratingIsChanged={ratingIsChanged}
                            setRatingIsChanged={setRatingIsChanged}
                          />
                        )
                      }
                    </DragDropContext>
                  </LayoutContent>
                </AthleteLayout>
              </AthleteContent2>
            </AthleteContent1>
          </ContentWrapper>
        </motion.div>
      {/* )} */}
    </FolderContainer>
  );
}

FavoritesFolder.propTypes = {};

FavoritesFolder.defaultProps = {};

export default FavoritesFolder;

const FolderContainer = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
`;


const FavoritesLayout = styled.div`
  position: relative;
`;

const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const NoResults = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 15px;
  font-weight: 400;
`;
const ListScroll = styled.div`
  position: absolute;
  overflow: auto;
  width: 100% ;
  top: 20px;
  left: 0;
  height: 100vh;
  z-index: 1;
  padding-right: 10px;
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2D334A;
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;

export const ButtonContainer = styled.div`
  flex-direction: row;
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 10px;
  display: flex;
  width: 100%;
  margin-bottom: 30px;
`

export const Button = styled.div`
  flex-direction: row;
  padding: 12px;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
`
export const ButtonText = styled.span`
  color: white;
  font-family: ${theme.fonts.gotham21Bold.family};
  font-size: 16px;
  font-weight: bold;
`
export const PadLock = styled.img`
  width: 15px;
  height:15px;
  margin-left: 10px;
`

const AthleteLayout = styled.div``;
const AthleteContainer = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
`;
const AthleteContent1 = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;
const AthleteContent2 = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: ${theme.colors.midnightBlue};
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2D334A;
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;
const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: absolute;
  width: 100% ;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  top: 0;
  background: rgb(6, 14, 40);
  background: linear-gradient(
    180deg,
    rgba(6, 14, 40, 1) 0%,
    rgba(6, 14, 40, 1) 89%,
    rgba(6, 14, 40, 0) 100%
  );
  z-index: 10;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const TitleNav = styled(NavLink)`
  margin: 30px 0 23px 0;
  font-size: 21px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
  text-decoration: none;
  color: white;
  & svg {
    margin-right: 12px;
  }
`;
const LayoutContent = styled.div`
  ${'' /* background-color: ${theme.colors.transparentWhite2};
  border-radius: 20px;
  padding: 12px; */}
`;
