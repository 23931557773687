import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

function InfoBitBox({ infoTitle, value1, value2 }) {
  return (
    <StyledInfoBitBox>
      <InfoTitle>{infoTitle}</InfoTitle>
      {value1 !== '' && <Value>{value1}</Value>}
      {value2 !== '' && value1 !== '' && <Value2 value1>{value2}</Value2>}
      {value2 !== '' && !(value1 !== '') && <Value2>{value2}</Value2>}
    </StyledInfoBitBox>
  );
}

InfoBitBox.propTypes = {
  infoTitle: PropTypes.string,
  value1: PropTypes.string,
  value2: PropTypes.string,
};

InfoBitBox.defaultProps = {
  infoTitle: '',
  value1: '',
  value2: '',
};
export default InfoBitBox;

const StyledInfoBitBox = styled.div`
	background-color: ${theme.colors.transparentWhite2};
	border-radius: 20px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	min-height: 90px;
	height: 100%;
`;
const InfoTitle = styled.div`
	font-family: ${theme.fonts.infoBitTitle.family};
	font-size: ${theme.fonts.infoBitTitle.size};
	font-weight: ${theme.fonts.infoBitTitle.weight};
	line-height: ${theme.fonts.infoBitTitle.lineHeight};
	color: ${theme.colors.transparentWhite4};
	text-align: center;
	padding-bottom: 4px;
`;
const Value = styled.div`
	font-family: ${theme.fonts.infoBitMain.family};
	font-size: ${theme.fonts.infoBitMain.size};
	font-weight: ${theme.fonts.infoBitMain.weight};
	line-height: ${theme.fonts.infoBitMain.lineHeight};
	color: ${theme.colors.white};
	text-align: center;
`;
const Value2 = styled.div`
	font-family: ${theme.fonts.infoBitTitle.family};
	font-size: ${theme.fonts.infoBitTitle.size};
	font-weight: ${theme.fonts.infoBitTitle.weight};
	line-height: ${theme.fonts.infoBitTitle.lineHeight};
	color: ${theme.colors.white};
	text-align: center;
	${(props) => props.value1 === ''
		&& `
 padding-top:10px;

 `}
`;
