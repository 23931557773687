import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextAreaForm from '../../components/_FormElements/TextAreaForm';

import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';

// filters
import RightColumn from '../../components/_Layout/RightColumn';

function ShowcaseFeedBack({ children }) {
  const dataContext = useContext(DataContext);

  const onFeedbackFormSubmit = async (values, isSubmiting) => {
    try {
      const execDataContentFunction = await dataContext.feedBackFormSend({
        message: values.message,
        type: "showcase",
      });
      if (execDataContentFunction) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  return (
    <Layout>
      <RightColumn />
      <ContentWrapper>
        <FeedBackLayout>
          <HeaderSpacer />
          <Header>
            <Title>Showcase Tour 2022-2023</Title>
          </Header>
          <HeaderLine>Request more information about our showcases</HeaderLine>
          <TextLine>Please, drop us a message and let us know which events are you interested in. Our agencies will connect with you as soon as possible.</TextLine>
          <TextAreaForm
            onSubmit={onFeedbackFormSubmit}
            placeholder="I think..."
            submitLabel="Send"
            sentReponse="Thanks for your help!"
          />
        </FeedBackLayout>
      </ContentWrapper>
    </Layout>
  );
}

ShowcaseFeedBack.propTypes = {};

ShowcaseFeedBack.defaultProps = {};

export default ShowcaseFeedBack;

const FeedBackLayout = styled.div`
  position: relative;
`;

const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  width: 100% ;
  top: 0;
  left: 0;
  background: rgb(6,14,40);
  background: linear-gradient(180deg, rgba(6,14,40,1) 0%, rgba(6,14,40,1) 89%, rgba(6,14,40,0) 100%);
  z-index: 2;
  @media ${theme.mq.desktop} { 
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const HeaderLine = styled.div`
  font-size: 21px;
  line-height: 30px;
  font-weight: 700;
  margin: 6px 52px 12px 0;
  & strong {
    font-size: 25px;
    font-weight: 700;
    margin-right: 6px;
  }
`;
const TextLine = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
  margin: 6px 52px 12px 0;
  & strong {
    font-size: 18px;
    font-weight: 600;
    margin-right: 6px;
  }
`;
