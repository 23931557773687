import React, { useContext } from 'react';
import styled from 'styled-components';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';

function LegalText({setIsOpen, setTermshavebeenreading}) {
  const dataContext = useContext(DataContext);
  const { user } = dataContext;
  const acceptTerms = () => {
		setTermshavebeenreading(true)
		setIsOpen(false)
	}
  return (
    <ScrollableContainer>
      <H1>MOBILE APP USER TERMS AND PRIVACY POLICY</H1>

      <H2>ALLIANCE APP LTD</H2>
      <P>Last updated 15 September 2021</P>

      <P>Alliance App. Ltd (&ldquo;we&rdquo; or &ldquo;us&rdquo; or &ldquo;our&rdquo;) respects the privacy of our users ((&ldquo;user&rdquo;) or (&ldquo;you&rdquo;)). This Privacy
      Policy explains how we collect, use, disclose, and safeguard your and our user’s information when
      you visit or use mobile and/or desktop version of our application (the (&ldquo;Application&rdquo;)). Please read this
      User Terms and Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS
      USER TERMS AND PRIVACY POLICY, PLEASE DO NOT ACCEPT OUR TERMS AND DO NOT
      LOG ON AND/OR USE THE APPLICATION.</P>

      <P>WHEN YOU ACCESS THE APPLICATION, YOU THEREBY AGREE TO RESPECT AND TREAT
      THE INFORMATION OF OTHER USERS PRIVATE. FURTHERMORE, YOU AGREE TO NOT USE
      THE PERSONAL INFORMATION ABOUT OTHER USERS ACCESSED VIA THE APPLICATION
      FOR ANY OTHER PURPOSE THAN TO ASSESS ATHLETES AND THEIR POTENTIAL OR AS THE
      CASE MAY BE PROVIDE ATHLETES INFORMATION TO COAHCES IN ORDER TO POSSIBLY
      ENROLL THEM/ENROLL YOURSELF TO UNIVERSITIES AND/OR TEAMS. WHEN ASSESSING
      ATHLETES OR PROVIDING ATHLETES INFORMATION, YOU SHALL NOT SHARE PERSONAL
      INFORMATION ABOUT ANY ATHLETES TO ANYONE NOT INVOLVED IN THE ATHLETE’S
      SELECTION PROCESS.</P>

      <P>We reserve the right to make changes to this User Terms and Privacy Policy (the (&ldquo;Policy&rdquo;)) at any time
      and for any reason. We will alert you about any changes by updating the (&ldquo;Last updated&rdquo;) date of this
      Policy. You are encouraged to periodically review this Policy to stay informed of updates. You will be
      deemed to have been made aware of, will be subject to, and will be deemed to have accepted the
      changes in any revised Policy by your continued use of the Application after the date such revised
      Policy is posted in the Application.</P>

      <P>This Policy does not apply to the third-party online/mobile store from which you install the Application
      or any payment providers linked to the Application, which may also collect and use data about
      you. We are not responsible for any of the data collected by any such third party.</P>

      <H3>COLLECTION OF YOUR INFORMATION</H3>
      <P>This Policy explains when and why we collect information about you, how we use it, the conditions
      under which we may disclose it to others and how we keep it secured.</P>
      <P>We may collect information about you in a variety of ways. Our main goal in collecting such data is to
      provide you with a safe, efficient and personalised experience, and we will only collect information
      relating to you that we deem necessary for this purpose. We will never sell your personal information.
      The information we may collect via the Application depends on the content and materials you use,
      and includes:</P>

      <H4>Personal Data</H4>
      <P>Demographic and other personally identifiable information (such as your name and email address)
      that you voluntarily give to us when choosing to participate in various activities related to the
      Application, such as, if you are an athlete your personal athletes information, and if you are a coach,
      your coach preferences as to athletes information and individual performances and your specific
      requirements to athletes you are seeking such as intended semester to start college in the USA, SAT,
      TOEFL, GPA, position, level of competition, etc. If you choose to share data about yourself and your
      preferences via your profile to recruiting agencies, please be advised that data you disclose will be
      accessible only to recruiting agencies with access to the Application.</P>

      <H4>Derivative Data</H4>
      <P>Information our servers automatically collect when you access the Application, such as your native
      actions that are integral to the Application, including replying to an enquiry, as well as other
      interactions with the Application and recruiting agencies via server log files.</P>

      <H4>Financial Data</H4>
      <P>In case certain of the services in the Application requires payments to be made, financial information,
      such as data related to your payment method (e.g. valid credit card number, card brand, expiration
      date) that we may collect when you order, our services from the Application. We store only very
      limited, if any, financial information that we collect. Otherwise, all financial information is stored by our
      selected payment processors and you are encouraged to review your selected payment processors’
      privacy policy and contact them directly for responses to your questions.</P>

      <H4>Mobile Device Access</H4>
      <P>We may request access or permission to certain features from your mobile device, including your
mobile device’s bluetooth, calendar, camera microphone, sensors, and storage, and other features. If
you wish to change our access or permissions, you may do so in your device’s settings.</P>

      <H4>Technical data</H4>
      <P>Data including information about use of our Application, internet protocol (IP) address, your login data,
browser type and version, time zone setting and location, browser plug-in types and versions,
operating system and platform, screen resolution and other technical characteristics of your device,
your use of our services and applications and connection to our Application.</P>

      <H4>Usage data</H4>
      <P>Data including information about your visit, the path that you take through and from the Application
      (including date and time); pages that you viewed or searched for; page response times, download
      errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and
      mouse-overs), and methods used to browse away from the page.</P>

      <H4>Mobile Device Data</H4>
      <P>Device information such as your mobile device ID number, model, and manufacturer, version of your
      operating system, phone number, country, location, and any other data you choose to provide.</P>

      <H4>Push Notifications</H4>
      <P>We may request to send you push notifications regarding your account or the Application. If you wish
      to opt-out from receiving these types of communications, you may turn them off in your device’s
      settings.</P>

      <H4>Data From using the Application and Surveys</H4>
      <P>Personal and other information you may provide when   using the Application or responding to
      surveys.</P>

      <P>We do not knowingly collect any special categories of personal data about you (such as details about
      your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions,
      trade union membership, information about your health and genetic and biometric data (other than
      your height and weight and any fitness statistics you may voluntarily choose to upload as a student
      athlete for coaches to view) or information about criminal convictions and offences.</P>
      
      <H3>USE OF YOUR INFORMATION</H3>
      <P>Having accurate information about you permits us to provide you with a smooth, efficient, and
      customized experience. Specifically, we may use information collected about you via the Application
      to:</P>

      <Ol>
        <Li>If you are a coach, put you in contact with appropriate athletes for your team and/or university.</Li>
        <Li>If you are an athlete, put you in contact with appropriate coaches for your education and scholarship application.</Li>
        <Li>Create and manage your account.</Li>
        <Li>Deliver targeted advertising newsletters, and other information regarding promotions and the Application to you.</Li>
        <Li>Email you regarding your account or service request.</Li>
        <Li>Enable user-to-recruitment agency communications.</Li>
        <Li>Generate a personal profile about you to make future visits to the Application more personalized.</Li>
        <Li>Increase the efficiency and operation of the Application.</Li>
        <Li>Monitor and analyze usage and trends to improve your experience with the Application.</Li>
        <Li>Notify you of updates to the Application.</Li>
        <Li>Offer new services and/or recommendations to you.</Li>
        <Li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</Li>
        <Li>If service fees are involved, process payments and/or refunds.</Li>
        <Li>Request feedback and contact you about your use of the Application.</Li>
        <Li>Resolve disputes and troubleshoot problems.</Li>
        <Li>Respond to service requests.</Li>
        <Li>Assist law enforcement and respond to subpoena.</Li>
        <Li>Compile anonymous statistical data and analysis for use internally or with third parties.</Li>
      </Ol>

      <H3>DISCLOSURE OF YOUR INFORMATION</H3>
      <P>We may use and share information we have collected about you in certain situations when the law
      allows us to. Your information may be disclosed as follows:</P>

      <H4>By Law or to Protect Rights</H4>
      <P>If we believe the release of information about you is necessary to respond to legal process, to
      investigate or remedy potential violations of our policies, or to protect the rights, property, and safety
      of others, we may share your information as permitted or required by any applicable law, rule, or
      regulation.  This includes exchanging information with other entities for fraud protection and credit risk
      reduction.</P>

      <H4>Third-Party Service Providers</H4>
      <P>We may share your information with third parties that perform services for us or on our behalf,
      including payment processing, data analysis, email delivery, hosting services, customer service, and
      marketing assistance.</P>

      <H4>Marketing Communications</H4>
      <P>With your specific and individual consent, or with an opportunity for you to withdraw such specific
      consent, we may share your information with recruitment agencies who uses the Application for
      marketing purposes to promote the Application and its services.</P>

      <H4>Interactions with Agencies and student athletes</H4>
      <P>If you interact with recruitment agencies who uses the Application, those users may see your name,
      profile photo, and descriptions of your activity, including sending invitations to other users, and
      chatting with other users.</P>

      <H4>Third-Party Advertisers</H4>
      <P>We may use third-party advertising companies to serve ads when you visit the Application. These
      companies may use information about your visits to the Application and other websites that are
      contained in web cookies in order to provide advertisements about goods and services of interest to
      you.</P>

      <H4>Affiliates</H4>
      <P>We may share your information with our affiliates, in which case we will require those affiliates to
      honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture
      partners or other companies that we control or that are under common control with us.</P>

      <H4>Recruitment agencies and athletes</H4>
      <P>We may share your information with our recruiting agencies and put you in direct contact with relevant
      athletes/students if you are a coach, and if you are an athlete put you in contact with relevant
      coaches.</P>

      <H4>Other Third Parties</H4>
      <P>We may share your pseudonymized information with advertisers and investors for the purpose of
      conducting general business analysis.</P>

      <H4>Sale or Bankruptcy</H4>
      <P>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another
      entity, we may transfer your information to the successor entity.  If we go out of business or enter
      bankruptcy, your information would be an asset transferred or acquired by a third party.  You
      acknowledge that such transfers may occur, and that the transferee may decline honor commitments
      we made in this Policy.</P>

      <P>We are not responsible for the actions of third parties with whom you share personal or sensitive data,
      and we have no authority to manage or control third-party solicitations.  If you no longer wish to
      receive correspondence, emails or other communications from third parties, you are responsible for
      contacting the third party directly.</P>

      <H3>TRACKING TECHNOLOGIES</H3>
      <H3>Cookies and Web Beacons</H3>
      <P>We may use cookies, web beacons, tracking pixels, and other tracking technologies on the
      Application to help customize the Application and improve your experience. When you access the
      Application, your personal information is not collected through the use of tracking technology. Most
      browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that
      such action could affect the availability and functionality of the Application. You may not decline web
      beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web
      browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline
      cookies on an individual basis.</P>

      <H3>Internet-Based Advertising</H3>
      <P>Additionally, we may use third-party software to serve ads on the Application, implement email
      marketing campaigns, and manage other interactive marketing initiatives.  This third-party software
      may use cookies or similar tracking technology to help manage and optimize your online experience
      with us.</P>

      <H4>Sub-processors</H4>
      <P>We rely on a handful of third-party suppliers who assist in the service we offer to you as a user. Some
      of these suppliers process your personal data to be able to execute the service they offer.
      We will only transfer Users’ personal data to third parties that we have confidence in. We carefully
      choose partners to ensure that the user’s personal data is processed in accordance to current privacy
      legislations. We cooperate with the following categories of processors of personal data; server and
      hosting companies, e-mail distributor companies, video processing companies, information-sourcing
      companies, analytical service companies and IT-development companies with regards to suppling the
      services and this Application.</P>

      <H3>THIRD-PARTY WEBSITES</H3>
      <P>The Application may contain links to third-party websites and applications of interest, including
      advertisements and external services, that are not affiliated with us. Once you have used these links
      to leave the Application, any information you provide to these third parties is not covered by this
      Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and
      providing any information to any third-party websites, you should inform yourself of the privacy
      policies and practices (if any) of the third party responsible for that website, and should take those
      steps necessary to, in your discretion, protect the privacy of your information. We are not responsible
      for the content or privacy and security practices and policies of any third parties, including other sites,
      services or applications that may be linked to or from the Application.</P>

      <H3><Strong>SECURITY OF YOUR INFORMATION</Strong></H3>
      <P>We use administrative, technical, and physical security measures to help protect your personal
      information. While we have taken reasonable steps to secure the personal information you provide to
      us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and
      no method of data transmission can be guaranteed against any interception or other type of misuse.
      Any information disclosed online is vulnerable to interception and misuse by unauthorized parties.
      Therefore, we cannot guarantee complete security if you provide personal information.</P>

      <H3>TRANSFER OF INFORMATION TO COUNTRIES OUTSIDE EU/EEA/UK</H3>

      <P>As a part of the services offered to you through the Application, the information you provide to us may
      be transferred to/from the European Union ((&ldquo;EU&rdquo;)), United Kingdom ((&ldquo;UK&rdquo;)) or the European Economic
      Area ((&ldquo;EEA&rdquo;)), provided you agree to receive information to/from recruitment agencies and athletes
      inside/outside the EU/EEA/UK (as applicable) if you are a coach, and vice versa if you are an athlete.
      Please note that such third countries may have different and less strict levels of protection of your
      personal data which is not always as strict as in EU/EEA/UK.</P>

      <P>If we transfer your information outside of the EU/EEA/UK where your personal data is stored, we will
      take steps to ensure that appropriate security measures are taken to ensure that your privacy rights
      continue to be protected as outlined in this Policy. You have when accepted this Policy, also in the
      click the box acceptance form, consented to such data transfer to third countries outside of the
      EU/EEAK/UK as applicable.</P>

      <H3>POLICY FOR CHILDREN</H3>
      <P>We do not knowingly solicit information from or market to children under the age of 13. In the event
      we learn that we have collected personal information from a child under age 13 without parental
      consent, we will delete that information as quickly as possible. If you become aware of any data we
      have collected from children under age 13, please contact us using the contact information provided
      below.</P>

      <H3>CONTROLS FOR DO-NOT-TRACK FEATURES</H3>
      <P>Most web browsers and some mobile operating systems include a Do-Not-Track (&ldquo;DNT&rdquo;) feature or
      setting you can activate to signal your privacy preference not to have data about your online browsing
      activities monitored and collected.  No uniform technology standard for recognizing and implementing
      DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any
      other mechanism that automatically communicates your choice not to be tracked online.  If a standard
      for online tracking is adopted that we must follow in the future, we will inform you about that practice
      in a revised version of this Policy.</P>

      <H3>OPTIONS REGARDING YOUR INFORMATION</H3>
      <H3>Account Information</H3>
      <P>You may at any time review or change the information in your account or terminate your account by:</P>
      <Ul>
        <Li>Logging into your account settings and updating your account</Li>
        <Li>Contacting us using the contact information provided below</Li>
        <Li>Enter your account settings and use the delete account button.</Li>
      </Ul>
      <P>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, and/or comply with legal requirements.</P>

      <H4>Emails and Communications</H4>
      <P>If you no longer wish to receive correspondence, emails, or other communications from us, you may
      opt-out by:</P>
      <Ul>
        <Li>Noting your preferences at the time you register your account with the Application</Li>
        <Li>Contacting us using the contact information provided below</Li>
      </Ul>
      <P>If you no longer wish to receive correspondence, emails, or other communications from third parties,
      you are responsible for contacting the third party directly.</P>
      
      <H3>CALIFORNIA PRIVACY RIGHTS</H3>
      <P>California Civil Code Section 1798.83, also known as the &ldquo;Shine The Light&rdquo; law, permits our users
      who are California residents to request and obtain from us, once a year and free of charge,
      information about categories of personal information (if any) we disclosed to third parties for direct
      marketing purposes and the names and addresses of all third parties with which we shared personal
      information in the immediately preceding calendar year. If you are a California resident and would like
      to make such a request, please submit your request in writing to us using the contact information
      provided below.</P>

      <P>If you are under 18 years of age, reside in California, and have a registered account with the
      Application, you have the right to request removal of unwanted data that you publicly post on the
      Application. To request removal of such data, please contact us using the contact information
      provided below, and include the email address associated with your account and a statement that you
      reside in California.  We will make sure the data is not publicly displayed on the Application, but
      please be aware that the data may not be completely or comprehensively removed from our systems.</P>
      
      <H3>CONTACT US</H3>
      <P>If you have questions or comments about this Policy, please contact us at:</P>
      <Ul>
        <Li>THE ALLIANCE APP LTD UK company # 12964225</Li>
        <Li>9 Haines Watts (C/O The Alliance App Ltd), Abbey Lane, Evesham, WR11 4BY, United Kingdom +34 625 110 046 info@allianceathletes.com</Li>
      </Ul>

      <H3>OTHER LEGAL RIGHTS</H3>
      <P>You have the right to:</P>
      <Ul>
        <Li>Request access. This means you can ask us to give you a copy of the information we hold about you.</Li>
        <Li>Request correction. This means if we have incomplete or inaccurate information, you can ask us to correct it. It is important that the information we hold about you is accurate and current. Please keep us informed if the information you provide us changes as our relationship evolves. Note, we may need to verify the accuracy of the new data you provide to us.</Li>
        <Li>Request erasure. This means you can ask us to delete or remove any information we have if there is no good reason for us continuing to have it. You also have the right to ask us to delete or remove information where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your information to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</Li>
        <Li>Object to processing. This means you can contact us if you feel our use of your information impacts on your rights and freedoms. This applies if we are: (a) relying on our legitimate interest (or those of a third party) to use your information; (b) if we are using your information for direct marketing purposes; (c) if you believe we are using your information unlawfully; or (d) where we need to erase you information to comply with local laws. It is unlikely, but in some cases we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</Li>
        <Li>Request restriction. This means you can ask us to suspend using your information in the following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the information is unlawful but you do not want us to erase it; (c) where you need us to hold the information even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your information but we need to verify whether we have overriding legitimate grounds to use it.</Li>
        <Li>Request the transfer. This means you can ask us to transfer the information we hold on you to you, or to a third party. We will provide to you, or the third party you have chosen, your information in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use, or where we used the information to perform a contract with you.</Li>
        <Li>Withdraw consent. This means you can tell us that you no longer consent to our use of your information at any time where we are relying on consent to process your information. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent. Note that this will only apply to any information where we were previously relying on consent as the legal basis to use your information. This action will also not affect the lawfulness of any processing carried out before you withdraw your consent.</Li>
      </Ul>
      <P>What we may need from you:</P>
      <P>We may need to request specific information from you to help us confirm your identity and your rights in relation to your request. This is a security measure to ensure that information is not disclosed to any person who does not have a right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</P>
      <P>Time limit to respond:</P>
      <P>We try to respond to all legitimate requests as soon as reasonably practicable and at least within one month. Occasionally, it may take us longer if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</P>
    

      <br/>
      {
        !user.signInUserSession && (
          <AcceptButton onClick={acceptTerms}><span>I ACCEPT PRIVACY TERMS</span></AcceptButton>
        )
      }
    </ScrollableContainer>
  );
}

export default LegalText;

const H1 = styled.h1`
  display: block;
  font-size: 2em;
  font-weight: bold;
  margin-block-start: .67em;
  margin-block-end: .67em;
`;
const H2 = styled.h2`
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin-block-start: .83em;
  margin-block-end: .83em;
`;
const H3 = styled.h3`
  display: block;
  font-size: 1.17em;
  font-weight: bold;
  margin-block-start: 1em;
  margin-block-end: 1em;
`;
const H4 = styled.h4`
  display: block;
  font-size: 1.00em;
  font-weight: bold;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
`;
const H5 = styled.h5`
  display: block;
  font-size: 0.83em;
  font-weight: bold;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
`;
const H6 = styled.h6`
  display: block;
  font-size: 0.67em;
  font-weight: bold;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
`;
const P = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  line-height: 1.5;
`;
const Ul = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
  counter-reset: list-item;
`;
const Ol = styled.ol`
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
  counter-reset: list-item;
`;
const Li = styled.li`
    display: list-item;
    text-align: match-parent;
    line-height: 1.5;
`;
const Strong = styled.strong`
  font-weight: bolder;
`;
const AcceptButton = styled.div`
  cursor: pointer;
  background-color: ${theme.colors.royalBlue};
  padding: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  float: right;
  width: 300px;
  margin: 30px 0px;
  border-radius: 3px;
`;

const ScrollableContainer = styled.div`
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2D334A;
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
  
`;