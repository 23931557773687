import styled from 'styled-components'

const PremiumInfo = () => (
  <Container>
    <Title>Upgrade to PREMIUM</Title>
    <Description>
      Save time with your recruiting and identify your most suitable recruits
      more easily by taking full advantage of the Premium filters and features.
    </Description>
    <ListContainer>
      <div style={{ width: '40%' }}>
        <Ul>
          <Li>Budget Filter</Li>
          <Li>Current Status Filter</Li>
          <Li>Showcase Filter</Li>
          <Li>PSA With Videos Filter</Li>
          <Li>Competition Country Filter</Li>
          <Li>Agency Filter</Li>
          <Li>Type of Admission Filter</Li>
        </Ul>
      </div>
      <div style={{ width: '60%' }}>
        <Ul>
          <Li>Saved Searches (save your previous search)</Li>
          <Li>PSA management features</Li>
          <Li>Rate and tag PSAs</Li>
          <Li>
            Notifications (receive customized notifications and app updates)
          </Li>
          <Li>Destination college/university for the committed PSAs</Li>
          <Li>Priority customer service</Li>
          <Li>No Ads</Li>
        </Ul>
      </div>
    </ListContainer>
  </Container>
)

const Container = styled.div`
  text-align: center;
  padding: 25px;
`

const Title = styled.h1`
  font-size: 2.5rem;
  margin: 20px 0px;
`

const Description = styled.p`
  text-align: left;
  font-weight: 500;
  line-height: 1.5;
`

const ListContainer = styled.div`
  display: flex;
  justify-content: left;
  margin: 30px 0px 30px 10px;
`

const Ul = styled.ul`
  list-style-type: disc;
  padding-left: 0; /* Elimina el relleno predeterminado de la lista */
  text-align: left; /* Alinea el texto de la lista a la izquierda */
`

const Li = styled.li`
  margin-bottom: 5px;
`

export default PremiumInfo
