export const listAthletes = /* GraphQL */ `
  query ListAthletes(
    $filter: ModelAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthletes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        agencyID
        agentID
        isVisible
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        destination_college
        type_of_admission
        weight
        height
        sport
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        showcases_participation {
          items {
            id
            showcaseID
            showcase {
              name
            }
            athleteID
            color
            number
            createdAt
            updatedAt
          }
          nextToken
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        sports_background
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agencyID
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getAthlete = /* GraphQL */ `
  query GetAthlete($id: ID!) {
    getAthlete(id: $id) {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
      }
      other_videos {
        url
        metadata
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
        }
        agents {
          nextToken
        }
        coach_messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
        }
        ahtletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorited {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
        }
        nextToken
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createAthleteEnquiry = /* GraphQL */ `
  mutation CreateAthleteEnquiry(
    $input: CreateAthleteEnquiryInput!
  ) {
    createAthleteEnquiry(input: $input) {
      id
	}
}
`;
export const createAthleteTag = /* GraphQL */ `
  mutation CreateAthleteTag(
    $input: CreateAthleteTagInput!
    $condition: ModelAthleteTagConditionInput
  ) {
    createAthleteTag(input: $input, condition: $condition) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAthleteTag = /* GraphQL */ `
  mutation UpdateAthleteTag(
    $input: UpdateAthleteTagInput!
    $condition: ModelAthleteTagConditionInput
  ) {
    updateAthleteTag(input: $input, condition: $condition) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAthleteTag = /* GraphQL */ `
  mutation DeleteAthleteTag(
    $input: DeleteAthleteTagInput!
    $condition: ModelAthleteTagConditionInput
  ) {
    deleteAthleteTag(input: $input, condition: $condition) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAthleteTagsRelation = /* GraphQL */ `
  mutation CreateAthleteTagsRelation(
    $input: CreateAthleteTagsRelationInput!
    $condition: ModelAthleteTagsRelationConditionInput
  ) {
    createAthleteTagsRelation(input: $input, condition: $condition) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAthleteTagsRelation = /* GraphQL */ `
  mutation UpdateAthleteTagsRelation(
    $input: UpdateAthleteTagsRelationInput!
    $condition: ModelAthleteTagsRelationConditionInput
  ) {
    updateAthleteTagsRelation(input: $input, condition: $condition) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAthleteTagsRelation = /* GraphQL */ `
  mutation DeleteAthleteTagsRelation(
    $input: DeleteAthleteTagsRelationInput!
    $condition: ModelAthleteTagsRelationConditionInput
  ) {
    deleteAthleteTagsRelation(input: $input, condition: $condition) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAthleteTagsRelations = /* GraphQL */ `
  query ListAthleteTagsRelations(
    $filter: ModelAthleteTagsRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthleteTagsRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        athleteID
        tagID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          facebook_url
          twitter_user
          linkedin_url
          createdAt
          updatedAt
        }
        tag {
          id
          text
          type
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;