import React, { useContext, useEffect, useRef } from 'react'
import { DataContext } from './dataContext'
import { API } from 'aws-amplify'
import {
  onCreateNotificationRelationByCoachId,
  onUpdateNotificationRelationByCoachId,
  onUpdateCoachAthleteFavoritesByCoachId,
  onCreateAthleteFavoritesByCoachId,
  onDeleteCoachAthleteFavoritesByCoachId,
  onDeleteFavoritesFolderByCoachId,
  onCreateFavoritesFolderByCoachId,
  onCreateFavoritesFolderAthleteRelationByFolderId,
  onDeleteFavoritesFolderAthleteRelationByFolderId,
  onUpdateTeamById,
} from '../custom_graphql_queries/subscriptions'

const SubscriptionProvider = ({ children }) => {
  const dataContext = useContext(DataContext)
  const {
    user,
    setUser,
    userCoachData,
    setUserCoachData,
    setNotifications,
    ratingIsChanged,
    setRatingIsChanged,
    totalFavorites,
    setTotalFavorites,
    athletes,
    receivingItemList,
    setReceivedItemList,
    athletesInFolder,
    setAthletesInFolder,
  } = dataContext

  const createSubscriptionNotifications = () => {
    try {
      API.graphql({
        query: onCreateNotificationRelationByCoachId,
        variables: {
          coachID: user?.attributes['custom:profile_id'],
        },
      }).subscribe({
        next: ({ value }) => {
          setNotifications((notifications) => [
            value.data.onCreateNotificationRelationByCoachId,
            ...notifications,
          ])
        },
        error: (error) => {
          console.log('[ERROR] Creation subscription:', error)
        },
      })
    } catch (error) {
      console.log('[ERROR] Creation subscription:', error)
    }
  }

  const updateSubscriptionNotifications = () => {
    try {
      API.graphql({
        query: onUpdateNotificationRelationByCoachId,
        variables: {
          coachID: user?.attributes['custom:profile_id'],
        },
      }).subscribe({
        next: ({ value }) => {
          setNotifications((notifications) =>
            notifications.map((item) => {
              if (
                item.id === value.data.onUpdateNotificationRelationByCoachId?.id
              ) {
                return { ...value.data.onUpdateNotificationRelationByCoachId }
              }
              return item
            })
          )
        },
        error: (error) => {
          console.log('[ERROR] Update subscription:', error)
        },
      })
    } catch (error) {
      console.log('[ERROR] Update subscription:', error)
    }
  }

  const updateCoachAthleteFavorites = () => {
    try {
      let subscription = API.graphql({
        query: onUpdateCoachAthleteFavoritesByCoachId,
        variables: {
          coachID: user?.attributes['custom:profile_id'],
        },
      }).subscribe({
        next: ({ value }) => {
          setTotalFavorites((totalFavorites) =>
            totalFavorites.map((item) => {
              if (
                item.athleteID ===
                value.data.onUpdateCoachAthleteFavoritesByCoachId.athleteID
              ) {
                return {
                  ...item,
                  rating:
                    value.data.onUpdateCoachAthleteFavoritesByCoachId.rating,
                }
              }
              return item
            })
          )
          setRatingIsChanged((ratingIsChanged) => !ratingIsChanged)
        },
        error: (error) => {
          console.log('[ERROR] Update subscription:', error)
        },
      })
      return () => subscription.unsubscribe()
    } catch (error) {
      console.log('[ERROR] updateCoachAthleteFavorites subscription:', error)
    }
  }

  const createCoachAthleteFavorites = () => {
    try {
      let subscription = API.graphql({
        query: onCreateAthleteFavoritesByCoachId,
        variables: {
          coachID: user?.attributes['custom:profile_id'],
        },
      }).subscribe({
        next: ({ value }) => {
          const athlete = allAthletes.current.find(
            (r) =>
              r.id === value.data.onCreateAthleteFavoritesByCoachId.athleteID
          )
          setTotalFavorites((totalFavorites) => [
            ...totalFavorites,
            {
              ...value.data.onCreateAthleteFavoritesByCoachId,
              athlete: { ...athlete, isFavorited: true },
            },
          ])
          // setUserCoachData((userCoachData)=>({ ...userCoachData, favorites: { items: [...userCoachData.favorites.items, value.data.onCreateAthleteFavoritesByCoachId] } }))
        },
        error: (error) => {
          console.log('ERROR', error)
        },
      })
      return () => subscription.unsubscribe()
    } catch (error) {
      console.log('[ERROR] createCoachAthleteFavorites subscription:', error)
    }
  }

  const deleteCoachAthleteFavorites = () => {
    let subscription = API.graphql({
      query: onDeleteCoachAthleteFavoritesByCoachId,
      variables: {
        coachID: user?.attributes['custom:profile_id'],
      },
    }).subscribe({
      next: ({ value }) => {
        // setUserCoachData((userCoachData)=>({ ...userCoachData, favorites: { items: [ userCoachData.favorites.items.map( r=> { r.athleteID !== value.data.onDeleteCoachAthleteFavoritesByCoachId.athleteID})] } }))
        setTotalFavorites((totalFavorites) =>
          totalFavorites.filter(
            (r) =>
              r.athleteID !==
              value.data.onDeleteCoachAthleteFavoritesByCoachId.athleteID
          )
        )
        setRatingIsChanged((ratingIsChanged) => !ratingIsChanged)
      },
      error: (error) => {
        console.log('ERROR', error)
      },
    })
    return () => subscription.unsubscribe()
  }

  const createFavoritesFolder = () => {
    try {
      let subscription = API.graphql({
        query: onCreateFavoritesFolderByCoachId,
        variables: {
          coachID: user?.attributes['custom:profile_id'],
        },
      }).subscribe({
        next: ({ value }) => {
          setReceivedItemList((receivingItemList) => [
            ...receivingItemList,
            {
              id: value.data.onCreateFavoritesFolderByCoachId.id,
              name: value.data.onCreateFavoritesFolderByCoachId.folder_name,
            },
          ])
        },
        error: (error) => {
          // console.log('ERROR delete', error)
        },
      })
      return () => subscription.unsubscribe()
    } catch (error) {
      console.log('[ERROR] createFavoritesFolder subscription:', error)
    }
  }

  const deleteFavoritesFolder = () => {
    try {
      let subscription = API.graphql({
        query: onDeleteFavoritesFolderByCoachId,
        variables: {
          coachID: user?.attributes['custom:profile_id'],
        },
      }).subscribe({
        next: ({ value }) => {
          setReceivedItemList((receivingItemList) =>
            receivingItemList.filter(
              (r) => r.id !== value.data.onDeleteFavoritesFolderByCoachId.id
            )
          )
        },
        error: (error) => {
          // console.log('ERROR delete', error)
        },
      })
      return () => subscription.unsubscribe()
    } catch (error) {
      console.log('[ERROR] createFavoritesFolder subscription:', error)
    }
  }

  const createFavoritesFolderAthleteRelation = (folder_id) => {
    try {
      let subscription = API.graphql({
        query: onCreateFavoritesFolderAthleteRelationByFolderId,
        variables: {
          favoritesFolderID: folder_id,
        },
      }).subscribe({
        next: ({ value }) => {
          setAthletesInFolder((athletesInFolder) => [
            ...athletesInFolder,
            value.data.onCreateFavoritesFolderAthleteRelationByFolderId,
          ])
        },
        error: (error) => {
          // console.log('ERROR delete', error)
        },
      })
      return () => subscription.unsubscribe()
    } catch (error) {
      console.log('[ERROR] createFavoritesFolder subscription:', error)
    }
  }

  const deleteFavoritesFolderAthleteRelation = (folder_id) => {
    try {
      let subscription = API.graphql({
        query: onDeleteFavoritesFolderAthleteRelationByFolderId,
        variables: {
          favoritesFolderID: folder_id,
        },
      }).subscribe({
        next: ({ value }) => {
          setAthletesInFolder((athletesInFolder) =>
            athletesInFolder.filter(
              (r) =>
                r.athleteID !==
                value.data.onDeleteFavoritesFolderAthleteRelationByFolderId
                  .athleteID
            )
          )
        },
        error: (error) => {
          // console.log('ERROR delete', error)
        },
      })
      return () => subscription.unsubscribe()
    } catch (error) {
      console.log('[ERROR] createFavoritesFolder subscription:', error)
    }
  }

  const updateTeamById = (teamID) => {
    try {
      API.graphql({
        query: onUpdateTeamById,
        variables: {
          id: teamID,
        },
      }).subscribe({
        next: ({ provider, value }) => {
          setUserCoachData((userCoachData) => ({
            ...userCoachData,
            team: value.data.onUpdateTeamById,
          }))
        },
        error: (error) => {},
      })
    } catch (error) {
      console.log('[ERROR] onUpdateTeamById subscription:', error)
    }
  }

  const allAthletes = useRef()

  useEffect(() => {
    if (
      user &&
      user !== 'notLoggedIn' &&
      user.challengeName !== 'newPasswordRequired'
    ) {
      createSubscriptionNotifications()
      updateSubscriptionNotifications()
      createCoachAthleteFavorites()
      updateCoachAthleteFavorites()
      deleteCoachAthleteFavorites()
      createFavoritesFolder()
      deleteFavoritesFolder()
    }
  }, [user])

  useEffect(() => {
    if (userCoachData) {
      updateTeamById(userCoachData.teamID)
    }
  }, [userCoachData])

  useEffect(() => {
    allAthletes.current = athletes
  }, [athletes])

  useEffect(() => {
    if (Object.keys(receivingItemList).length > 0) {
      receivingItemList.map((r) => {
        createFavoritesFolderAthleteRelation(r.id)
        deleteFavoritesFolderAthleteRelation(r.id)
      })
    }
  }, [receivingItemList])

  return <>{children}</>
}

export default SubscriptionProvider
