import styled from 'styled-components'

const PricingOption = ({ price, onClick, type, period, discount, line }) => {
  if (!type) return null // Devuelve null si no hay tipo

  return (
    <Option discount={discount}>
      {discount ? (
        <DiscountTag>{discount}</DiscountTag>
      ) : (
        <div style={{ height: '37px' }}></div>
      )}{' '}
      {/* Agrega un div vacío como marcador de posición si no hay descuento */}
      <Wrapper>
        <SubscriptionPeriod>{period}</SubscriptionPeriod>
        <Price>{price}</Price>
        <Info>{line}</Info>
        <Button onClick={onClick} discount={discount}>
          Continue
        </Button>
      </Wrapper>
    </Option>
  )
}

/* Pricing options component */
const Option = styled.div`
  border: ${(props) =>
    props.discount ? '2px solid #fd5147' : '2px solid #b3b3b3'};

  border-radius: 5px;
  padding: 10px 0px;
  margin: 10px 20px 40px 20px;
  transition: transform 0.2s; /* Animación */
  min-width: 200px; /* Tamaño mínimo */
  display: inline-block;
  vertical-align: top; /* Alineación en la parte superior */
  &:hover {
    transform: scale(1.05); /* Ligero zoom al pasar el mouse */
    /*border-color: #0056b3; */ /* Cambia el color del borde al pasar el mouse */
  }
`

const Wrapper = styled.div`
  padding: 20px 20px;
  min-width: 240px; /* Tamaño mínimo */
`

const DiscountTag = styled.div`
  background-color: #fd5147;
  color: #fff;
  padding: 10px;
  font-size: 1.1rem;
  font-weight: bolder;
  position: relative;
  top: -10px;
  width: 50%;
  margin: 0 auto; /* Centrado */
  width: 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`

const SubscriptionPeriod = styled.div`
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: normal;
  text-transform: uppercase;
`

const Price = styled.div`
  margin: 25px 0;
  font-size: 3rem;
  letter-spacing: 2px;
`

const Info = styled.div`
  margin-bottom: 40px;
  font-size: 1rem;
  text-transform: uppercase;
`

const Button = styled.div`
  padding: 12px;
  font-size: 1rem;
  color: #fff;
  background-color: ${(props) => (props.discount ? '#fd5147' : '#b3b3b3')};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: #0056b3;
  }
`

export default PricingOption
