import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import {
  filter,
} from 'lodash';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import ListItem from '../../components/Athletes/ListItem';
import theme from '../../styles/theme.styles';
import RightColumn from '../../components/_Layout/RightColumn';
import { createFavoritesFolderAthleteRelation, listFavoritesFolders } from '../../custom_graphql_queries/coachs';
import { DragDropContext } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import Popup from '../../components/_FormElements/Popup';
import CreateFolderNameForm from '../../components/Favorites/CreateFolderNameForm';
import DroppableArea, { DroppableAreaRoot } from '../../components/Favorites/DroppableArea';

function Favorites({ children }) {
  const location = useLocation();
  const isInFavoritesAthletePage = () => location.pathname.substr(0, 19) === '/favorites/athlete_';
  const isInFavoritesPage = () => location.pathname === '/favorites/';

  const dataContext = useContext(DataContext);
  const { 
    userCoachData, 
    athletes, 
    dragFavoriteItemRootFolder, 
    setDragFavoriteItemRootFolder, 
    isDataProcessed, 
    totalFavorites, 
    setTotalFavorites, 
    athletesInFolder, 
    setAthletesInFolder, 
    ratingIsChanged, 
    setRatingIsChanged, 
    openPopup, 
    setOpenPopup,
    receivingItemList,
    setReceivedItemList
  } = dataContext

  // const [visibleItems, setVisibleItems] = useState([]);
  const [activeTab, setActiveTab] = useState('Prospects');
  const getFavoritedAthletes = (tempAthletes) => filter(tempAthletes, { isFavorited: true });
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()
  
  // const listFolders = async () => {
	// 	let folders = [];
  // 	let response = {};
	// 	let params = { 
	// 		query: listFavoritesFolders, 
	// 		variables: { 
	// 			limit: 500,
	// 			filter: {
	// 				coachID: { eq : userCoachData.id }
	// 			}
	// 		}
	// 	}
	// 	try {
	// 		response = await API.graphql(params);
	// 		folders = response.data.listFavoritesFolders.items
	// 		while(response.data.listFavoritesFolders.nextToken) {
	// 			params.variables.nextToken = response.data.listFavoritesFolders.nextToken;
	// 			response = await API.graphql(params);
	// 			folders = [...folders, ...response.data.listFavoritesFolders.items];
	// 		}

	// 		return folders
			
	// 	} catch (e) {
	// 		console.log(e);
  //     throw new Error('ERROR LISTANDO CARPETAS!!');
	// 	}
	// }
	
  const createFolderRelation = async ({destinationFolderID, athleteID}) => {
    try {
      const response = await API.graphql({ 
        query: createFavoritesFolderAthleteRelation, 
        variables: { 
          input: {
            athleteID: athleteID,
            favoritesFolderID: destinationFolderID
          }
        }
      })

      return response
    } catch (e) {
      console.log(e);
      throw new Error('ERROR INSERTANDO EN CARPETA!!');
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    // id de la carpeta
    const sourceFolderID = source.droppableId
    const destinationFolderID = destination.droppableId
    // id del atleta
    const athleteID = draggableId
    
    if (sourceFolderID !== destinationFolderID) {
      createFolderRelation({destinationFolderID, athleteID}).then(r=>{
        let newDragItemlist = dragFavoriteItemRootFolder.filter(item => item.athleteID !== athleteID)
        let athlete = totalFavorites.find(res=> res.athleteID === athleteID)

        setDragFavoriteItemRootFolder(newDragItemlist);
        setAthletesInFolder([...athletesInFolder, {...athlete, favoritesFolderID: destinationFolderID, id: r.data.createFavoritesFolderAthleteRelation.id }])
      }).catch(e=>{
        console.log(e.message)
      })
    }
  };

  // useEffect(() => {
  //   if(userCoachData){
  //     listFolders().then((response)=>{
  //       setReceivedItemList(
  //         response.map(item => {
  //           return {
  //             "id": item.id,
  //             "name": item.folder_name
  //           }
  //         })
  //         )
  //       })
  //   }
	// 	return () => {
  //     setReceivedItemList([])
  //   }
  // }, [userCoachData])

  useEffect(() => {
    if (dataContext.totalFavorites) {
      setTotalFavorites(dataContext.totalFavorites)
      const atheletesInRootFolder = totalFavorites.filter(({ athleteID: id1 }) => !athletesInFolder.some(({ athleteID: id2 }) => id2 === id1)) 
      setDragFavoriteItemRootFolder(atheletesInRootFolder)
    }
  }, [dataContext.totalFavorites, ratingIsChanged, athletesInFolder]);

  const buttons = ['Favorites', 'Prospects'];

  const handleClose = () => {
    setIsOpen(!isOpen)
    setOpenPopup(!openPopup)
  }
  const handleAddNewFolder = () => {
    setIsOpen(true)
    setOpenPopup(true)
  }

  return (
    <>
      {
        userCoachData && (
          <Layout>

            {
              ((userCoachData.team.suscription_name === 'PREMIUM_PASS' || (userCoachData?.team?.suscription_name === 'SEASON_PASS' && userCoachData?.team?.had_trial !== null)) && isOpen) && (
                <Popup
                  isOpen={isOpen}
                  bgcolor={theme.colors.brandDark}
                  textAlign="left"
                  content={
                    <CreateFolderNameForm 
                      setReceivedItemList={setReceivedItemList} 
                      receivingItemList={receivingItemList}
                      setIsOpen={setIsOpen}
                    />
                  }
                  handleClose={handleClose}
                />
              )
            }
            <RightColumn />
            <ContentWrapper>
              <FavoritesLayout>
                <Header ocult={openPopup}>
                  <ButtonContainer>
                    { 
                      buttons.map((item, index) => (
                        <Button style={{backgroundColor: item === activeTab ? theme.colors.royalBlue : 'transparent'}} onClick={()=> setActiveTab(item)} key={index}>
                          <ButtonText>{item}</ButtonText>
                          {
                            item === 'Prospects' && (
                              <svg width="18" height="18" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 0C7.14567 0 4 3.14567 4 7V9H2C0.895 9 0 9.895 0 11V23C0 24.105 0.895 25 2 25H20C21.105 25 22 24.105 22 23V11C22 9.895 21.105 9 20 9H18V7C18 3.27158 15.0366 0.268565 11.3555 0.0722656C11.2424 0.0267105 11.1219 0.00221916 11 0V0ZM11 2C13.7737 2 16 4.22633 16 7V9H6V7C6 4.22633 8.22633 2 11 2Z" fill="white"/>
                              </svg>
                            )
                          }
                        </Button>
                      ))
                    }
                  </ButtonContainer>
                </Header>
                <ListScroll>
                  <HeaderSpacer />
                  { 
                    ( activeTab === 'Prospects') ? (
                      (userCoachData.team.suscription_name === 'PREMIUM_PASS' || (userCoachData?.team?.suscription_name === 'SEASON_PASS' && userCoachData?.team?.had_trial !== null)) ? (
                        <>
                          {/* Create new folder button */}
                          <NewFolderButtonContainer onClick={handleAddNewFolder}>
                            <NewFolderButtonContainerIcon>
                              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11 11V0H12V11H23V12H12V23H11V12H0V11H11Z" fill="white"/>
                              </svg>
                            </NewFolderButtonContainerIcon>
                            <span style={{cursor: "pointer"}}>Add New Folder</span> 
                          </NewFolderButtonContainer>
                          {/* Dropable zone */}
                          <DragnDropContainer>
                            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                            {
                              (receivingItemList.length || dragFavoriteItemRootFolder.length) > 0 && (
                                <DragDropContextInner>
                                  {
                                    receivingItemList.map((item, index)=>{
                                      return (
                                        <DroppableArea
                                          droppableId={item.id}
                                          key={index}
                                          index={index}
                                          name={item.name}
                                          placeholder={''}
                                          setReceivedItemList={setReceivedItemList}
                                          receivingItemList={receivingItemList}
                                        />
                                      )
                                    })
                                  }
                                  {
                                    dragFavoriteItemRootFolder.length > 0 && (
                                      <DroppableAreaRoot
                                        droppableId={uuid()}
                                        key={uuid()}
                                        index={1111}
                                        items={dragFavoriteItemRootFolder}
                                        isDropDisabled={true}
                                        placeholder={''}
                                        ratingIsChanged={ratingIsChanged}
                                        setRatingIsChanged={setRatingIsChanged}
                                      />
                                    )
                                  }
                                </DragDropContextInner>
                              )
                            }
                            </DragDropContext>
                          </DragnDropContainer>
                        </>
                      ) : (
                        // The user is not premium
                        <RestrictedArea>
                          <MessageArea>
                            <span>To access this area you need to be Premium user.</span> <SpanLink onClick={()=>navigate('/payments')}>Learn more</SpanLink>
                          </MessageArea>
                          <svg width="300" height="350" viewBox="0 0 549 623" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M215.4 1.66797C211.933 2.60132 208.067 4.20129 206.733 5.13464C199.8 10.3347 196.333 14.468 193.533 20.468L190.333 27.1346V152.068V277.135L182.333 281.135L174.467 285.135L174.067 180.735C173.667 65.2679 174.2 72.468 164.867 61.8013C156.2 51.9346 141 47.9346 127.4 52.068C120.067 54.2013 110.6 63.1346 106.867 71.1346L103.667 77.8013V224.335C103.667 305.268 103.133 370.468 102.467 369.668C101.4 368.468 71.9334 294.601 68.8667 285.135C67.2667 280.335 62.0667 272.468 58.3334 269.401C57.2667 268.468 53.4001 266.201 49.6667 264.468C27.4001 253.535 0.600072 270.601 0.733406 295.401C0.866739 302.201 6.60007 329.935 32.4667 447.135C38.0667 472.735 41.9334 484.601 49.6667 500.468C71.2667 545.135 111.8 582.601 161.267 603.668C189.667 615.801 213 621.135 242.333 622.068C253.667 622.335 266.467 622.201 270.867 621.535L278.6 620.468L266.867 612.468C242.2 595.935 214.867 564.735 201 537.135C152.867 441.268 192.067 322.601 288.2 273.801C302.2 266.735 321.267 259.801 333.667 257.135C337.4 256.468 341.667 255.535 343.4 255.135L346.467 254.335L346.067 153.401L345.667 52.468L342.733 47.0013C328.6 20.6013 293.4 20.6013 279.267 47.0013L276.333 52.468L275.933 157.801L275.667 263.135L267.933 262.468L260.333 261.801L259.667 143.135C259 31.2679 258.867 24.2013 256.6 19.5346C252.733 11.9346 243.667 4.33466 235.533 1.93463C227.267 -0.598694 223.8 -0.598694 215.4 1.66797Z" fill="white" fill-opacity="0.4"/>
                            <path d="M383 163.135C375.133 166.068 368.067 172.468 363.933 180.335C360.333 187.135 360.333 187.268 359.933 219.801L359.533 252.468L380.6 252.601C399.667 252.601 413.4 254.201 427.4 257.935L430.333 258.735V225.001C430.333 205.801 429.667 189.001 429 186.201C427 179.135 420.067 170.601 412.6 165.935C405 161.268 391.533 159.935 383 163.135Z" fill="white" fill-opacity="0.4"/>
                            <path d="M348.333 280.601C289.8 291.001 240.067 331.801 218.467 387.135C210.333 407.535 207.267 424.601 207.133 448.468C207 471.268 208.733 482.335 215.133 502.201C247.533 601.535 360.067 649.135 453.533 602.868C503.4 578.335 538.067 531.401 547.134 476.468C551 452.201 548.467 419.535 540.734 395.801C521.267 335.801 465.267 288.601 403.133 279.935C389 277.935 361.267 278.335 348.333 280.601ZM413.267 306.868C491.667 327.268 539.134 404.735 520.867 482.468C507.534 539.401 462.2 582.868 404.6 593.801C391.267 596.335 364.333 596.335 351.4 593.935C301.133 584.335 259.4 549.668 241 502.068C220.467 449.268 232.333 389.801 271.533 348.735C285.133 334.601 296.2 326.335 313.667 317.801C337.667 305.935 354.467 302.335 382.333 303.135C396.067 303.401 403.4 304.335 413.267 306.868Z" fill="#2049DA"/>
                            <path d="M365.133 333.135C348.733 336.601 331.4 349.401 323.667 363.935C316.867 376.868 315.667 383.135 315.667 408.735V431.801H309C299.8 431.801 292.467 435.668 289 442.468C286.467 447.401 286.333 450.201 286.333 486.601C286.333 524.201 286.467 525.668 289.267 530.735C290.867 533.535 294.333 537.268 297 538.868C301.667 541.801 302.067 541.801 374.6 542.201C425.8 542.468 449.267 542.068 453.267 541.135C460.467 539.135 467 532.468 469 525.135C470.067 521.135 470.467 508.068 470.067 483.268C469.667 452.068 469.4 446.601 467.4 442.868C463.667 436.201 457.667 432.735 448.734 432.068L441 431.535V413.268C441 388.735 439.267 378.468 433.667 367.001C426.467 352.068 413.934 341.001 398.6 335.668C389.934 332.601 373.667 331.401 365.133 333.135ZM396.067 353.535C410.2 359.935 419.8 372.201 421.934 386.601C422.6 390.468 422.867 402.335 422.6 412.735L422.067 431.801H377.4H332.733L333.267 407.401C333.667 384.335 333.8 382.735 337.133 375.801C348.067 353.135 373.8 343.268 396.067 353.535ZM387.133 459.401C394.734 464.335 396.467 479.801 390.467 487.801C388.2 490.868 387.667 493.535 387.667 501.135C387.667 509.401 387.267 511.001 384.334 513.801C379.267 519.001 372.867 518.068 369.667 511.668C368.6 509.668 367.667 504.335 367.667 499.935C367.667 494.201 366.867 490.601 364.867 487.135C361.133 481.001 361.667 466.868 365.667 462.335C371.4 455.935 380.2 454.735 387.133 459.401Z" fill="#2049DA"/>
                          </svg>
                        </RestrictedArea>
                      )
                    ) : (
                      // Flat list for favorites
                      totalFavorites.length > 0
                      && totalFavorites.map((athlete) => (
                        <ListItem athlete={athlete.athlete} key={athlete.id} path={`/favorites/athlete_${athlete.athlete.id}`} favoriteToggle={dataContext.favoriteToggle} />
                      ))
                    )
                  }
                  {
                    totalFavorites.length === 0 && 
                    <NoResults>No results...</NoResults>
                  }
                </ListScroll>
              </FavoritesLayout>

            </ContentWrapper>
            <AnimatePresence>
              <Outlet />
            </AnimatePresence>
          </Layout>
        )  
      }
    </>
  );
}

Favorites.propTypes = {};

Favorites.defaultProps = {};

export default Favorites;

const RestrictedArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
`
const MessageArea = styled.div`
  margin: 10px
`
const FavoritesLayout = styled.div`
  position: relative;
`;
const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
  z-index: 2;
`;
const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  width: 100% ;
  top: 0;
  left: 0;
  background: rgb(6,14,40);
  background: linear-gradient(180deg, rgba(6,14,40,1) 0%, rgba(6,14,40,1) 89%, rgba(6,14,40,0) 100%);
  z-index: ${(props) => props.ocult ? '1' : '2'};
  @media ${theme.mq.desktop} { 
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const NoResults = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 15px;
  font-weight: 400;
`;
const ListScroll = styled.div`
  position: absolute;
  overflow: auto;
  width: 100% ;
  top: 20px;
  left: 0;
  height: 100vh;
  z-index: 1;
  padding-right: 10px;
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2D334A;
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;  
    width: 5px;
  }
`;
const NewFolderButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`
const NewFolderButtonContainerIcon = styled.div`
  border: 1px solid white;
  justify-content: center;
  display: flex;
  margin: 8px 0;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  align-items: center
`
const DragnDropContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`
const DragDropContextInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 999;
`
const ButtonContainer = styled.div`
  flex-direction: row;
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 10px;
  display: flex;
  width: 100%;
  margin-bottom: 30px;
`
const Button = styled.div`
  flex-direction: row;
  padding: 12px;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
`
const ButtonText = styled.span`
  color: white;
  font-family: ${theme.fonts.gotham21Bold.family};
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
`;
const SpanLink = styled.span`
  color: ${theme.colors.royalBlue};
  font-weight: bold;
  cursor: pointer;
`;