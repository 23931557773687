import React, { useContext, useState } from 'react'
import { DataContext } from '../../contexts/dataContext'

import styled from 'styled-components'
import theme from '../../styles/theme.styles'

import { API } from 'aws-amplify'
import {
  createSubscriptionPeriod,
  updateTeam,
} from '../../custom_graphql_queries/teams'

import { loadStripe } from '@stripe/stripe-js'
import { cancelStripeSubscriptions } from '../../services/stripeServices'

import ContentWrapper from '../../components/_Layout/ContentWrapper'
import Layout from '../../components/_Layout/Layout'
import RightColumn from '../../components/_Layout/RightColumn'
import SettingsButton from '../../components/Settings/SettingsButtons'
import settingButtonTypes from '../../components/Settings/SettingsButtons.types'
import Popup from '../../components/_FormElements/Popup'
import Button from '../../components/_FormElements/Button'
import MediaBox from '../../components/Athletes/MediaBox'
import PremiumInfo from '../../components/Payments/PremiumInfo'

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
  }
  return stripePromise
}

function Payments({ children }) {
  const dataContext = useContext(DataContext)
  const userIsPremium =
    dataContext.userCoachData?.team?.suscription_name === 'PREMIUM_PASS' &&
    dataContext.userCoachData?.team?.suscription_name !== 'SEASON_PASS'
  // Show trial button only when user is free & had_trial doesn't exist.
  const userSeeTrialButton =
    dataContext.userCoachData?.team?.suscription_name === 'FREE_PERIOD' &&
    dataContext.userCoachData?.team?.had_trial === null

  const [stripeMessage, setStripeMessage] = useState(null)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [showStripeSubInfo, setShowStripeSubInfo] = useState(false)
  const [confirmCancelStripeSub, setConfirmCancelStripeSub] = useState(false)
  // open popup save filter
  const [openConfirmTrialsModal, setOpenConfirmTrialsModal] = useState(false)

  // const checkoutOptions = {
  //   lineItems: [
  //     {
  //       price: "price_1MbiPHGkZYsGQ6yKm4sJF9UG",
  //       quantity: 1, // For metered billing, do not pass quantity
  //     },
  //   ],
  //   mode: 'subscription',
  //   customerEmail: dataContext.userCoachData?.contact_email,
  //   successUrl: `${window.location.origin}/success/${dataContext.userCoachData?.id}`,
  //   cancelUrl: `${window.location.origin}/cancel`,
  // }

  const redirectToCheckout = async (price_id) => {
    setStripeLoading(true) // Disable button

    const stripe = await getStripe()
    const stripe2 = require('stripe')(process.env.REACT_APP_STRIPE_SK)

    const session = await stripe2.checkout.sessions.create({
      payment_method_types: ['card'],
      line_items: [
        {
          price: price_id,
          quantity: 1, // For metered billing, do not pass quantity
        },
      ],
      mode: 'subscription',
      customer_email: dataContext.userCoachData?.contact_email,
      success_url: `${window.location.origin}/success/${dataContext.userCoachData?.id}`,
      cancel_url: `${window.location.origin}/cancel`,
      allow_promotion_codes: true,
    })

    const res = await stripe.redirectToCheckout({ sessionId: session.id })
    // This method only returns somethign if it fails.
    if (res) {
      setStripeMessage(res)
    }

    setStripeLoading(false)
  }

  if (stripeMessage) {
    alert(stripeMessage)
  }

  const confirmCancelStripeSubscription = async () => {
    const stripeSubscription = await cancelStripeSubscriptions(
      dataContext.userCoachData?.team?.suscription_stripe_id
    ).then((res) => res)
    if (stripeSubscription.status === 'canceled') {
      // Do some stuff
      const { suscription_stripe_id, college, ...newTeam } =
        dataContext.userCoachData?.team
      try {
        await API.graphql({
          query: updateTeam,
          variables: {
            input: {
              ...newTeam,
              suscription_stripe_id: null,
            },
          },
        })
          .then(() => {
            dataContext.setUserCoachData({
              ...dataContext.userCoachData,
              team: newTeam,
            })
            setConfirmCancelStripeSub(false)
          })
          .catch((error) => {
            console.log('[ERROR] Cancel stripe subscription:', error)
          })
      } catch (e) {
        console.log('[ERROR] Cancel stripe subscription:', e)
      }
    }
  }

  const confirmTrialPeriod = async () => {
    setOpenConfirmTrialsModal(false)
    const start = new Date()
    const now = new Date()
    const next = now.setDate(now.getDate() + 15)
    // Required for the new season
    const nextEndDate = new Date(next).toISOString().split('T')[0]

    const { college, coaches, subscription_history, ...finalTeam } =
      dataContext.userCoachData?.team

    try {
      await API.graphql({
        query: updateTeam,
        variables: {
          input: {
            ...finalTeam,
            had_trial: true,
            suscription_name: 'SEASON_PASS',
            suscription_premium_start_date: start.toISOString().split('T')[0],
            suscription_premium_end_date: nextEndDate,
          },
        },
      })
        .then(async () => {
          await API.graphql({
            query: createSubscriptionPeriod,
            variables: {
              input: {
                teamID: finalTeam.id,
                suscription_name: 'SEASON_PASS',
                start_date: start.toISOString().split('T')[0],
                end_date: nextEndDate,
              },
            },
          })
            .then(() => {
              dataContext.setUserCoachData({
                ...dataContext.userCoachData,
                team: {
                  ...finalTeam,
                  had_trial: true,
                  suscription_name: 'SEASON_PASS',
                  suscription_premium_start_date: start
                    .toISOString()
                    .split('T')[0],
                  suscription_premium_end_date: nextEndDate,
                },
              })
            })
            .catch((error) => {
              console.log('[ERROR] Saving subscription period:', error)
            })
        })
        .catch((error) => {
          console.log('[ERROR] Updating team:', error)
        })
    } catch (error) {
      console.log('[Error] Updating team for trial period: ', error)
    }
  }

  // Subscription buttons
  const userSettingsDefaults = [
    {
      type: settingButtonTypes.PRICE_OPTION,
      heading: 'Trial period',
      line: `15 days`,
      period: 'Trial',
      price: 'FREE',
      showIfPremium: false,
      showIfFree: true,
      trial: true,
      onClick: () => setOpenConfirmTrialsModal(true),
    },
    {
      type: settingButtonTypes.PRICE_OPTION,
      heading: 'Upgrade to premium monthly',
      period: 'Monthly',
      line: 'per Month',
      price: '$29.99',
      line2: `Enjoy The Alliance Athletes Premium Plan and take full advantage of the premium filters and features.`,
      additionalLine: (
        <Ul>
          <Li>Budget Filter</Li>
          <Li>Current Status Filter</Li>
          <Li>Showcase Filter</Li>
          <Li>PSA With Videos Filter</Li>
          <Li>Competition Country Filter</Li>
          <Li>Agency Filter</Li>
          <Li>Type of Admission Filter</Li>
          <Li>Saved Searches (save your previous search)</Li>
          <Li>PSA management features</Li>
          <Li>Rate and tag PSAs</Li>
          <Li>
            Notifications (receive customized notifications and app updates)
          </Li>
          <Li>Destination college/university for the committed PSAs</Li>
          <Li>Priority customer service</Li>
          <Li>No Ads</Li>
        </Ul>
      ),
      showIfPremium: false,
      showIfFree: true,
      onClick: () =>
        redirectToCheckout(process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID),
    },
    {
      type: settingButtonTypes.PRICE_OPTION,
      heading: 'Upgrade to premium',
      period: 'Annual',
      price: '$329.99',
      discount: 'SAVE $29.89',
      line: 'per Year',
      line2: `Enjoy The Alliance Athletes Premium Plan and take full advantage of the premium filters and features.`,
      additionalLine: (
        <Ul>
          <Li>Budget Filter</Li>
          <Li>Current Status Filter</Li>
          <Li>Showcase Filter</Li>
          <Li>PSA With Videos Filter</Li>
          <Li>Competition Country Filter</Li>
          <Li>Agency Filter</Li>
          <Li>Type of Admission Filter</Li>
          <Li>Saved Searches (save your previous search)</Li>
          <Li>PSA management features</Li>
          <Li>Rate and tag PSAs</Li>
          <Li>
            Notifications (receive customized notifications and app updates)
          </Li>
          <Li>Destination college/university for the committed PSAs</Li>
          <Li>Priority customer service</Li>
          <Li>No Ads</Li>
        </Ul>
      ),
      showIfPremium: false,
      showIfFree: true,
      onClick: () => redirectToCheckout(process.env.REACT_APP_STRIPE_PRICE_ID),
    },
    {
      type: settingButtonTypes.SUBSCRIPTION,
      heading: 'Review your subscription dates',
      showIfPremium: true,
      showIfFree: true,
      onClick: () => setShowStripeSubInfo(true),
    },
    {
      type: settingButtonTypes.SUBSCRIPTION,
      heading: 'Cancel your subscription',
      showIfPremium: true,
      showIfFree: false,
      onClick: () => {
        dataContext.userCoachData?.team?.suscription_stripe_id
          ? setConfirmCancelStripeSub(true)
          : alert(
              `Your subscription has been cancelled by your team, you can enjoy the premium version until:\n\n${dataContext.userCoachData?.team?.suscription_premium_end_date}`
            )
      },
    },
  ]

  // Auxiliary info to show
  const subscriptionInfo = [
    {
      name: 'PREMIUM_PASS',
      label: 'PREMIUM PASS',
      description:
        'Test the premium features are only available for a short period of time. Get the best out of the of the platform to recruit the perfect fit for your program.',
    },
    {
      name: 'FREE_PERIOD',
      label: 'FREE PERIOD',
      description:
        'Start looking for the best fit for your program with access to the information of hundreds of recruits from top international recruiting agencies.',
    },
    {
      name: 'SEASON_PASS',
      label: 'SEASON PASS',
      description:
        'Check out the talent around the world during the entire spring/fall season.',
    },
  ]

  return (
    <Layout>
      {/* Popup with subscription information */}
      {showStripeSubInfo && (
        <Popup
          isOpen={showStripeSubInfo}
          bgcolor={theme.colors.brandDark}
          textAlign="left"
          content={
            <>
              <Body>
                <BodyTitle>Here is your subscription information:</BodyTitle>
                <BodySection>Type of subscription</BodySection>
                <BodyInformation>
                  {dataContext.userCoachData?.team?.suscription_name.replace(
                    '_',
                    ' '
                  )}
                </BodyInformation>
                <BodySection>START DATE</BodySection>
                <BodyInformation>
                  {dataContext.userCoachData?.team?.suscription_name ===
                  'FREE_PERIOD'
                    ? 'Unlimited access'
                    : dataContext.userCoachData?.team
                        ?.suscription_premium_start_date}
                </BodyInformation>
                <BodySection>END DATE</BodySection>
                <BodyInformation>
                  {dataContext.userCoachData?.team?.suscription_name ===
                  'FREE_PERIOD'
                    ? 'Unlimited access'
                    : dataContext.userCoachData?.team
                        ?.suscription_premium_end_date}
                </BodyInformation>
              </Body>
            </>
          }
          handleClose={() => setShowStripeSubInfo(false)}
        />
      )}
      <Popup
        isOpen={openConfirmTrialsModal}
        bgcolor={theme.colors.brandDark}
        textAlign="left"
        content={
          <>
            <ConfirmTrialTitle>Request trial period</ConfirmTrialTitle>
            <ConfirmTrialText>
              Are you sure you want to start enjoying all the premium features?
            </ConfirmTrialText>
            <ConfirmTrialSpan>
              Remember that this trial lasts 15 days
            </ConfirmTrialSpan>
            <ButtonGroup>
              <ButtonContainer>
                <Button
                  label="Cancel"
                  style={2}
                  onClick={() => setOpenConfirmTrialsModal(false)}
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  label="Go Trial"
                  style={1}
                  onClick={confirmTrialPeriod}
                />
              </ButtonContainer>
            </ButtonGroup>
          </>
        }
        handleClose={() => setOpenConfirmTrialsModal(false)}
      />
      {/* Popup with confirm cancel subscription */}
      {confirmCancelStripeSub && (
        <Popup
          isOpen={confirmCancelStripeSub}
          bgcolor={theme.colors.brandDark}
          textAlign="left"
          content={
            <>
              <Body>
                <BodyTitle>Confirm cancel your subscription</BodyTitle>
                <BodySection>
                  We're sorry that you're thinking about unsubscribing.
                  <br />
                  We have a feedback section where you can tell us what you'd
                  like to improve about Alliance Athletes app...
                </BodySection>
              </Body>
              <ButtonGroup>
                <ButtonContainer>
                  <Button
                    onClick={() => setConfirmCancelStripeSub(false)}
                    label="Cancel"
                  />
                </ButtonContainer>
                <ButtonContainer>
                  <Button
                    style={2}
                    onClick={() => confirmCancelStripeSubscription()}
                    label="Confirm"
                  />
                </ButtonContainer>
              </ButtonGroup>
            </>
          }
          handleClose={() => setConfirmCancelStripeSub(false)}
        />
      )}
      <RightColumn />
      <ContentWrapper>
        <FeedBackLayout>
          <Header>
            <Title>Your subscription</Title>
          </Header>
          <ListScroll>
            <HeaderSpacer />
            <div
              style={{
                alignItems: 'center',
                height: '10%',
                padding: '10px',
                justifyContent: 'center',
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '6%',
                marginBottom: '8%',
              }}
            >
              <div style={{ width: '60%', margin: '10px' }}>
                <CurrentSubscription>
                  {' '}
                  {/*Box with icon and text, row direction*/}
                  <CurrentSubscriptionIconContainer>
                    {' '}
                    {/*Left icon*/}
                    <svg
                      width="68"
                      height="68"
                      viewBox="0 0 68 68"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1405_1445)">
                        <path
                          d="M55.651 12.824H12.3489C11.9156 12.824 11.5086 13.033 11.2564 13.3855L0.251042 28.7627C-0.122958 29.2852 -0.0744817 29.9993 0.366456 30.4667L33.0228 65.0739C33.553 65.6358 34.4468 65.6358 34.977 65.0739L67.6335 30.4667C68.0744 29.9993 68.1227 29.2852 67.7489 28.7627L56.7435 13.3855C56.4913 13.033 56.0845 12.824 55.651 12.824Z"
                          fill="#79FF79"
                        />
                        <path
                          d="M67.9952 29.6626H44.2253C51.1119 28.3083 56.8204 23.6708 59.651 17.4465L67.7494 28.7626C67.9414 29.0312 68.022 29.3509 67.9952 29.6626Z"
                          fill="#29E088"
                        />
                        <path
                          d="M67.995 29.6622C67.9708 29.9349 67.8634 30.1996 67.678 30.4186C67.6632 30.4348 67.6484 30.4509 67.6336 30.4669L34.9767 65.0746C34.9741 65.0772 34.97 65.0813 34.9673 65.084C34.8182 65.2398 34.6409 65.3513 34.45 65.4186C34.3533 65.4522 34.2525 65.475 34.1504 65.4858C34.0509 65.4992 33.9489 65.4992 33.8494 65.4858C33.7473 65.475 33.6466 65.4522 33.5498 65.4186C33.3591 65.3513 33.1816 65.2399 33.0325 65.084C33.0298 65.0813 33.0257 65.0772 33.0231 65.0746L0.366327 30.4669C0.351585 30.4509 0.33671 30.4347 0.321967 30.4186C0.136561 30.1996 0.0291157 29.935 0.00494385 29.6622H67.995Z"
                          fill="#0CC166"
                        />
                        <path
                          d="M67.9952 29.6626C67.971 29.9353 67.8635 30.2 67.6781 30.419C67.6634 30.435 67.6485 30.4512 67.6338 30.4673L34.9769 65.0749L34.9675 65.0844C34.8183 65.2403 34.641 65.3517 34.4502 65.4189C34.3535 65.4525 34.2527 65.4754 34.1505 65.4861C34.0511 65.4995 33.9491 65.4995 33.8496 65.4861C33.7475 65.4754 33.6468 65.4525 33.55 65.4189C33.3592 65.3517 33.1818 65.2403 33.0327 65.0844L33.0232 65.0749L24.6292 56.1785C38.139 53.8705 48.7914 43.1872 51.0565 29.6625H67.9952V29.6626Z"
                          fill="#04A550"
                        />
                        <path
                          d="M57.7052 29.662L34.4498 65.4182C34.3532 65.4518 34.2523 65.4747 34.1502 65.4854C34.0507 65.4988 33.9487 65.4988 33.8493 65.4854C33.7471 65.4747 33.6465 65.4518 33.5496 65.4182L10.2944 29.662L18.6011 12.8243H49.3987L57.7052 29.662Z"
                          fill="#C2FFC2"
                        />
                        <path
                          d="M57.7052 29.6622L34.4498 65.4184C34.3532 65.452 34.2523 65.4749 34.1502 65.4856C34.0507 65.499 33.9487 65.499 33.8493 65.4856C33.7471 65.4749 33.6465 65.452 33.5496 65.4184L10.2944 29.6622H57.7052Z"
                          fill="#29E088"
                        />
                        <path
                          d="M57.7055 29.6627L34.4502 65.419C34.3535 65.4526 34.2527 65.4755 34.1505 65.4862C34.0511 65.4997 33.9491 65.4997 33.8496 65.4862C33.7474 65.4755 33.6468 65.4526 33.55 65.419L27.186 55.6346C39.4679 52.4923 48.9367 42.3221 51.0566 29.6627H57.7055Z"
                          fill="#0CC166"
                        />
                        <path
                          d="M42.8092 29.662L34.1506 65.4855C34.0512 65.499 33.9492 65.499 33.8497 65.4855L25.1911 29.662L28.2783 12.8243H39.722L42.8092 29.662Z"
                          fill="#79FF79"
                        />
                        <path
                          d="M42.8092 29.6622L34.1506 65.4858C34.0512 65.4992 33.9492 65.4992 33.8497 65.4858L25.1911 29.6622H42.8092Z"
                          fill="#0CC166"
                        />
                        <path
                          d="M32.3662 17.2791H15.3023C14.0721 17.2791 13.0746 16.2818 13.0746 15.0514C13.0746 13.8212 14.0719 12.8237 15.3023 12.8237H32.3662C33.5964 12.8237 34.5939 13.821 34.5939 15.0514C34.5937 16.2816 33.5964 17.2791 32.3662 17.2791Z"
                          fill="#C2FFC2"
                        />
                        <path
                          d="M28.2782 12.825L27.4612 17.2786H16.4046L18.6012 12.825H28.2782Z"
                          fill="#EEFFEE"
                        />
                        <path
                          d="M37.7027 50.7915L34.1506 65.4863C34.0511 65.4997 33.9491 65.4997 33.8496 65.4863L31.1546 54.3383C33.4828 53.4071 35.6781 52.2129 37.7027 50.7915Z"
                          fill="#04A550"
                        />
                        <path
                          d="M57.7054 29.6625H44.2305C48.3456 28.851 52.0387 26.8681 54.9486 24.0751L57.7054 29.6625Z"
                          fill="#79FF79"
                        />
                        <path
                          d="M62.3225 17.9894C62.8011 17.6742 62.8011 16.974 62.3225 16.6587C61.0872 15.8449 59.9146 14.8981 58.8258 13.8175C57.737 12.737 56.7829 11.5733 55.9629 10.3474C55.6452 9.87248 54.9397 9.87248 54.622 10.3474C53.802 11.5733 52.848 12.737 51.7591 13.8175C50.6702 14.8981 49.4977 15.8449 48.2624 16.6587C47.7838 16.974 47.7838 17.6742 48.2624 17.9894C49.4977 18.8032 50.6703 19.75 51.7591 20.8306C52.8479 21.9111 53.802 23.0748 54.622 24.3007C54.9397 24.7756 55.6452 24.7756 55.9629 24.3007C56.7829 23.0748 57.7369 21.9111 58.8258 20.8306C59.9147 19.75 61.0872 18.8032 62.3225 17.9894Z"
                          fill="#29E088"
                        />
                        <path
                          d="M62.3227 17.9889C61.0876 18.8027 59.9152 19.7492 58.8259 20.8301C57.7366 21.9112 56.7829 23.0746 55.9629 24.3004C55.6449 24.7746 54.9404 24.7746 54.6224 24.3004C53.8024 23.0746 52.8487 21.9112 51.7594 20.8301C51.6177 20.6895 51.4738 20.5508 51.3281 20.4141C51.5204 20.4302 51.7149 20.4382 51.9113 20.4382C55.649 20.4382 58.6964 17.4924 58.8219 13.8132C58.8239 13.8152 58.8239 13.8152 58.826 13.8172C59.9154 14.8983 61.0877 15.8447 62.3229 16.6585C62.8006 16.974 62.8006 17.6733 62.3227 17.9889Z"
                          fill="#0CC166"
                        />
                        <path
                          d="M63.3119 6.72489C63.5645 6.55847 63.5645 6.18899 63.3119 6.02257C62.6599 5.59306 62.041 5.09342 61.4663 4.52299C60.8916 3.95256 60.388 3.33843 59.9553 2.6915C59.7876 2.44089 59.4153 2.44089 59.2476 2.6915C58.8147 3.33857 58.3112 3.95269 57.7366 4.52299C57.1619 5.09328 56.543 5.59306 55.891 6.02257C55.6384 6.18899 55.6384 6.55847 55.891 6.72489C56.543 7.1544 57.1619 7.65404 57.7366 8.22447C58.3112 8.7949 58.8149 9.40903 59.2476 10.056C59.4153 10.3066 59.7876 10.3066 59.9553 10.056C60.3882 9.40889 60.8916 8.79477 61.4663 8.22447C62.041 7.65417 62.6599 7.15427 63.3119 6.72489Z"
                          fill="#79FF79"
                        />
                        <path
                          d="M63.3123 6.72428C62.6604 7.15379 62.0417 7.6533 61.4666 8.22386C60.8916 8.79443 60.3883 9.40855 59.9554 10.0555C59.7877 10.3058 59.4157 10.3058 59.2479 10.0555C58.8151 9.40855 58.3117 8.79443 57.7368 8.22386C57.662 8.14962 57.5861 8.07644 57.5092 8.00432C57.6106 8.01282 57.7133 8.01707 57.8169 8.01707C59.7897 8.01707 61.398 6.46224 61.4643 4.52039C61.4654 4.52145 61.4654 4.52145 61.4664 4.52251C62.0414 5.09307 62.6602 5.59258 63.3121 6.0221C63.5645 6.18878 63.5645 6.55786 63.3123 6.72428Z"
                          fill="#29E088"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1405_1445">
                          <rect width="68" height="68" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </CurrentSubscriptionIconContainer>
                  <CurrentSubscriptionInfo>
                    {' '}
                    {/*Text, column direction*/}
                    <CurrentSubscriptionTitle>
                      {
                        subscriptionInfo.find(
                          (it) =>
                            it.name ===
                            dataContext.userCoachData?.team?.suscription_name
                        )?.label
                      }
                    </CurrentSubscriptionTitle>
                    <CurrentSubscriptionDescription>
                      {
                        subscriptionInfo.find(
                          (it) =>
                            it.name ===
                            dataContext.userCoachData?.team?.suscription_name
                        )?.description
                      }
                    </CurrentSubscriptionDescription>
                  </CurrentSubscriptionInfo>
                </CurrentSubscription>
              </div>
              <div style={{ width: '40%', margin: '10px' }}>
                {/* <MediaBox featured_video={{url:'https://res.cloudinary.com/dsvz8cktr/video/upload/v1675769303/Corporate%20videos/Alliance%20Athletes%20-%20Premium%20Features.mp4'}} /> */}
                <iframe
                  src="https://player.vimeo.com/video/799093865?h=bcc783bd5c"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
            {!userIsPremium && <PremiumInfo />}
            <div style={{ textAlign: 'center' }}>
              {userSettingsDefaults.map((button, index) =>
                userIsPremium && button.showIfPremium ? (
                  <SettingsButton
                    key={index}
                    type={button.type}
                    heading={button.heading}
                    line2={button.line2}
                    onClick={button.onClick}
                    disabled={stripeLoading}
                  />
                ) : (
                  !userIsPremium &&
                  button.showIfFree &&
                  (userSeeTrialButton || !button.trial) && (
                    <SettingsButton
                      key={index}
                      type={button.type}
                      heading={button.heading}
                      line={button.line}
                      discount={button.discount}
                      line2={button.line2}
                      additionalLine={button.additionalLine}
                      onClick={button.onClick}
                      disabled={stripeLoading}
                      price={button.price}
                      period={button.period}
                      color={
                        button.trial
                          ? theme.colors.darkOrange
                          : theme.colors.transparentWhite2
                      }
                    />
                  )
                )
              )}
            </div>
          </ListScroll>
        </FeedBackLayout>
      </ContentWrapper>
    </Layout>
  )
}

Payments.propTypes = {}

Payments.defaultProps = {}

export default Payments

const FeedBackLayout = styled.div`
  position: relative;
`

const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`
const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  width: 100%;
  top: 0;
  left: 0;
  background: rgb(6, 14, 40);
  background: linear-gradient(
    180deg,
    rgba(6, 14, 40, 1) 0%,
    rgba(6, 14, 40, 1) 89%,
    rgba(6, 14, 40, 0) 100%
  );
  z-index: 2;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`
const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`

const SubscriptionInfo = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 5%;
`
const CurrentSubscription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-radius: 13px;
  color: ${theme.colors.white};
  text-decoration: none;
  background-color: ${theme.colors.transparentWhite2};
  align-items: center;
`
const CurrentSubscriptionIconContainer = styled.div`
  width: 20%;
  margin: 0 20px;
`
const CurrentSubscriptionInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`
const CurrentSubscriptionTitle = styled.h1`
  margin: 10px 0;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.3px;
`
const CurrentSubscriptionDescription = styled.p`
  margin: 10px 0;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`
const BodyTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.35px;
  margin-bottom: 20px;
`
const BodySection = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 0.35px;
`
const BodyInformation = styled.span`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.374px;
  margin-left: 20px;
`
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
`
const ButtonContainer = styled.div`
  width: 100%;
  padding: 5px 10px;
`
const Ul = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
  counter-reset: list-item;
`
const Li = styled.li`
  display: list-item;
  text-align: match-parent;
  font-family: ${theme.fonts.gotham13Regular};
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: ${theme.colors.transparentWhite6};
  text-align: left;
`
const ConfirmTrialTitle = styled.h1`
  margin: 0 25px 20px 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.35px;
`
const ConfirmTrialText = styled.h1`
  margin: 0 25px 10px 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
`
const ConfirmTrialSpan = styled.h5`
  margin: 10px 0 15px 0;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.15px;
`
const ListScroll = styled.div`
  position: absolute;
  overflow: auto;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  scrollbar-color: inherit transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2d334a;
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: padding-box;
    width: 5px;
  }
`
