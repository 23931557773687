import React from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme.styles';

function Sidebar({children,isOpen}) {
  

  return (
    <>
			<SidebarContent active={isOpen}>
				{children}
			</SidebarContent>	
    </>
  )
}

export default Sidebar;


const SidebarContent = styled.div`
	background-image: linear-gradient(
    180deg,
    rgba(6, 14, 40, 1) 0%,
    rgba(6, 14, 40, 1) 70%,
    rgba(6, 14, 50, 0.7) 80%
  );
  width: 22.5vw;
  height: 100vh;
  display: block;
  justify-content: center;
  position: fixed;
  z-index:999;
  top: 10vh;
  right: -100%;
  transition: 850ms;
	padding: 10px 1.5vw 100px 0.7vw;
	overflow: auto;
  ${(props) => props.active && `
    right: 0;
    transition: 450ms;
 `}
`
