import React from 'react'
import styled from 'styled-components'
import Lottie from 'lottie-react'
import theme from '../../styles/theme.styles'
import lottieAnimationData from './loadingAnimation.json'

function Loading(props) {
  const { visible } = props
  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimationData,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice"
    // }
  }
  return (
    <SLoading visible={visible}>
      <Lottie
        animationData={lottieAnimationData}
        loop={true}
        autoplay={true}
        style={{
          height: 100,
          width: 100,
        }}
      />
      <TextLoading>loading...</TextLoading>
      <TextLoadingVersion>v1.2.12</TextLoadingVersion>
    </SLoading>
  )
}
export default Loading

const SLoading = styled.div`
  position: fixed;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${theme.colors.midnightBlue};
  z-index: 1000;
  opacity: 0.8;
`
const TextLoading = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-top: 24px;
`
const TextLoadingVersion = styled.div`
  font-size: 12px;
  margin-top: 16px;
`
