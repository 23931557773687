import { API } from 'aws-amplify';
import React, { useContext, useState } from 'react'
import { Droppable } from "react-beautiful-dnd";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DataContext } from '../../contexts/dataContext';
import { deleteFavoritesFolder, deleteFavoritesFolderAthleteRelation, listFavoritesFolderAthleteRelations } from '../../custom_graphql_queries/coachs';
import theme from '../../styles/theme.styles';
import Button from '../_FormElements/Button';
import Popup from '../_FormElements/Popup';
import AthleteCard from './AthleteCard';

const DroppableArea = ({ droppableId, items, name, receivingItemList, setReceivedItemList, isInsideFolder }) => {
  const navigate = useNavigate();
  const dataContext = useContext(DataContext)
  const { userCoachData, setOpenPopup, openPopup } = dataContext
  const [ isOpen, setIsOpen ] = useState(false)
    
  const deleteThisFolder = (event)=>{
    if (event.target !== event.currentTarget) {
      event.stopPropagation();
      event.preventDefault();
      setIsOpen(!isOpen)
      setOpenPopup(true)
    }
    
    // removeFolder(droppableId)
  }

  const handleClose = () => {
    setIsOpen(!isOpen)
    setOpenPopup(false)
  }

  return (
    <>
      {isOpen && (
        <Popup
          isOpen = {isOpen}
          bgcolor={theme.colors.brandDark}
          content={<ConfirmDeleteFolder setIsOpen={setIsOpen} droppableId={droppableId} receivingItemList={receivingItemList} setReceivedItemList={setReceivedItemList}/>}
          handleClose={() => {}}
        />
      )}

      <Droppable droppableId={droppableId} key={droppableId}>
        {(provided, snapshot) => {
          return (
            <DroppableAreaContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
              border={snapshot.isDraggingOver}
              onClick={()=> isInsideFolder ? navigate(`/favorites`) : navigate(`/favorites/folder_${droppableId}`, { state: { name: name } })}
            >
              {items && 
                items.map((item, index) => {
                  return (
                    <AthleteCard key={item.id} item={item} index={index} />
                  )
                })
              }
              {
                !isInsideFolder && ( 
                  <DeleteButton onClick={(event)=>deleteThisFolder(event)}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 6H5H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  </DeleteButton>
                )
              }
            
            
              <svg width="39" height="31" viewBox="0 0 59 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.2209 12.791C26.2389 12.791 24.5564 11.3736 24.2453 9.4377L23.5539 5.07031C23.4502 4.425 22.8855 3.95254 22.2287 3.95254H5.3584C4.6209 3.95254 4.02168 4.54023 4.02168 5.27773V44.8723C4.02168 45.5982 4.6209 46.1859 5.3584 46.1859H53.6301C54.3676 46.1859 54.9668 45.5982 54.9668 44.8723V14.1162C54.9668 13.3902 54.3676 12.8025 53.6301 12.8025H28.2209V12.791ZM28.2209 8.83848H53.6416C56.6031 8.83848 59 11.2008 59 14.1162V44.8723C59 47.7877 56.6031 50.15 53.6416 50.15H5.3584C2.39687 50.15 0 47.7877 0 44.8723V5.27773C0 2.3623 2.39687 0 5.3584 0H22.2172C24.8561 0 27.1031 1.88984 27.518 4.47109L28.2209 8.83848Z" fill="white"/>
              </svg>
              <span style={{marginLeft: '20px'}}>
                { name }
              </span>
              <span style={{display: 'none'}}>
                {provided.placeholder}
              </span>
            </DroppableAreaContainer>
          );
        }}
        
      </Droppable>
    </>

  );
};

const ConfirmDeleteFolder = ({droppableId, setIsOpen, receivingItemList, setReceivedItemList}) => {

  const dataContext = useContext(DataContext)
  const { userCoachData, totalFavorites, setDragFavoriteItemRootFolder, dragFavoriteItemRootFolder, setOpenPopup, openPopup } = dataContext
  
  const listFavoritesInFolders = async () => {
		let favoritesInFolders = [];
  	let response = {};
		let params = { 
			query: listFavoritesFolderAthleteRelations, 
			variables: { 
				input: {
					coachID: userCoachData.id
				},
				filter: {
					favoritesFolderID: { eq : droppableId }
				}
			}
		}
		try {
			response = await API.graphql(params)
			favoritesInFolders = response.data.listFavoritesFolderAthleteRelations.items
			while(response.data.listFavoritesFolderAthleteRelations.nextToken) {
				params.variables.nextToken = response.data.listFavoritesFolderAthleteRelations.nextToken;
				response = await API.graphql(params);
				favoritesInFolders = [...favoritesInFolders, ...response.data.listFavoritesFolderAthleteRelations.items];
			}
			
			return favoritesInFolders;

		} catch (e) {
			console.log("ERROR!!!!!");
			console.log(e);
		}
	}

  const deleteFolderRelation = async (item) => {
		const { athleteID, favoritesFolderID } = item
		return await listFavoritesInFolders().then(async(response)=>{
			const relation = response.filter(item => {
				if(item.athleteID === athleteID && item.favoritesFolderID === favoritesFolderID)				
				return item
			})	
			
			try {
				const response =  await API.graphql({query: deleteFavoritesFolderAthleteRelation, variables: { input: {id:  relation[0].id }}})
				return response
			} catch (e) {
				console.log("ERROR!!!!!");
				console.log(e);
			}
		})		
	}

  const listFavoritesInSpecificFolder = async (folder_id) => {
		let favorites = [];
  	let response = {};
		let params = { 
			query: listFavoritesFolderAthleteRelations, 
			variables: { 
				limit: 500,
				input: {
					coachID: userCoachData.id
				},
				filter: {
					favoritesFolderID: { eq : folder_id }
				}
			}
		}
		try {
			response = await API.graphql(params)
			favorites = response.data.listFavoritesFolderAthleteRelations.items
			while(response.data.listFavoritesFolderAthleteRelations.nextToken) {
				params.variables.nextToken = response.data.listFavoritesFolderAthleteRelations.nextToken;
				response = await API.graphql(params);
				favorites = [...favorites, ...response.data.listFavoritesFolderAthleteRelations.items];
			}
		
			return favorites
		
		} catch (e) {
			console.log("ERROR!!!!!");
			console.log(e);
		}
	}

  const deleteFolder = async (folder_id) => {
		try {
			const response = await API.graphql({query: deleteFavoritesFolder, variables: { input: {id:  folder_id }}})
			return response
		} catch (e) {
			console.log("ERROR!!!!!");
			console.log(e);
		}
	}

  const removeFolder = (folder_id) => {

		listFavoritesInSpecificFolder(folder_id).then((res)=>{
			let auxArray = [];
			if(res.length > 0){
				return res.map((item)=>{
					deleteFolderRelation(item).then((response)=>{
						if(response){
							auxArray.push(totalFavorites.find(item=>item.athleteID === response.data.deleteFavoritesFolderAthleteRelation.athleteID));
							return deleteFolder(folder_id).then(()=>{
								setReceivedItemList(receivingItemList.filter(item=> item.id !== folder_id))
								setDragFavoriteItemRootFolder([...dragFavoriteItemRootFolder, ...auxArray].sort((a, b) => { return b.rating - a.rating; }))
							})
						}
					})
					.catch(err=>{console.log(err)})
				})
			} else {
				deleteFolder(folder_id).then(()=>{
					setReceivedItemList(receivingItemList.filter(item=> item.id !== folder_id))
				})
			}
		})
	}

  const handleCancel = () => {
    setIsOpen(false)
    setOpenPopup(false)
  }
  const handleConfirm = () => {
    removeFolder(droppableId)
    setOpenPopup(false)
  }
  return (
    <div style={{margin: '10px'}}>
      <h1 style={{lineHeight: '40px'}}>You are going to remove this folder. All athlete inside will be place in root folder</h1>
      <span>Are you sure?</span>
      <div style={{display: 'flex', flexDirection: 'row', margin: '20px 0'}}>
        <Button
          label={'Cancel'}
          onClick={handleCancel}
        />
        <div style={{margin: '0 5px'}}/>
        <Button
          label={'Confirm'}
          onClick={handleConfirm}
        />
      </div>
    </div>
  )
}

export const DroppableAreaRoot = ({ droppableId, items, ratingIsChanged, setRatingIsChanged }) => {
  const dataContext = useContext(DataContext)
  const { userCoachData, favoriteToggle } = dataContext
  return (
    <Droppable droppableId={droppableId} key={droppableId}>
      {(provided, snapshot) => {
        return (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              padding: 4,
              width: '100%',
              minHeight: 150,
              // border: (snapshot.isDraggingOver) ? "4px dashed #ccc" : 'none',
              borderRadius: "4px",
              marginBottom: 30,
              display: 'block',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            {items && 
              items.sort((a, b) => { return b.rating - a.rating }).map((item, index) => {
                return (
                  <AthleteCard 
                    key={item.id} 
                    item={item} 
                    index={index} 
                    includeRatingStars={true}
                    rating={item.rating}
                    athleteID= {item.athlete.id}
                    coachID= {userCoachData.id}
                    relationID={item.id}
                    ratingIsChanged={ratingIsChanged}
                    setRatingIsChanged={setRatingIsChanged}
                    userCoachData={userCoachData}
                    favoriteToggle={favoriteToggle}
                    hasTags={true}
                  />
                )
              })
            }
            <span style={{display: 'none'}}>
              {provided.placeholder}
            </span>
          </div>
        );
      }}
    </Droppable>
  );
};


export default DroppableArea

const DroppableAreaContainer = styled.div`
  padding: 4px;
  width: 100%;
  min-height: 120px;
  border: ${props => props.border ? '4px dashed #ccc' : '1px solid white'};
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 4px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`
const DeleteButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px solid ${theme.colors.transparentWhite3};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  &:hover {
    background-color: ${theme.colors.transparentWhite3}
  }
`