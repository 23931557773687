import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper';
import { getAge, positionHumanize } from '../../helpers';
import theme from '../../styles/theme.styles';
import RatingStars from '../Favorites/RatingStars';
import Popup from '../_FormElements/Popup';
import Button from '../_FormElements/Button';
import InputField from '../_FormElements/InputField';
import { createAthleteTag, createAthleteTagsRelation, deleteAthleteTagsRelation, listAthleteTagsRelations } from '../../custom_graphql_queries/athletes';
import { createCoachTagsRelation, listCoachTagsRelations } from '../../custom_graphql_queries/coachs';
import { API } from 'aws-amplify';
import TagForm from '../Favorites/TagForm';
import { DataContext } from '../../contexts/dataContext';


function ListItem({ 
  athlete, 
  path, 
  favoriteToggle,
  relationID,
  rating,
  athleteID,
  coachID,
  ratingIsChanged,
  setRatingIsChanged,
  includeRatingStars,
  userCoachData,
  hasTags
}) {
  const {
    name,
    isFavorited,
    isFavoritedLoading,
    featured_video,
    // agency,
    card_pic,
    profile_pic,
    position,
    second_position,
    country_of_competition,
    start_date,
    birthdate,
    gender,
    current_status,
    showcases_participation,
    destination_college,
  } = athlete;

  const [isOpen, setIsOpen] = useState(false)
  const [athleteTags, setAthleteTags] = useState([])
  const [coachTags, setCoachTags] = useState([])

  const fetchAthleteTagRelation = async(athleteID, tagID) =>{
    let athletesTagRelation = [];
    let response = {};
    let params = { 
      query: listAthleteTagsRelations, 
      variables: { 
        filter:{
          athleteID: {eq: athleteID}
        } 
      }
    }
    try {
      response = await API.graphql(params);
      athletesTagRelation = response.data.listAthleteTagsRelations.items
      while(response.data.listAthleteTagsRelations.nextToken) {
        params.variables.nextToken = response.data.listAthleteTagsRelations.nextToken;
        response = await API.graphql(params);
        athletesTagRelation = [...athletesTagRelation, ...response.data.listAthleteTagsRelations.items];
      }
      return athletesTagRelation
    } catch (e) {
      console.log("TAG ERROR!!!!!");
      console.log(e);
    }
  }

  const fetchCoachTagRelation = async() =>{
    let coachRelation = [];
    let response = {};
    let params = { 
        query: listCoachTagsRelations, 
        variables: { 
          filter: {
            coachID: {eq: userCoachData.id}
          }
        }
      }
    try {
      response = await API.graphql(params)
      coachRelation = response.data.listCoachTagsRelations.items
      while(response.data.listCoachTagsRelations.nextToken) {
        params.variables.nextToken = response.data.listCoachTagsRelations.nextToken;
        response = await API.graphql(params);
        coachRelation = [...coachRelation, ...response.data.listCoachTagsRelations.items];
      }
      
      return coachRelation
    } catch (e) {
      console.log("TAG ERROR!!!!!");
      console.log(e);
    }
  }

  const fetchData = async () => {
    const ctr =  await fetchCoachTagRelation()
    const tagIDs = ctr.map((item)=> item.tagID)
    const atr = await fetchAthleteTagRelation(athleteID)
    let arrayAthleteTag = atr.filter(item=>tagIDs.includes(item.tagID))
    setCoachTags(ctr)
    setAthleteTags(arrayAthleteTag.map(r=> ({RelationID: r.id, name: r.tag.text})))
  }

  useEffect(()=>{
    if(userCoachData)
      fetchData()
    // return () => {
    //   setAthleteTags({})
    // }
  },[])

  // const currentAthlete = useRef(null)
  const dataContext = useContext(DataContext);
  const isPremium =   (dataContext.userCoachData?.team?.suscription_name === 'PREMIUM_PASS' || (dataContext.userCoachData?.team?.suscription_name === 'SEASON_PASS' && dataContext.userCoachData?.team?.had_trial !== null))

  let availablePic = card_pic || profile_pic;
  if (availablePic) {
    let committed_layer = '';
    if (current_status === 'COMMITTED') {
      committed_layer = 'e_grayscale/l_commited-watermark_cbwmm6/c_scale,w_0.35/fl_layer_apply/';
    }
    availablePic = availablePic.replaceAll(
      'image/upload/',
      `image/upload/c_thumb,e_auto_brightness,g_face,z_0.01,h_254,w_346/${committed_layer}`,
    );
  }
  const profileImage = availablePic || (gender === 'female'
    ? '/images/avatar_girl_horizontal.svg'
    : '/images/avatar_boy_horizontal.svg');
  const semester = start_date?.split('_').join(' ');

  const navigate = useNavigate();

  const [allowFavorite, setAllowFavorite] = useState(true);

  const handleFavoriteToggle = async (athlete) => {
    setAllowFavorite(false);
    favoriteToggle(athlete).then(() => setAllowFavorite(true));
  };

  return (
    <ListItemContainer>
      <SListItem to={path}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <BlockAvatar>
            <Avatar image={profileImage} />
            {featured_video && (
              <VideoIcon>
                <svg
                  width={16}
                  height={16}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 4V2A2 2 0 0013.834.007l-12 1A2 2 0 000 3v2c.003.352.1.697.279 1-.18.302-.277.648-.279 1v7a2 2 0 002 2h12a2 2 0 002-2V7a1.988 1.988 0 00-.689-1.5A1.992 1.992 0 0016 4zm-2-2v2l-1.846.154-1.846-1.846L14 2zM6.615 4.615L4.769 2.769l3.693-.308 1.846 1.847-3.693.307zM2 3l.923-.077 1.846 1.846L2 5V3zm12 11H2V7h12v7zM6 8v5l4.75-2.5L6 8z"
                    fill="#fff"
                  />
                </svg>
              </VideoIcon>
            )}
          </BlockAvatar>
          <Texts>
            <HeaderLine>
              <strong>{name}</strong>
              {' '}
              {new Date(birthdate).getFullYear()}
              {' '}
            </HeaderLine>
            { 
              (showcases_participation && showcases_participation?.items?.length > 0) &&
                (
                  <TextLine>
                    { isPremium ? (
                      <svg width="15" height="13" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8845 0.650268C12.8911 0.435191 12.8948 0.218505 12.8948 0H3.18778C3.18778 0.21854 3.19147 0.435191 3.19805 0.650268H0C0 3.97106 2.09152 6.8112 5.0495 7.96629C5.69448 9.0201 6.47066 9.74077 7.31842 10.0047V12.4967H6.57491C5.89054 12.4967 5.33569 13.0424 5.33569 13.7156V14H10.6642V13.7156C10.6642 13.0424 10.1094 12.4967 9.42502 12.4967H8.76411V10.0047C9.62352 9.73717 10.4092 8.99996 11.0594 7.92251C13.9596 6.74077 16 3.93008 16 0.650268H12.8845ZM1.05881 1.49886H3.2409C3.37931 3.44099 3.7823 5.20319 4.37135 6.62054C2.38418 5.56375 1.05881 3.6653 1.05881 1.49886ZM10.1633 4.03873C9.84706 4.29448 9.53375 4.55342 9.21822 4.80997C9.20958 4.81745 9.20062 4.82515 9.19458 4.83494C9.28372 5.21648 9.38359 5.59596 9.47671 5.97674C9.54747 6.16762 9.38199 6.39283 9.17417 6.37787C9.10235 6.37703 9.03984 6.33828 8.98256 6.29988C8.6733 6.10771 8.36386 5.91564 8.05442 5.72368C8.02807 5.70784 8.00325 5.68798 7.97356 5.67843C7.62277 5.88627 7.27728 6.10341 6.92738 6.31296C6.83116 6.36944 6.70338 6.36867 6.60894 6.30901C6.50938 6.25071 6.44805 6.13041 6.47159 6.01692C6.57239 5.60981 6.68581 5.20484 6.78202 4.797C6.49786 4.55642 6.20922 4.32064 5.92368 4.08157C5.859 4.03006 5.78888 3.9761 5.76083 3.89577C5.69224 3.73448 5.80079 3.52416 5.98049 3.49898C6.40826 3.46341 6.83895 3.44844 7.26572 3.4099C7.41331 3.06298 7.55525 2.7134 7.7011 2.36561C7.72827 2.29626 7.75963 2.22401 7.82156 2.17749C7.9587 2.06208 8.1992 2.10073 8.27707 2.26548C8.43476 2.64961 8.58957 3.03497 8.74622 3.41959C8.74885 3.43264 8.76137 3.43697 8.7736 3.43697C9.16567 3.46338 9.55746 3.49482 9.94946 3.52227C10.062 3.52458 10.1736 3.5855 10.2244 3.68601C10.2873 3.79886 10.2663 3.95547 10.1633 4.03873ZM11.7344 6.56336C12.3107 5.15584 12.7051 3.41515 12.8417 1.49886H14.9412C14.9412 3.62669 13.6628 5.49612 11.7344 6.56336Z" fill="white"/>
                      </svg>                 
                    ) : (
                      <svg width="13" height="13" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 0C7.14567 0 4 3.14567 4 7V9H2C0.895 9 0 9.895 0 11V23C0 24.105 0.895 25 2 25H20C21.105 25 22 24.105 22 23V11C22 9.895 21.105 9 20 9H18V7C18 3.27158 15.0366 0.268565 11.3555 0.0722656C11.2424 0.0267105 11.1219 0.00221916 11 0V0ZM11 2C13.7737 2 16 4.22633 16 7V9H6V7C6 4.22633 8.22633 2 11 2Z" fill="white"/>
                      </svg>
                    )}
                    {/* {showcases_participation?.items[0]?.showcase?.name} {showcases_participation?.items[0]?.color && ` | ${showcases_participation?.items[0]?.color}`}{showcases_participation?.items[0]?.number && ` | ${showcases_participation?.items[0]?.number}`} */}
                    {isPremium 
                    ? `${showcases_participation?.items[0]?.showcase?.name}  ${showcases_participation?.items[0]?.color !== null ? ` | ${showcases_participation?.items[0]?.color}` : ''}${showcases_participation?.items[0]?.number !== null ? ` | ${showcases_participation?.items[0]?.number}` : ''}` 
                    : 'Premium content'}
                  </TextLine>
                )
            }
            <TextLine status={current_status}>
              <svg width="14" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.427395 0.640897C0.25671 0.687323 0.143375 0.784273 0.0559847 0.96042L0 1.07102V7.45191V13.8328L0.068274 13.8847C0.165223 13.9584 0.274461 13.9885 0.477918 13.998C0.741456 14.0089 0.925795 13.9748 1.04732 13.8902L1.10604 13.8506V7.44508V1.04098L1.06371 0.96042C0.977684 0.799293 0.847963 0.690054 0.686836 0.646359C0.578963 0.616319 0.524344 0.614953 0.427395 0.640897Z" fill="white" />
                <path d="M2.01 5.09509L2.0141 8.3818L2.09602 8.40638C2.32406 8.47739 2.46334 8.5006 2.69001 8.50879C3.11604 8.52245 3.48608 8.43369 3.98175 8.19883C4.46786 7.96806 4.92257 7.66493 5.847 6.95488C6.6895 6.30628 7.00902 6.07141 7.34629 5.85294C7.94984 5.46241 8.43322 5.25759 8.92206 5.18658C9.15282 5.15244 9.49693 5.16473 9.72769 5.21526L9.90384 5.25486L9.93524 5.21389C10.0718 5.04457 9.95846 4.76874 9.61845 4.44513C9.03812 3.88938 8.41137 3.55756 7.6467 3.4019C7.4132 3.35274 7.23296 3.34045 6.52018 3.32407C4.65084 3.28037 4.56891 3.27491 4.11693 3.16021C3.40962 2.98133 2.85933 2.64952 2.25852 2.04461C2.1288 1.91489 2.02092 1.80838 2.01546 1.80838C2.01136 1.80838 2.00863 3.2872 2.01 5.09509Z" fill="white" />
              </svg>
              {(current_status === 'ON_HOLD' ? 'ON HOLD' : current_status)}
            </TextLine>
            { destination_college && (
              <TextLine>
                { isPremium ? (
                  <svg width="15" height="13" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.08005 6.46765V10.0102C3.08005 10.4147 3.23938 10.8152 3.54896 11.1889C3.85853 11.5626 4.31229 11.9021 4.88431 12.1881C5.45633 12.4741 6.13542 12.701 6.8828 12.8558C7.63018 13.0106 8.43122 13.0902 9.24018 13.0902C10.0491 13.0902 10.8502 13.0106 11.5976 12.8558C12.3449 12.701 13.024 12.4741 13.5961 12.1881C14.1681 11.9021 14.6218 11.5626 14.9314 11.1889C15.241 10.8152 15.4003 10.4147 15.4003 10.0102V6.46765L9.24018 9.24016L6.16012 7.85353V10.0102L5.3901 9.24016L4.62008 10.0102V7.16097L3.08005 6.46765V6.46765Z" fill="white"/>
                    <path d="M18.4804 4.15809L9.24017 0L-2.4925e-05 4.15809L9.24017 8.31618L18.4804 4.15809Z" fill="white"/>
                  </svg>                  
                ) : (
                  <svg width="13" height="13" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C7.14567 0 4 3.14567 4 7V9H2C0.895 9 0 9.895 0 11V23C0 24.105 0.895 25 2 25H20C21.105 25 22 24.105 22 23V11C22 9.895 21.105 9 20 9H18V7C18 3.27158 15.0366 0.268565 11.3555 0.0722656C11.2424 0.0267105 11.1219 0.00221916 11 0V0ZM11 2C13.7737 2 16 4.22633 16 7V9H6V7C6 4.22633 8.22633 2 11 2Z" fill="white"/>
                  </svg>
                )}
                {isPremium ? destination_college : 'Premium content'}
              </TextLine>
            )}
            <TextLine>
              <svg
                width={14}
                height={14}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.622 9.144l-.398-3.98a.872.872 0 00-.87-.789H5.646a.872.872 0 00-.87.787l-.398 3.982a.44.44 0 00.434.481h.479l.359 3.588c.044.448.42.787.871.787h.958c.452 0 .826-.34.871-.787l.359-3.588h.478a.434.434 0 00.435-.481zM7 3.5A1.75 1.75 0 107 0a1.75 1.75 0 000 3.5z"
                  fill="#fff"
                />
              </svg>
              {positionHumanize(position)
                + (second_position && ` | ${positionHumanize(second_position)}`)}
            </TextLine>
            <TextLine>
              <svg
                width={14}
                height={14}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 12.833A5.833 5.833 0 107 1.166a5.833 5.833 0 000 11.667zM1.167 6.999h11.666"
                  stroke="#fff"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 1.166a8.925 8.925 0 012.333 5.833A8.925 8.925 0 017 12.833a8.925 8.925 0 01-2.333-5.834A8.925 8.925 0 017 1.166v0z"
                  stroke="#fff"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {country_of_competition}
            </TextLine>
            <TextLine>
              <svg
                width={14}
                height={14}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.468 1.75h-1.49a.753.753 0 00-.218-.53.742.742 0 00-1.27.53H5.51a.753.753 0 00-.217-.53.742.742 0 00-1.272.53H2.532c-.395 0-.774.158-1.053.44-.28.28-.436.662-.436 1.06v8.25c0 .398.157.78.436 1.06.28.282.658.44 1.053.44h8.936c.395 0 .774-.158 1.053-.44.28-.28.436-.662.436-1.06V3.25c0-.398-.157-.78-.436-1.06a1.484 1.484 0 00-1.053-.44zm-8.936 1.5h1.49c0 .199.078.39.218.53a.742.742 0 001.27-.53h2.98a.742.742 0 101.49 0h1.488v1.5H2.532v-1.5zm0 8.25V6.25h8.936v5.25H2.532z"
                  fill="#fff"
                />
              </svg>
              {semester || 'N/A'}
            </TextLine>
          </Texts>
        </div>
        <div style={{display: 'block', marginTop: 10}}>
          {
            athleteTags &&
            athleteTags.map((tag, index) => {
              return (
                <div style={{display: 'inline-block', margin: '5px 10px', border: '1px solid white', padding: 6, borderRadius: 5}} key={index}>
                  #{tag.name}
                </div>
              )
            })
          }
        </div>

      </div>
      </SListItem>
      {
        includeRatingStars && (
          <FavoriteRatingStars>
            <RatingStars 
              rating={ rating }
              relationID= {relationID}
              athleteID={athleteID}
              coachID={coachID}
              ratingIsChanged={ratingIsChanged}
              setRatingIsChanged={setRatingIsChanged} />
          </FavoriteRatingStars>
        )
      }
      {
        hasTags && (
          <TagsButton
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <svg width="22" height="20" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.90084 25.0664C9.8902 26.0557 11.4962 26.0557 12.4856 25.0664L24.7577 12.7943C25.1101 12.4419 25.3811 12.0217 25.5573 11.5745C26.7906 11.9201 27.99 12.1031 29.0472 12.0963C31.6629 12.0827 32.5303 10.9375 32.8149 10.2531C33.0995 9.57545 33.3028 8.1524 31.4664 6.2821C31.446 6.26177 31.4257 6.24144 31.4054 6.22112C30.2669 5.08267 28.6135 4.03233 26.7364 3.25304C26.3908 3.11073 26.0452 2.98198 25.7064 2.86V2.83968C25.6861 1.33531 24.4731 0.122327 22.9687 0.101997L15.6163 0.000350923C14.6405 -0.0132019 13.7053 0.366278 13.0209 1.05747L0.742019 13.3296C-0.24734 14.3189 -0.24734 15.925 0.742019 16.9143L8.90084 25.0664ZM18.3607 4.39148C19.1807 3.57153 20.5089 3.57153 21.3288 4.39148C21.8167 4.87938 22.0132 5.55025 21.9184 6.18046C22.7248 6.74968 23.687 7.27824 24.717 7.70515C25.083 7.85423 25.4353 7.98976 25.7742 8.10496L25.7335 5.15044C25.7945 5.17754 25.8487 5.19787 25.9097 5.22498C27.5157 5.89585 28.9591 6.80389 29.8874 7.73226C29.901 7.74581 29.9146 7.75936 29.9281 7.77292C30.809 8.67418 30.9039 9.25018 30.8294 9.42637C30.599 9.97526 28.1256 10.4632 24.3714 8.8978C23.2736 8.43701 22.2436 7.86779 21.4101 7.25113C21.383 7.28501 21.3491 7.3189 21.322 7.35278C20.5021 8.17273 19.1739 8.17273 18.354 7.35278C17.5408 6.53961 17.5408 5.21143 18.3607 4.39148Z" fill="white"/>
            </svg>
          </TagsButton>
        )
      }
      
      <FavoriteButton
        onClick={() => {
          allowFavorite && handleFavoriteToggle(athlete);
        }}
        isFavoritedLoading={isFavoritedLoading}
        isFavorited={isFavorited}
      >
        <svg
          width={20}
          height={18}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.238 3.309a4.471 4.471 0 00-6.327 0l-.862.862-.863-.862A4.474 4.474 0 002.86 9.636l7.19 7.19 7.189-7.19a4.472 4.472 0 000-6.327z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </FavoriteButton>
      {
        (athlete.agency && !includeRatingStars) && (
          <AgencyLogo>
            <img src={athlete.agency.logo} alt="Agency Logo" onClick={() => navigate(`/agencies/agency_${athlete.agencyID}`)} />
          </AgencyLogo>
        )
      }
      {(isOpen && hasTags) && (
        <Popup
          // ref={currentAthlete}
          isOpen={isOpen}
          bgcolor={theme.colors.brandDark}
          textAlign="left"
          content={<TagForm athlete={athlete} athleteTags={athleteTags} setAthleteTags={setAthleteTags} userCoachData={userCoachData} coachTags={coachTags}/>}
          handleClose={() => setIsOpen(!isOpen)}
        />
      )}
      
    </ListItemContainer>
  );
}

ListItem.propTypes = {
  athlete: PropTypes.object,
};

ListItem.defaultProps = {
  athlete: {},
};

export default ListItem;

const ListItemContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;
const FavoriteRatingStars = styled.div`
  position: absolute;
  top: 130px;
  right: 21px;
  height: 36px;
`;
const TagsButton = styled.div`
  position: absolute;
  top: 21px;
  right: 64px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${(props) => (props.isFavorited ? theme.colors.royalBlue : 'rgba(255, 255, 255, 0.1)')};
  cursor: pointer;
  & > svg {
    margin: 9px 0 0 8px;
    opacity: ${(props) => (props.isFavorited ? 1 : 0.2)};
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  ${(props) => props.isFavoritedLoading
    && `
    & > svg{
        animation: blink normal 0.5s infinite ease-in-out;
    }
    `}
`;
const FavoriteButton = styled.div`
  position: absolute;
  top: 21px;
  right: 21px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${(props) => (props.isFavorited ? theme.colors.royalBlue : 'rgba(255, 255, 255, 0.1)')};
  cursor: pointer;
  & > svg {
    margin: 9px 0 0 8px;
    opacity: ${(props) => (props.isFavorited ? 1 : 0.2)};
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  ${(props) => props.isFavoritedLoading
    && `
    & > svg{
        animation: blink normal 0.5s infinite ease-in-out;
    }
    `}
`;
const SListItem = styled(NavLink)`
  display: flex;
  padding: 12px;
  border-radius: 13px;
  color: ${theme.colors.white};
  text-decoration: none;
  background-color: ${theme.colors.transparentWhite2};
`;
const BlockAvatar = styled.div`
  position: relative;
  width: 173px;
  height: 147px;
`;
const VideoIcon = styled.span`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 13px;
  right: 13px;
  & svg {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`;
const Avatar = styled.div`
  width: 173px;
  height: 147px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image});
`;

const Texts = styled.div`
  flex: 1;
  margin-left: 14px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image});
`;

const HeaderLine = styled.div`
  font-size: 21px;
  line-height: 30px;
  font-weight: 400;
  margin: 6px 52px 2px 0;
  & strong {
    font-size: 25px;
    font-weight: 700;
    margin-right: 6px;
  }
`;
const TextLine = styled.div`
  font-size: 13px;
  line-height: 26px;
  font-weight: 400;
  ${(props) => props.status === 'COMMITTED' && ` color: ${theme.colors.red}`};
  ${(props) => props.status === 'ON_HOLD' && ` color: ${theme.colors.gold}`};
  ${(props) => props.status === 'AVAILABLE' && ` color: ${theme.colors.green}`};
  ${((props) => props.status === 'COMMITTED' || props.status === 'ON_HOLD' || props.status === 'AVAILABLE') && 'font-weight: 700;'};
  svg {
    vertical-align: middle;
    margin-right: 8px;
  }
`;
const AgencyLogo = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  background-color: ${theme.colors.white};
  border-radius: 11px;
  cursor: pointer;
  & img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
  }
`;

/*
act_date: "1900-01-01"
act_score: null
agency: {id: '2e961d7e-8eeb-4e0c-aee8-95b8384e7fb1', name: 'Soccer & Education USA', logo: 'https://res.cloudinary.com/dsvz8cktr/image/upload/…04/agency_logos/SEUSA_transparent_logo_gqy0n9.png', country: Array(1), contact_email: 'seusa@soccerandeducationusa.com', …}
agencyID: "2e961d7e-8eeb-4e0c-aee8-95b8384e7fb1"
agency_assesment: "Arnar Eide Gardarsson is a top attacking player for 2022. Arnar is a very dynamic player and can play on both wings as well as a central midfielder and right back. He is athletic, technical and strong in the air. He is very calm and collected on the ball, strong with the ball to his feet, holds up the play well and looks to play forward. He has a great work rate, decent speed and stamina. He is very dangerous in and around the box, providing goals and assists, and has great crossing and finishing abilities.\n \nArnar has played over 60 league games for his team in Iceland and recently got promoted. He was chosen the best U-19 player in his region in 2019.\n \nArnar has proven experience at the senior level in Iceland despite his young age. He is an interesting prospect for college soccer!"
agentID: "b241e1f2-abdf-4a84-9784-5cc35cfe23a1"
birthdate: "2001-10-18"
budget: 9000
card_pic: "https://res.cloudinary.com/dsvz8cktr/image/upload/v1635376319/ppvqkrlwo5somojumaou.jpg"
compliance: (4) ['NCAA_DI', 'NCAA_DII', 'NCAA_DIII', 'NAIA']
contact_email: "arnareide@gmail.com"
contact_phone: null
country_of_competition: "Iceland"
createdAt: "2021-10-18T17:00:24.029Z"
current_club: "Höttur/Huginn"
current_status: "AVAILABLE"
dominant_foot: "right"
duolingo: null
duolingo_date: "1900-01-01"
elegibility_notes: null
enquiries: {nextToken: null}
estimated_gpa: null
facebook_url: null
favorited: {nextToken: null}
featured_video: null
gemat_gre_date: "1900-01-01"
gemat_gre_score: null
gender: "male"
height: 190
high_school_graduation_date: "1900-01-01"
id: "f90f2470-84a4-44ed-84a6-0a427e921b4f"
ielts: null
ielts_date: "1900-01-01"
intended_degree: null
intended_field_of_studies: null
isFavorited: false
isVisible: true
level_of_competition: null
linkedin_url: null
name: "Arnar Eide Garðarsson"
nationality: "Iceland"
other_nice_pics: null
other_videos: null
position: "LEFT_WINGER"
profile_pic: "https://res.cloudinary.com/dsvz8cktr/image/upload/v1635473269/t4bim2if1gx8tymsucum.png"
sat_date: "1900-01-01"
sat_score: null
second_position: "RIGHT_WINGER"
showcase_participation: null
sport: "Soccer"
sports_background: null
start_date: "FALL_2022"
tags: {nextToken: null}
target_division: (3) ['NCAA_DI', 'NCAA_DII', 'NAIA']
toefl_date: "1900-01-01"
toefl_score: null
twitter_user: null
type_of_admission: "FRESHMAN"
updatedAt: "2021-10-29T02:07:58.010Z"
weight: 76 */
