export const createSearchFilter = /* GraphQL */ `
  mutation CreateSearchFilter(
    $input: CreateSearchFilterInput!
    $condition: ModelSearchFilterConditionInput
  ) {
    createSearchFilter(input: $input, condition: $condition) {
      id
      name
      filter_body
      type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          collegeID
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteSearchFilter = /* GraphQL */ `
  mutation DeleteSearchFilter(
    $input: DeleteSearchFilterInput!
    $condition: ModelSearchFilterConditionInput
  ) {
    deleteSearchFilter(input: $input, condition: $condition) {
      id
      name
      filter_body
      type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          collegeID
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;