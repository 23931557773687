import { API } from "aws-amplify";
import { useContext, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/dataContext";
import { createFavoritesFolder } from "../../custom_graphql_queries/coachs"
import Button from "../_FormElements/Button"
import InputField from "../_FormElements/InputField"

function CreateFolderNameForm({receivingItemList, setReceivedItemList, setIsOpen}) {

  const dataContext = useContext(DataContext);
  const { userCoachData } = dataContext
  const [text, setText] = useState();

  const createFolder = async () => {
		try {
			await API.graphql({ 
				query: createFavoritesFolder, 
				variables: { 
					input: {
						folder_name: text,
						coachID: userCoachData.id
					}
				}
			}).then((res)=>{
				// setReceivedItemList([...receivingItemList, {
				// 	"id": res.data.createFavoritesFolder.id,
				// 	"name": res.data.createFavoritesFolder.folder_name
				// }])
				
				setText('')
        setIsOpen(false)
			})
		} catch (e) {
			console.log("ERROR!!!!!");
			console.log(e);
      throw new Error('Error creating folder:',e);
		}
	}

  function onChangeText(text) {
    setText(text.target.value)
  }

  return (
    <CreateFolderContainer>
      <CreateFolderTitle>Add new folder</CreateFolderTitle>
      <CreateFolderText>Give a descriptive name for your folder</CreateFolderText>
      <InputField 
        placeholder={'Your desired name'}
        onChange={onChangeText}
      />
      <ButtonGroup>
        <div style={{padding: "10px 3px", width: '50%'}}>
          <Button label="Cancel" onClick={()=>setIsOpen(false)}/>
        </div>
        <div style={{padding: "10px 3px", width: '50%'}} onClick={createFolder}>
          <Button label="Create Folder"/>
        </div>
      </ButtonGroup>
    </CreateFolderContainer>
      
  )
}

export default CreateFolderNameForm

const CreateFolderContainer = styled.div`
  margin: 25px;
  display: flex;
  flex-direction: column;
  width: 25vw;
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const CreateFolderTitle = styled.h1`
  margin: 0 25px 20px 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.35px;
`;
const CreateFolderText = styled.h1`
  margin: 0 25px 10px 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
`;