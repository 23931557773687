import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

// import mapPinIcon from '../../../assets/icons/mapPinIcon.png'
// import foot from '../../../assets/icons/foot.png'
// import footActive from '../../../assets/icons/footActive.png'

function InfoBitRow({
  title, icon, type, value, budget, currency, onClick
}) {
  if (value === null || value === '') return null;
  return (
    <StyledInfoBitRow onClick={onClick}>
      <InfoTitle>{title}</InfoTitle>
      <ValueBox type={type} valueText={value}>
        {icon
          && (
          <ValueIcon>
            <svg width={12} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 16c.344-.003.682-.09.986-.253.303-.164.563-.4.756-.687 1.936-2.914 4.259-6.847 4.259-8.997 0-1.608-.633-3.15-1.758-4.288A5.968 5.968 0 0 0 6 0a5.969 5.969 0 0 0-4.243 1.776A6.096 6.096 0 0 0 0 6.064c0 2.151 2.323 6.082 4.258 9.017.198.281.459.511.762.671.303.16.639.245.98.247ZM6 1.564c1.18 0 2.313.474 3.148 1.318a4.524 4.524 0 0 1 1.304 3.18c0 1.33-1.49 4.362-4.007 8.138a.551.551 0 0 1-.445.234.522.522 0 0 1-.445-.235C3.04 10.425 1.55 7.393 1.55 6.065c0-1.194.469-2.338 1.304-3.181A4.429 4.429 0 0 1 6 1.565Z"
                fill="#fff"
              />
              <path
                d="M8.38 5.868c0-.476-.14-.941-.4-1.337a2.386 2.386 0 0 0-1.07-.886 2.357 2.357 0 0 0-2.594.522A2.43 2.43 0 0 0 3.8 6.789c.18.439.486.815.877 1.079a2.363 2.363 0 0 0 3.006-.3 2.408 2.408 0 0 0 .697-1.7Zm-3.213 0a.847.847 0 0 1 .514-.777.824.824 0 0 1 .906.183A.844.844 0 0 1 6 6.709a.834.834 0 0 1-.587-.248.853.853 0 0 1-.246-.593Z"
                fill="#fff"
              />
            </svg>
          </ValueIcon>
          )}
        {type !== 'handedness' && type !== 'budget' && <ValueText valueText={value}>{value}</ValueText>}
        {type === 'handedness'
            && (
            <StyledHandedness handedness={value}>
              <FootIcon
                active={value === 'left'}
              >
                <svg width={36} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.386 1.479c.395-.32.733-.581.98-.762 1.147-.84 1.8-.78 2.546.08.01.01.65.782 1.784 2.101a7.547 7.547 0 0 1-3.342-.961c-.737-.411-1.136-.483-1.968-.458Z"
                    fill="#fff"
                  />
                  <path
                    d="M33.805 13.89a.536.536 0 0 1-.475.385l-1.628.055-.255 1.06a.44.44 0 0 1-.408.321H29.74a.44.44 0 0 1-.407-.321l-.235-.974-1.458.045-.242 1.002a.44.44 0 0 1-.407.322h-1.3a.44.44 0 0 1-.407-.322l-.224-.93c-.439.012-.878.025-1.317.04l-.223.927a.44.44 0 0 1-.407.322h-1.3a.44.44 0 0 1-.407-.322l-.2-.828c-2.343.1-4.685.215-7.03.256l-.24.997a.44.44 0 0 1-.408.321H12.23a.44.44 0 0 1-.407-.321l-.237-.985a80.636 80.636 0 0 1-1.391-.025l-.234.973a.44.44 0 0 1-.408.321H8.253a.44.44 0 0 1-.408-.321l-.263-1.093c-.412-.028-.825-.061-1.236-.1l-.287 1.193a.44.44 0 0 1-.408.321H4.352a.44.44 0 0 1-.408-.321l-.375-1.562a28.133 28.133 0 0 1-1.611-.328 24.015 24.015 0 0 1-1.59-.433.556.556 0 0 1-.366-.5L0 11.439c0-.304.127-.688.272-.952.334-.605 1.026-.9 1.643-1.128.934-.343 1.91-.571 2.875-.804.412-.1.822-.203 1.23-.315.614-.169 1.225-.344 1.837-.517a47.099 47.099 0 0 0 3.64-1.167c-.12-.325.045-.769.497-.769.279 0 .448.169.509.374.394-.163.785-.335 1.172-.518-.186-.327-.033-.852.459-.852.282 0 .452.173.51.381.416-.206.835-.422 1.245-.654-.24-.319-.1-.908.421-.908.264 0 .43.152.5.343a9.2 9.2 0 0 0 1.042-.806c.227-.205.442-.427.699-.597.75-.496 1.79-.278 2.527.138 1.124.634 3.659 1.405 5.816.75.906-.275 1.778-.755 2.439-1.44.336-.35.592-.747.823-1.17.202-.37.43-.763.89-.82.91-.115 1.744 1.164 2.242 1.763 1.062 1.278 1.745 2.874 1.867 4.536.115 1.58-.304 3.226-.656 4.758-.217.945-.464 1.883-.694 2.824Z"
                    fill="#fff"
                  />
                </svg>
              </FootIcon>
              <ValueText isBoxed>{value}</ValueText>
              <FootIcon
                active={value === 'right'}
              >
                <svg width={36} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.789 1.479c-.396-.32-.734-.581-.98-.762-1.148-.84-1.8-.78-2.547.08-.009.01-.65.782-1.784 2.101a7.548 7.548 0 0 0 3.343-.961c.737-.411 1.135-.483 1.968-.458Z"
                    fill="#fff"
                  />
                  <path
                    d="M1.37 13.89c.05.205.263.377.475.385l1.628.055.255 1.06a.44.44 0 0 0 .407.321h1.3a.44.44 0 0 0 .407-.321l.234-.974 1.459.045.241 1.002a.44.44 0 0 0 .408.322h1.299a.44.44 0 0 0 .407-.322l.224-.93c.44.012.878.025 1.317.04l.223.927a.44.44 0 0 0 .408.322h1.299a.44.44 0 0 0 .407-.322l.2-.828c2.343.1 4.686.215 7.03.256l.24.997a.44.44 0 0 0 .408.321h1.299a.44.44 0 0 0 .408-.321l.237-.985c.463-.005.927-.013 1.39-.025l.235.973a.44.44 0 0 0 .408.321h1.298a.44.44 0 0 0 .408-.321l.263-1.093c.413-.028.825-.061 1.237-.1l.287 1.193a.44.44 0 0 0 .408.321h1.298a.44.44 0 0 0 .408-.321l.376-1.562c.54-.094 1.077-.202 1.61-.328a24.03 24.03 0 0 0 1.59-.433.556.556 0 0 0 .367-.5l.001-1.626c0-.304-.127-.688-.272-.952-.333-.605-1.026-.9-1.643-1.128-.934-.343-1.91-.571-2.875-.804-.412-.1-.822-.203-1.23-.315-.613-.169-1.225-.344-1.837-.517a47.094 47.094 0 0 1-3.64-1.167c.12-.325-.045-.769-.497-.769a.506.506 0 0 0-.509.374 26.065 26.065 0 0 1-1.172-.518c.186-.327.034-.852-.458-.852a.506.506 0 0 0-.512.381 25.912 25.912 0 0 1-1.243-.654c.24-.319.099-.908-.422-.908a.504.504 0 0 0-.499.343 9.2 9.2 0 0 1-1.043-.806c-.227-.205-.441-.427-.698-.597-.75-.496-1.79-.278-2.527.138-1.125.634-3.66 1.405-5.816.75-.907-.275-1.779-.755-2.44-1.44-.336-.35-.592-.747-.823-1.17-.201-.37-.43-.763-.89-.82-.91-.115-1.744 1.164-2.242 1.763C.824 3.05.141 4.645.02 6.307c-.116 1.58.303 3.226.656 4.758.217.945.463 1.883.693 2.824Z"
                    fill="#fff"
                  />
                </svg>
              </FootIcon>
            </StyledHandedness>
            )}
        {type === 'budget'
          && (
          <>
            <ValueText isSmall valueText={value}>{value}</ValueText>
            <Budget valueText={value}>
              <Currency>
                {' '}
                {currency.toLocaleString('en-US')}
&nbsp;
              </Currency>
              {budget != null ? budget : 'N/A'}
            </Budget>
          </>
          )}
      </ValueBox>
    </StyledInfoBitRow>
  );
}

export default InfoBitRow;

export const StyledInfoBitRow = styled.div`
  background-color: ${theme.colors.transparentWhite2};
  border-radius: 6px;
  padding: 15px 15px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
`;
export const InfoTitle = styled.div`
  /* height: 40px; */
  /* width: 79px; */
  display:flex;
  justify-content: center;
  flex-direction:column;
  font-family: ${theme.fonts.infoBitTitle.family};
  font-size: 13px;
  font-weight: 600;
  line-height: ${theme.fonts.infoBitTitle.lineHeight};
  color: ${theme.colors.white};
  display: flex;
  align-items: left;
  flex-grow: 0;
  width:40%;
  padding-right: 15px;

`;
export const ValueBox = styled.div`
  background-color: ${theme.colors.transparentWhite2};
  padding: 12px ;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;
  flex:1;
  ${(props) => props.valueText === 'Standby'
    && `background-color: ${props.theme.colors.transparentYellow};`
};
  ${(props) => props.type === 'budget' && 'flex-direction: column'}
`;
export const ValueIcon = styled.div`
  margin-right: 10px;
`;
export const ValueText = styled.div`
 text-align: center;
  font-family: ${theme.fonts.valueBoxText.family};
  font-size: ${theme.fonts.valueBoxText.size};
  font-weight: ${theme.fonts.valueBoxText.weight};
  line-height: ${theme.fonts.valueBoxText.lineHeight};
  color: ${theme.colors.white};
  ${(props) => props.valueText === 'Standby'
    && `color: ${props.theme.colors.gold};`
};
 ${(props) => props.isBoxed === true
    && ` 
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;`
}

${(props) => props.isSmall === true
    && ` 
    font-size:10px
    `
}
`;

export const Budget = styled.div`
 text-align: center;
  font-family: ${theme.fonts.valueBoxText.family};
  font-size: 24px;
  font-weight: bold;
  line-height: ${theme.fonts.valueBoxText.lineHeight};
  color: ${theme.colors.white};
  ${(props) => props.valueText === 'Standby'
    && `color: ${props.theme.colors.gold};`
};
 ${(props) => props.isBoxed === true
    && ` 
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;`
}
`;

export const Currency = styled.span`
 text-align: center;
  font-family: ${theme.fonts.valueBoxText.family};
  font-size: 13px;
  font-weight: bold;
  line-height: ${theme.fonts.valueBoxText.lineHeight};
  color: ${theme.colors.white};
  ${(props) => props.valueText === 'Standby'
    && `color: ${props.theme.colors.gold};`
};
 ${(props) => props.isBoxed === true
    && ` 
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;`
}
`;

export const StyledHandedness = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content:space-between;
flex:1
`;

export const FootIcon = styled.div`
    ${(props) => !props.active && 'opacity: 0.4;'}
  
`;
