export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        had_trial
        team_logo
        conference
        division
        college_category
        url_domain
        collegeID
        subscription_history {
          items {
            id
            start_date
            end_date
            suscription_name
            teamID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`
export const listTeamsWithNoSubscription = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        had_trial
        team_logo
        conference
        division
        college_category
        url_domain
        collegeID
      }
      nextToken
    }
  }
`
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_premium_start_date
      suscription_premium_end_date
      subscription_initial_date
      subscriberID
      team_logo
      conference
      division
      collegeID
      college_category
      url_domain
      had_trial
    }
  }
`
export const getSubscriptionPeriod = /* GraphQL */ `
  query GetSubscriptionPeriod($id: ID!) {
    getSubscriptionPeriod(id: $id) {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
        }
        coaches {
          nextToken
        }
        subscription_history {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const listSubscriptionPeriods = /* GraphQL */ `
  query ListSubscriptionPeriods(
    $filter: ModelSubscriptionPeriodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionPeriods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        start_date
        end_date
        suscription_name
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const createSubscriptionPeriod = /* GraphQL */ `
  mutation CreateSubscriptionPeriod(
    $input: CreateSubscriptionPeriodInput!
    $condition: ModelSubscriptionPeriodConditionInput
  ) {
    createSubscriptionPeriod(input: $input, condition: $condition) {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
    }
  }
`
export const updateSubscriptionPeriod = /* GraphQL */ `
  mutation UpdateSubscriptionPeriod(
    $input: UpdateSubscriptionPeriodInput!
    $condition: ModelSubscriptionPeriodConditionInput
  ) {
    updateSubscriptionPeriod(input: $input, condition: $condition) {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
        }
        coaches {
          nextToken
        }
        subscription_history {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const deleteSubscriptionPeriod = /* GraphQL */ `
  mutation DeleteSubscriptionPeriod(
    $input: DeleteSubscriptionPeriodInput!
    $condition: ModelSubscriptionPeriodConditionInput
  ) {
    deleteSubscriptionPeriod(input: $input, condition: $condition) {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
        }
        coaches {
          nextToken
        }
        subscription_history {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`
