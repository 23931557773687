import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme.styles';

function GenderButtons(allProps) {
  const {
    value = false,
    children = null,
    fieldName,
    formikForm,
    onChangeValueFormik,
    options,
    gender,
    setGender,
    data,
  } = allProps;

  const getIndex = (options, value) => {
    if (data.gender && value === null) {
      value = data.gender;
      setGender(data.gender);
    }
    return options.findIndex((item) => item.type === value);
  };
  useEffect(() => {
    onChangeValueFormik({ form: formikForm, fieldName, value: gender });
  }, [gender]);

  const handlePress = (index, type) => {
    setGender(type);
  };
  return (
    <GenderBox>
      {
                options.map((item, index) => (
                  <ClickableArea key={index} onClick={() => { handlePress(index, item.type); }}>
                    <GenderButton selectedBgcolor={getIndex(options, value) === index ? item.type : null}>
                      { item.icon }
                      <GenderTextBox>
                        <GenderText selectedTextColor={getIndex(options, value) === index ? item.type : null}>{item.type}</GenderText>
                      </GenderTextBox>
                    </GenderButton>
                  </ClickableArea>
                ))
            }
    </GenderBox>
  );
}

export default GenderButtons;

const ClickableArea = styled.div`
    cursor: pointer;
`;
const GenderBox = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	margin: 5% 0;
`;
const GenderButton = styled.div`
    position: relative;
    border-radius: 20px;
	align-items:center;
	justify-content:center;
	display: flex;
	margin: 25px;
	min-width:96px;
	padding:10px;
	width: 125px;
	height: 125px;
	flex-direction: column;
	background-color: ${theme.colors.transparentWhite2};
	${(props) => props.selectedBgcolor === 'male' && `background-color:${theme.colors.purple}`};
	${(props) => props.selectedBgcolor === 'female' && `background-color:${theme.colors.alertPink}`};
`;
const GenderTextBox = styled.div`
	position: absolute;
	bottom: 5px;
`;
const GenderText = styled.p`
	font-family: GothamBook;
	font-size: 12px;
	line-height: 25px;
	color: ${theme.colors.white};
	text-align: center;
	text-transform: capitalize;
`;
