// Context
import React, { createContext, useState, useEffect } from 'react'

import { API, Auth, Hub } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import {
  shuffle,
  filter,
  each,
  find,
  uniqBy,
  sortBy,
  toLower,
  size,
  indexOf,
} from 'lodash'

// Grapql Queries
import Fuse from 'fuse.js'
import { listAgencys, getAgency } from '../custom_graphql_queries/agencies'
import { listAgents } from '../custom_graphql_queries/agents'
import {
  listAthletes,
  createAthleteEnquiry,
} from '../custom_graphql_queries/athletes'
import {
  getCoach,
  createCoachAthleteFavorites,
  deleteCoachAthleteFavorites,
  createCoachMessage,
  listFavoritesFolderAthleteRelations,
  listCoachAthleteFavoritess,
  listCoachNotificationRelations,
  listFavoritesFolders,
} from '../custom_graphql_queries/coachs'
import { createFeedback } from '../custom_graphql_queries/feedback'

import { positionHumanize, positionAcronym } from '../helpers'
import selectorsContentRaw from './selectorsContent'
import {
  listShowcaseAthleteRelations,
  listShowcases,
} from '../custom_graphql_queries/showcases'
import {
  checkExpiredAndRefreshToken,
  updateSDKToken,
} from '../services/authService'

// import {
// 	AuthStateContext
// } from "./authContext"
// import { signOut } from "../services/authService";
// import { removeUserStoredData } from '../store/authStore'

export const DataContext = createContext()

function DataContextProvider({ children }) {
  const [user, setUser] = useState(null)
  const [userCoachData, setUserCoachData] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [agencies, setAgencies] = useState([])
  const [numberOfAgencies, setNumberOfAgencies] = useState(-1) // Need for checking loaded. We can remove if upgrade api
  const [agents, setAgents] = useState([])
  const [athletes, setAthletes] = useState([])
  const [notifications, setNotifications] = useState([])
  const [isDataProcessed, setIsDataProcessed] = useState(false)
  const [searchIdx, setSearchIdx] = useState(null)
  const [openSideBar, setOpenSideBar] = useState(false)
  const [promotionalModalVisible, setPromotionalModalVisible] = useState(true) // To show promotional add
  const [showcases, setShowcases] = useState([])
  const [showcasesAthleteRelation, setShowcasesAthleteRelation] = useState([])
  const [mySavedSearchFilters, setMySavedSearchFilters] = useState([]) // Array with coach's saved filters
  const [openPromotionPremiumPass, setOpenPromotionPremiumPass] =
    useState(false) // Array with coach's saved filters

  const [filteredAthletes, setFilteredAthletes] = useState([]) // Athletes after filtering and search
  const [filters, setFilters] = useState({
    searchString: '',
    filters: {},
  }) // This contains internal search filters (and search).
  const [selectorsContent, setSelectorContent] = useState({})
  const [lastSavedToApiSearchString, setLastSavedToApiSearchString] =
    useState('')
  const [totalFavorites, setTotalFavorites] = useState([])
  const [athletesInFolder, setAthletesInFolder] = useState([])
  const [dragFavoriteItemRootFolder, setDragFavoriteItemRootFolder] = useState(
    []
  )
  const [ratingIsChanged, setRatingIsChanged] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)
  const [receivingItemList, setReceivedItemList] = useState([])
  // State to process all the data again when the athletes are loaded.
  const [endFetchAthletes, setEndFetchAthletes] = useState(false)
  const [athleteIsLoaded, setAthleteIsLoaded] = useState(false)

  const updateUser = (newUser) => {
    setUser(newUser)
  }

  const updateLoading = (newLoading) => {
    setLoading(newLoading)
  }

  const navigate = useNavigate()

  //     _____ _               _
  //    / ____| |             | |
  //   | |    | |__   ___  ___| | __  _   _ ___  ___ _ __
  //   | |    | '_ \ / _ \/ __| |/ / | | | / __|/ _ \ '__|
  //   | |____| | | |  __/ (__|   <  | |_| \__ \  __/ |
  //    \_____|_| |_|\___|\___|_|\_\  \__,_|___/\___|_|
  //

  // This effect executes once, when user enter to the app.
  useEffect(() => {
    // First we check if user is logged in.
    authCheckUser()
    // then we listen auth changes thanks to AWS Hub tool.
    setAuthListener()
  }, [])

  // is user is loaded, load data
  useEffect(() => {
    if (
      user !== null &&
      user !== 'notLoggedIn' &&
      user.challengeName !== 'newPasswordRequired'
    ) {
      loadAllData()
    }
  }, [user])

  const setAuthListener = async () => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        // case 'signIn':
        //   //If user change a loged in, usualy he is in /login. We update user in dataContext and goes to /app
        //   updateUser(data.payload.data)
        //   navigate('/app/')
        //   break
        case 'signOut':
          // If user change a loged out, we update user in dataContext. After this change, Router automaticaly moves user to /login
          updateUser('notLoggedIn')
          break

        default:
          break
      }
    })
  }

  //    _                     _       _       _
  //   | |                   | |     | |     | |
  //   | |     ___   __ _  __| |   __| | __ _| |_ __ _
  //   | |    / _ \ / _` |/ _` |  / _` |/ _` | __/ _` |
  //   | |___| (_) | (_| | (_| | | (_| | (_| | || (_| |
  //   |______\___/ \__,_|\__,_|  \__,_|\__,_|\__\__,_|
  //

  // When user is logged in, load all data, an then process the data to link connect information between
  const loadAllData = async () => {
    updateLoading(true)
    fetchUserCoachData().then((res) => {
      fetchCoachFavoriteAthletes(res.data.getCoach)
      listFavoritesInFolders(res.data.getCoach)
      listFolders(res.data.getCoach)
    })
    fetchAgencies()
    fetchAgents()
    fetchAthletes()
    fetchNotifications()
    fetchShowcases()
  }

  // Check if all data is loaded before process it
  useEffect(() => {
    checkAllLoaded()
  }, [agencies, agents, athletes, userCoachData])

  // Need to process all the data again when the athletes are loaded.
  useEffect(() => {
    processAllData()
  }, [endFetchAthletes])

  const loadRestOfAthletes = async ({ token }) => {
    let restOfAthletes = []
    let response = {}
    const params = {
      query: listAthletes,
      variables: {
        limit: 300,
        filter: {
          sport: {
            eq: 'Soccer',
          },
          isVisible: {
            eq: true,
          },
        },
        nextToken: token,
      },
    }
    response = await API.graphql(params) // He make the first request to API
    restOfAthletes = response.data.listAthletes.items
    while (response.data.listAthletes.nextToken) {
      // Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listAthletes.nextToken
      response = await API.graphql(params)
      restOfAthletes = [...restOfAthletes, ...response.data.listAthletes.items]
    }
    setAthletes(shuffle([...athletes, ...restOfAthletes]))
    setFilteredAthletes(shuffle([...athletes, ...restOfAthletes]))
    setEndFetchAthletes(true) // Need to process all the data again when the athletes are loaded.
  }

  const checkAllLoaded = () => {
    const agenciesLoaded =
      agencies.length === numberOfAgencies && numberOfAgencies > 0
    const agentsLoaded = agents.length > 0
    const athletesLoaded = athletes.length > 0
    const userCoachDataLoaded = userCoachData !== null

    if (
      agenciesLoaded &&
      agentsLoaded &&
      athletesLoaded &&
      userCoachDataLoaded
    ) {
      // onsole.log("processing")
      if (!isDataProcessed) {
        setIsDataProcessed(true)
        processAllData()
      } else {
        updateLoading(false)
      }
    }
  }

  // In these queries we get all the data, not only for listing

  // Coach
  // User Coach Data. Cognito user is linked to coach by custon field profileId
  const fetchUserCoachData = async () => {
    // console.log("fetchUserCoachData")
    try {
      const coach = await API.graphql({
        query: getCoach,
        variables: { id: user.attributes['custom:profile_id'] },
      })
      if (coach.data.getCoach) {
        setUserCoachData(coach.data.getCoach)
        // Get saved filters by the coach
        setMySavedSearchFilters(coach.data.getCoach.my_filters?.items)
        return coach
      }
    } catch (e) {
      // onsole.log("fetchCoachSimple ERRORES")
      // onsole.log(e);
      throw ('fetchUserCoachData ERROR!!!!!', e)
    }
  }

  // Agencies
  const fetchAgencies = async () => {
    let agencies_res = []
    let response = {}
    const params = {
      query: listAgencys,
      variables: {
        limit: 500,
        filter: {
          or: [{ sport: { eq: 'Soccer' } }, { sport: { eq: 'Both' } }],
        },
      },
    }
    try {
      response = await API.graphql(params) // He make the first request to API
      agencies_res = response.data.listAgencys.items
      while (response.data.listAgencys.nextToken) {
        // Then repeat the same request with the nexttoken until it's nul'
        params.variables.nextToken = response.data.listAgencys.nextToken
        response = await API.graphql(params)
        agencies_res = [...agencies_res, ...response.data.listAgencys.items]
      }

      setNumberOfAgencies(agencies_res.length) // to check loading
      setAgencies(agencies_res)
    } catch (e) {
      throw ('fetchagencies ERROR!!!!!', e)
    }
  }

  // Agents
  const fetchAgents = async () => {
    try {
      const tempAgents = await API.graphql({
        query: listAgents,
        variables: { limit: 50000 },
      })
      if (tempAgents.data.listAgents) {
        setAgents(tempAgents.data.listAgents.items)
      }
    } catch (e) {
      throw ('fetchAgents ERROR!!!!!', e)
    }
  }

  // Athletes
  const fetchAthletes = async () => {
    let athletes = []
    let response = {}
    const params = {
      query: listAthletes,
      variables: {
        limit: 100,
        filter: {
          sport: {
            eq: 'Soccer',
          },
          isVisible: {
            eq: true,
          },
        },
      },
    }
    response = await API.graphql(params) // He make the first request to API
    athletes = response.data.listAthletes.items
    if (response.data.listAthletes.nextToken) {
      loadRestOfAthletes(response.data.listAthletes.nextToken)
    }

    setAthletes(shuffle(athletes)) // Filtro temporal para quitar los no visibles. Esto tiene que estar en el servidor
  }

  //    _____                               _____        _
  //   |  __ \                             |  __ \      | |
  //   | |__) | __ ___   ___ ___  ___ ___  | |  | | __ _| |_ __ _
  //   |  ___/ '__/ _ \ / __/ _ \/ __/ __| | |  | |/ _` | __/ _` |
  //   | |   | | | (_) | (_|  __/\__ \__ \ | |__| | (_| | || (_| |
  //   |_|   |_|  \___/ \___\___||___/___/ |_____/ \__,_|\__\__,_|
  //

  const processAllData = () => {
    // Number of athletes in agencies
    const tempAgencies = [...agencies]
    each(tempAgencies, (agency) => {
      agency.athleteCount = filter(athletes, { agencyID: agency.id }).length
    })
    // Add agent data into agency field
    each(tempAgencies, (agency, idxAgency) => {
      const tempAgents = []
      each(agency.agents.items, (agent, idxAgent) => {
        const agentInfo = find(agents, { id: agent.id })
        tempAgencies[idxAgency].agents.items[idxAgent] = agentInfo
      })
    })
    setAgencies(tempAgencies)

    const tempAthletes = [...athletes]
    // Mark isFavorited in athlete if is favorited of logged user
    each(tempAthletes, (athlete) => {
      athlete.isFavorited = !!find(userCoachData.favorites.items, {
        athleteID: athlete.id,
      })
    })

    // Add extra agency info (athleteCount) and Agent info to athlete
    // each(tempAthletes, (athlete) => {
    //   const athleteAgent = find(agents, { id: athlete.agentID });
    //   athlete.agent = athleteAgent;
    //   const athleteAgency = find(agencies, { id: athlete.agency.id });
    //   athlete.agency = athleteAgency;
    // });
    // DELETE THIS
    // each(tempAthletes, (athlete) => {
    //   if(athlete.budget!==null){
    //     console.log(athlete.budget)
    //     console.log(athlete.id)
    //   }
    // })

    setAthletes(tempAthletes)
    setFilteredAthletes(tempAthletes) // When load, there is no filters

    // Search data for athlete search
    generateSearchIndex()

    // Complete data for form selector Pickers form API
    completeSelectorContent()
    updateLoading(false)
  }

  // Generate search index of athletes (part of Process Data)
  const generateSearchIndex = () => {
    const idx = []
    athletes.forEach((item) => {
      const athleteForSearch = {
        id: item.id,
        text1: toLower(
          `${item.name} ${item.position} ${positionAcronym(
            item.position
          )} ${positionHumanize(item.position)} ${item.contact_email} ${
            item.gender
          } ${item.gender === 'male' ? 'man boy ' : ''}${
            item.gender === 'female' ? 'woman girl ' : ''
          }${item.contact_phone} ${item.nationality} ${
            item.country_of_competition
          } ${item.current_club}`
        ),
        text2: toLower(item.elegibility_notes),
      }
      idx.push(athleteForSearch)
    })
    const fuseOptions = {
      isCaseSensitive: false,
      threshold: 0.2,
      ignoreLocation: true,
      keys: ['text1', 'text2'],
    }
    setSearchIdx(new Fuse(idx, fuseOptions))
  }

  const completeSelectorContent = () => {
    // Agencies
    selectorsContentRaw.agencyID.valueSets = agencies.map((agency) => [
      agency.id,
      agency.name,
    ])
    // Showcases
    selectorsContentRaw.showcase_participation.valueSets = showcases.map(
      (agency) => [agency.id, agency.name]
    )

    // Countries
    const countries = []
    each(athletes, (athlete) => {
      if (athlete.country_of_competition !== null) {
        countries.push({ name: athlete.country_of_competition })
      }
    })
    const countriesUniq = uniqBy(countries, 'name')
    const countriesSorted = sortBy(countriesUniq, 'name')
    selectorsContentRaw.country_of_competition.valueSets = countriesSorted.map(
      (country) => [country.name, country.name]
    )

    setSelectorContent(selectorsContentRaw)
  }

  //    ______ _ _ _
  //   |  ____(_) | |
  //   | |__   _| | |_ ___ _ __ ___
  //   |  __| | | | __/ _ \ '__/ __|
  //   | |    | | | ||  __/ |  \__ \
  //   |_|    |_|_|\__\___|_|  |___/
  //

  // First we need to detect when the filters has changed. Externally can change by the function updateFilters(...)
  useEffect(() => {
    athleteSearchAndFilter()
  }, [filters])

  const athleteSearchAndFilter = () => {
    let tempAthletes = [...athletes]
    if (searchIdx) {
      if (filters.searchString !== '') {
        const tempSearcherdAthletes = [...tempAthletes]
        const itemsResult = []
        const indexesResult = searchIdx.search(filters.searchString)
        each(indexesResult, (index) => {
          const item = find(tempSearcherdAthletes, { id: index.item.id })
          if (item) {
            itemsResult.push(item)
          }
        })
        tempAthletes = [...itemsResult]
      }
    }
    if (filters.filters) {
      // console.log(filters.filters)
      const tempFilteredAthletes = filter(tempAthletes, (item) => {
        let includedFilters = size(filters.filters)
        each(filters.filters, (filter, index) => {
          switch (index) {
            case 'start_date':
            case 'gender':
            case 'start_date':
            case 'country_of_competition':
            case 'position':
            case 'dominant_foot':
            case 'agencyID':
            case 'type_of_admission':
            case 'current_status':
              if (filter.eq === item[index]) {
                includedFilters--
              }
              break
            case 'target_division':
              if (indexOf(item[index], filter.eq) > -1) {
                includedFilters--
              }
              break
            case 'budget':
            case 'sat_score':
            case 'toefl_score':
            case 'estimated_gpa':
              if (parseFloat(filter.ge) <= item[index]) {
                includedFilters--
              }
              break
            case 'psawithvideos':
              if (
                filter.eq === 'Highlight and Other Videos' &&
                Boolean(item.featured_video) &&
                item.other_videos
              ) {
                includedFilters--
              }
              if (
                filter.eq === 'Highlight Video' &&
                Boolean(item.featured_video) &&
                !item.other_videos
              ) {
                includedFilters--
              }
              if (
                filter.eq === 'Other Videos' &&
                item.other_videos &&
                Boolean(!item.featured_video)
              ) {
                includedFilters--
              }
              break
            case 'showcase_participation':
              if (
                showcasesAthleteRelation.find(
                  (showcase) =>
                    showcase.athleteID === item.id &&
                    showcase.showcaseID === filter.eq
                )
              ) {
                includedFilters--
              }
              break
            default:
              break
          }
          // onsole.log(filter, index)
        })

        return includedFilters === 0
      })
      tempAthletes = [...tempFilteredAthletes]
    }

    setFilteredAthletes(tempAthletes)
  }

  //    ______                    _ _
  //   |  ____|                  (_) |
  //   | |__ __ ___   _____  _ __ _| |_ ___  ___
  //   |  __/ _` \ \ / / _ \| '__| | __/ _ \/ __|
  //   | | | (_| |\ V / (_) | |  | | ||  __/\__ \
  //   |_|  \__,_| \_/ \___/|_|  |_|\__\___||___/
  //

  // Agencies
  const favoriteToggle = async (athlete) => {
    const tempAthletes = [...athletes]
    const athleteIdx = tempAthletes.findIndex((obj) => obj.id === athlete.id)
    tempAthletes[athleteIdx].isFavoritedLoading = true

    setAthletes(tempAthletes)
    let query
    if (!athlete.isFavorited) {
      query = {
        query: createCoachAthleteFavorites,
        variables: {
          input: {
            athleteID: athlete.id,
            coachID: userCoachData.id,
          },
        },
      }
      try {
        await API.graphql(query).then((r) => {
          fetchUserCoachData()
          tempAthletes[athleteIdx].isFavorited = !athlete.isFavorited
          tempAthletes[athleteIdx].isFavoritedLoading = false
          let { agency, ...athleteNoAgency } = athlete
          setAthletes(tempAthletes)
          //setTotalFavorites([...totalFavorites, {...r, athlete: athleteNoAgency}])
        })
      } catch (e) {
        console.log('[ERROR] favoriteToggle - Add action', e)
      }
    } else {
      const favorite = find(userCoachData.favorites.items, {
        athleteID: athlete.id,
      })
      // if favorite is not found, we do not update the state and return
      if (!favorite) {
        tempAthletes[athleteIdx].isFavoritedLoading = false
        setAthletes(tempAthletes)
        return
      }
      query = {
        query: deleteCoachAthleteFavorites,
        variables: {
          input: {
            id: favorite.id,
          },
        },
      }
      try {
        await API.graphql(query).then((r) => {
          fetchUserCoachData()
          tempAthletes[athleteIdx].isFavorited = !athlete.isFavorited
          tempAthletes[athleteIdx].isFavoritedLoading = false
          setAthletes(tempAthletes)
          // setTotalFavorites(totalFavorites.filter(r=> r.athlete.id !== athlete.id))
        })
      } catch (e) {
        console.log('[ERROR] favoriteToggle - Delete action', e)
      }
    }
  }

  const fetchCoachFavoriteAthletes = async (user) => {
    try {
      let caf = []
      let response = {}
      let params = {
        query: listCoachAthleteFavoritess,
        variables: {
          limit: 500,
          filter: {
            coachID: { eq: user.id },
          },
        },
      }
      response = await API.graphql(params)
      caf = response.data.listCoachAthleteFavoritess.items
      while (response.data.listCoachAthleteFavoritess.nextToken) {
        params.variables.nextToken =
          response.data.listCoachAthleteFavoritess.nextToken
        response = await API.graphql(params)
        caf = [...caf, ...response.data.listCoachAthleteFavoritess.items]
      }
      setTotalFavorites(
        caf
          .filter((r) => r.athlete.isVisible !== false)
          .map((r) => {
            return { ...r, athlete: { ...r.athlete, isFavorited: true } }
          })
      )
      // setCoachFavoritesAthletes(caf)
    } catch (e) {
      console.log(e)
      console.log('ERROR!!!!!')
    }
  }

  const listFavoritesInFolders = async (user) => {
    let favoritesInFolders = []
    let response = {}
    let params = {
      query: listFavoritesFolderAthleteRelations,
      variables: {
        limit: 500,
        input: {
          coachID: user.id,
        },
      },
    }
    try {
      response = await API.graphql(params)
      favoritesInFolders =
        response.data.listFavoritesFolderAthleteRelations.items
      while (response.data.listFavoritesFolderAthleteRelations.nextToken) {
        params.variables.nextToken =
          response.data.listFavoritesFolderAthleteRelations.nextToken
        response = await API.graphql(params)
        favoritesInFolders = [
          ...favoritesInFolders,
          ...response.data.listFavoritesFolderAthleteRelations.items,
        ]
      }

      setAthletesInFolder(
        favoritesInFolders
          .filter((r) => r.athlete.isVisible !== false)
          .map((athlete, index) => {
            return athlete
          })
      )
    } catch (e) {
      console.log('ERROR!!!!!')
      console.log(e)
    }
  }

  //                    _             _      __
  //                   | |           | |    / _|
  //     ___ ___  _ __ | |_ __ _  ___| |_  | |_ ___  _ __ _ __ ___
  //    / __/ _ \| '_ \| __/ _` |/ __| __| |  _/ _ \| '__| '_ ` _ \
  //   | (_| (_) | | | | || (_| | (__| |_  | || (_) | |  | | | | | |
  //    \___\___/|_| |_|\__\__,_|\___|\__| |_| \___/|_|  |_| |_| |_|
  //
  //
  const coachToAgencyFormSend = async ({ message, agencyId }) => {
    try {
      const enquery = await API.graphql({
        query: createCoachMessage,
        variables: {
          input: {
            agencyID: agencyId,
            message,
            coachID: userCoachData.id,
          },
        },
      })
      if (enquery) {
        return true
      }
    } catch (e) {
      return false
    }
  }
  const feedBackFormSend = async ({ message, type = 'feedback' }) => {
    try {
      const enquery = await API.graphql({
        query: createFeedback,
        variables: {
          input: {
            feedback_text: message,
            coachID: userCoachData.id,
            type: type,
          },
        },
      })
      if (enquery) {
        return true
      }
    } catch (e) {
      return false
    }
  }
  const requestInfoOfAthleteFormSend = async ({ message, athleteId }) => {
    try {
      const enquery = await API.graphql({
        query: createAthleteEnquiry,
        variables: {
          input: {
            athleteID: athleteId,
            enquiry_text: message,
            coachID: userCoachData.id,
          },
        },
      })
      if (enquery) {
        return true
      }
    } catch (e) {
      return false
    }
  }

  // AUTH
  const authCheckUser = async () => {
    // This function check us user is logged in the session. The time of session can by modified in AWS console.log()
    try {
      const authUser = await Auth.currentAuthenticatedUser()
      checkExpiredAndRefreshToken(authUser)
      updateUser(authUser)
    } catch (error) {
      updateUser('notLoggedIn')
    }
  }

  const authSignIn = async (username, password) => {
    try {
      const authUser = await Auth.signIn(username, password)
      if (
        authUser.attributes &&
        authUser.attributes['custom:disabledByUser'] === 'True'
      ) {
        await Auth.signOut()
        throw new Error('User does not exist.')
      }
      if (
        authUser.attributes &&
        authUser.attributes['custom:isActive'] === 'False'
      ) {
        await Auth.signOut()
        throw new Error('User is not actived.')
      }
      console.log('SIGNED IN', authUser)
      updateSDKToken(authUser)

      return authUser
      // onsole.log(authUser)
    } catch (error) {
      // console.log('error signing in', error.message);
      throw new Error(error.message)
    }
  }
  const authSignOut = async () => {
    try {
      await Auth.signOut()
    } catch (error) {
      // onsole.log('error signing out', error);
    }
  }

  const fetchNotifications = async () => {
    let notifications = []
    let response = {}
    let params = {
      query: listCoachNotificationRelations,
      variables: {
        filter: { coachID: { eq: user?.attributes['custom:profile_id'] } },
      },
    }

    try {
      response = await API.graphql(params)
      notifications = response.data.listCoachNotificationRelations.items
      while (response.data.listCoachNotificationRelations.nextToken) {
        params.variables.nextToken =
          response.data.listCoachNotificationRelations.nextToken
        response = await API.graphql(params)
        notifications = [
          ...notifications,
          ...response.data.listCoachNotificationRelations.items,
        ]
      }
      setNotifications(notifications)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchShowcases = async () => {
    let show = []
    let showAth = []
    let showCoach = []
    let response = {}

    // Showcases
    let params = {
      query: listShowcases,
      variables: {
        limit: 500,
      },
    }
    response = await API.graphql(params) // He make the first request to API
    show = response.data.listShowcases.items
    while (response.data.listShowcases.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listShowcases.nextToken
      response = await API.graphql(params)
      show = [...show, ...response.data.listShowcases.items]
    }
    setShowcases(show)

    // Showcase athlete relation
    params = {
      query: listShowcaseAthleteRelations,
      variables: {
        limit: 500,
      },
    }
    response = await API.graphql(params) // He make the first request to API
    showAth = response.data.listShowcaseAthleteRelations.items
    while (response.data.listShowcaseAthleteRelations.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken =
        response.data.listShowcaseAthleteRelations.nextToken
      response = await API.graphql(params)
      showAth = [
        ...showAth,
        ...response.data.listShowcaseAthleteRelations.items,
      ]
    }
    setShowcasesAthleteRelation(showAth)
  }

  const listFolders = async (user) => {
    let folders = []
    let response = {}
    let params = {
      query: listFavoritesFolders,
      variables: {
        limit: 500,
        filter: {
          coachID: { eq: user.id },
        },
      },
    }
    try {
      response = await API.graphql(params)
      folders = response.data.listFavoritesFolders.items
      while (response.data.listFavoritesFolders.nextToken) {
        params.variables.nextToken =
          response.data.listFavoritesFolders.nextToken
        response = await API.graphql(params)
        folders = [...folders, ...response.data.listFavoritesFolders.items]
      }

      setReceivedItemList(
        folders.map((item) => {
          return {
            id: item.id,
            name: item.folder_name,
          }
        })
      )
    } catch (e) {
      console.log(e)
      throw new Error('ERROR LISTANDO CARPETAS!!')
    }
  }

  return (
    <DataContext.Provider
      value={{
        user,
        updateUser,
        userCoachData,
        setUserCoachData, // Need to update coach team subscription
        isLoading,
        updateLoading,
        isDataProcessed,
        totalFavorites,
        setTotalFavorites,
        athletesInFolder,
        setAthletesInFolder,
        dragFavoriteItemRootFolder,
        setDragFavoriteItemRootFolder,
        athletes,
        filteredAthletes,
        filters,
        updateFilters: setFilters,
        selectorsContent,
        authCheckUser,
        authSignIn,
        authSignOut,
        favoriteToggle,
        agencies,
        // Contact forms submit functions
        coachToAgencyFormSend,
        feedBackFormSend,
        requestInfoOfAthleteFormSend,
        // isLoading: isLoading,
        // athletes: athletes, //array de atletas ya filtrados. Se obtienen al entrar en SearchResult.screen, solo cuando los filtros cambian. Si el filtro está vacío, este se envía como null, y aparecen todos los atletas. Es como aparece al inicio. No hay paginación.
        // updateAthletes: setAthletes, //función para actualizar los atletas.
        // filters: filters, //Objeto de filtros tal cual se le envía a la API de AppSync. Sirve para hacer la petición y para rellenar el formulario con los valores que ya se han enviado.
        // updateFilters: setFilters, //función para actualiciar el objeto filtro
        // searchString: searchString,
        // updateSearchString: setSearchString,
        // clearFilters: () => { setFilters({}), console.log("Filtros a cerito")}, //función para actualiciar el objeto filtro
        // user: user,
        // setUser: setUser,
        // userExtraData: userExtraData,
        // agencies: agencies,
        // agents: agents,
        // countries: countries,
        // agentPopulate: agentPopulate,
        // favoriteToggle: favoriteToggle,
        // updateUserFavoritesFromApi:updateUserFavoritesFromApi,
        // athleteSearch: athleteSearch,
        lastSavedToApiSearchString,
        updateLastSavedToApiSearchString: setLastSavedToApiSearchString,
        notifications: notifications,
        setNotifications: setNotifications,
        openSideBar: openSideBar,
        setOpenSideBar: setOpenSideBar,
        // athletesShuffle: () => setAthletes(_.shuffle(athletes))
        showcases: showcases,
        showcasesAthleteRelation: showcasesAthleteRelation,
        setPromotionalModalVisible: setPromotionalModalVisible,
        promotionalModalVisible: promotionalModalVisible,
        mySavedSearchFilters: mySavedSearchFilters,
        setMySavedSearchFilters: setMySavedSearchFilters,
        ratingIsChanged: ratingIsChanged,
        setRatingIsChanged: setRatingIsChanged,
        openPopup: openPopup,
        setOpenPopup: setOpenPopup,
        receivingItemList: receivingItemList,
        setReceivedItemList: setReceivedItemList,
        openPromotionPremiumPass: openPromotionPremiumPass,
        setOpenPromotionPremiumPass: setOpenPromotionPremiumPass,
        athleteIsLoaded: athleteIsLoaded,
        setAthleteIsLoaded: setAthleteIsLoaded,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
export default DataContextProvider
