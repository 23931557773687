import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';
import SettingsButton from '../../components/Settings/SettingsButtons';
// filters
import RightColumn from '../../components/_Layout/RightColumn';
import Popup from '../../components/_FormElements/Popup';
import { changeUserPassword } from '../../services/authService';
import InputField from '../../components/_FormElements/InputField';

function ChangePassword({ children }) {
  const [errorTextValue, setErrorTextValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  useEffect(() => {
    setIsOpen(false);
  }, []);

  const changeUserPasswordRequest = async (values, resetForm) => {
    const { old_password, new_password } = values;
    await changeUserPassword(old_password, new_password)
      .then((response) => {
        setErrorTextValue(response);
        resetForm(initialValues);
        setIsOpen(!isOpen);
      })
      .catch((error) => {
        setErrorTextValue(error.message);
      });
  };

  return (
    <Layout>
      <RightColumn />
      <ContentWrapper>
        <FeedBackLayout>
          <HeaderSpacer />
          <Header>
            <Title>Change Password</Title>
          </Header>
          <HeaderLine>Complete all fields</HeaderLine>
          {isOpen && (
          <Popup
            isOpen={isOpen}
            content={(
              <>
                <HeaderLine>Successful</HeaderLine>
                <Label>Your password has been changed successfully</Label>
              </>
)}
            handleClose={() => setIsOpen(!isOpen)}
          />
          )}
          <div>
            <Formik
              initialValues={{ old_password: '', new_password: '', password_confirmation: '' }}
              validate={(values) => {
							  const errors = {};
							  if (values.new_password.length < 8) errors.new_password = 'Password must be at least 8 characters';
							  if (!values.password_confirmation || values.password_confirmation !== values.new_password) errors.password_confirmation = 'Passwords do not match';
							  return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
							  setTimeout(() => {
							    changeUserPasswordRequest(values, resetForm);
							    setSubmitting(false);
							  }, 400);
              }}
            >
              {({
						  values,
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  handleSubmit,
						  isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ErrorText>{errorTextValue}</ErrorText>
                  <InputField
                    label="Old password"
                    placeholder="your password"
                    isPassword
                    value={values.old_password}
                    onChange={handleChange('old_password')}
                    onBlur={handleBlur('old_password')}
                  />
                  <ErrorText>{errors.old_password && touched.old_password && errors.old_password}</ErrorText>
                  <InputField
                    label="New password"
                    placeholder="new password"
                    isPassword
                    value={values.new_password}
                    onChange={handleChange('new_password')}
                    onBlur={handleBlur('new_password')}
                  />
                  <ErrorText>{errors.new_password && touched.new_password && errors.new_password}</ErrorText>
                  <InputField
                    label="Password confirmation"
                    placeholder="password confirmation"
                    isPassword
                    value={values.password_confirmation}
                    onChange={handleChange('password_confirmation')}
                    onBlur={handleBlur('password_confirmation')}
                  />
                  <ErrorText>{errors.password_confirmation && touched.password_confirmation && errors.password_confirmation}</ErrorText>
                  <Actions>
                    <Button type="submit" disabled={isSubmitting}>Submit</Button>
                  </Actions>
                </form>
              )}
            </Formik>
          </div>
        </FeedBackLayout>
      </ContentWrapper>
    </Layout>
  );
}

export default ChangePassword;

const FeedBackLayout = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin: 24px 0 8px;
  font-size: 13px;
`;

const STextInput = styled.input`
  width: 100%;
  padding: 13px 20px 11px 20px;
  line-height: 16px;
  font-size: 13px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.5);
  &::placeholder,
  &:focus,
  &:active {
    color: rgba(255, 255, 255, 1);
  }
`;
const Actions = styled.div`
  margin-top: 24px;
`;
const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: fixed;
  width: calc(
    100% - ${theme.sizes.columns.col1.tabletPortrait}px -
      ${theme.sizes.columns.col2.tabletPortrait}px
  );
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  top: 0;
  background: rgb(6, 14, 40);
  background: linear-gradient(
    180deg,
    rgba(6, 14, 40, 1) 0%,
    rgba(6, 14, 40, 1) 89%,
    rgba(6, 14, 40, 0) 100%
  );
  z-index: 1;
  @media ${theme.mq.desktop} {
    height: ${theme.sizes.header.heightBig.desktop}px;
    width: calc(
      100% - ${theme.sizes.columns.col1.desktop}px -
        ${theme.sizes.columns.col2.desktop}px
    );
  }
`;
const Title = styled.h1`
  margin: 120px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const HeaderLine = styled.div`
  font-size: 21px;
  line-height: 30px;
  font-weight: 700;
  margin: 6px 52px 12px 0;
  & strong {
    font-size: 25px;
    font-weight: 700;
    margin-right: 6px;
  }
`;
const ErrorText = styled.p`
  display: inline;
  font-size: 13px;
  font-weight: 700;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: red;
  line-height: 30px;
`;
const Button = styled.button`
	display: block;
	width: 100%;
	border-radius: 8px;
	border-width: 0px;
	cursor: pointer;
	font-family: ${theme.fonts.family1};
	font-size: 17px;
	font-weight: 700;
	line-height: 48px;
	min-height: 48px;
	text-align: center;
	background-color: ${theme.colors.royalBlue};
	color: ${theme.colors.white};
	${(props) => props.disabled && `
			opacity: 0.4;
		`}
`;
