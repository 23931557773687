export const onCreateCoachNotificationRelation = /* GraphQL */ `
  subscription OnCreateCoachNotificationRelation {
    onCreateCoachNotificationRelation {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
     
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          collegeID
         
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
       
      }
      
    }
  }
`;
export const updateCoachNotificationRelation = /* GraphQL */ `
  mutation UpdateCoachNotificationRelation(
    $input: UpdateCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    updateCoachNotificationRelation(input: $input, condition: $condition) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          collegeID
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoachNotificationRelation = /* GraphQL */ `
  mutation DeleteCoachNotificationRelation(
    $input: DeleteCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    deleteCoachNotificationRelation(input: $input, condition: $condition) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          collegeID
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateCoachNotificationRelation = /* GraphQL */ `
  subscription OnUpdateCoachNotificationRelation {
    onUpdateCoachNotificationRelation {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          collegeID
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
      }
    }
  }
`;

export const onCreateNotificationRelationByCoachId = /* GraphQL */ `
  subscription OnCreateNotificationRelationByCoachId($coachID: ID!) {
    onCreateNotificationRelationByCoachId(coachID: $coachID) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
    }
  }
`;
export const onUpdateNotificationRelationByCoachId = /* GraphQL */ `
  subscription OnUpdateNotificationRelationByCoachId($coachID: ID!) {
    onUpdateNotificationRelationByCoachId(coachID: $coachID) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
    }
  }
`;

export const onCreateAthleteFavoritesByCoachId = /* GraphQL */ `
  subscription OnCreateAthleteFavoritesByCoachId($coachID: ID!) {
    onCreateAthleteFavoritesByCoachId(coachID: $coachID) {
      id
      athleteID
      coachID
      rating
    }
  }
`;
export const onUpdateCoachAthleteFavoritesByCoachId = /* GraphQL */ `
  subscription OnUpdateCoachAthleteFavoritesByCoachId($coachID: ID!) {
    onUpdateCoachAthleteFavoritesByCoachId(coachID: $coachID) {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        showcases_participation {
          items {
            id
            showcaseID
            showcase {
              name
            }
            athleteID
            color
            number
            createdAt
            updatedAt
          }
          nextToken
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoachAthleteFavoritesByCoachId = /* GraphQL */ `
  subscription OnDeleteCoachAthleteFavoritesByCoachId($coachID: ID!) {
    onDeleteCoachAthleteFavoritesByCoachId(coachID: $coachID) {
      id
      athleteID
      coachID
      rating
    }
  }
`;
export const onCreateFavoritesFolderByCoachId = /* GraphQL */ `
  subscription OnCreateFavoritesFolderByCoachId($coachID: ID!) {
    onCreateFavoritesFolderByCoachId(coachID: $coachID) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFavoritesFolderByCoachId = /* GraphQL */ `
  subscription OnUpdateFavoritesFolderByCoachId($coachID: ID!) {
    onUpdateFavoritesFolderByCoachId(coachID: $coachID) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFavoritesFolderByCoachId = /* GraphQL */ `
  subscription OnDeleteFavoritesFolderByCoachId($coachID: ID!) {
    onDeleteFavoritesFolderByCoachId(coachID: $coachID) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFavoritesFolderAthleteRelationByFolderId = /* GraphQL */ `
  subscription OnCreateFavoritesFolderAthleteRelationByFolderId(
    $favoritesFolderID: ID!
  ) {
    onCreateFavoritesFolderAthleteRelationByFolderId(
      favoritesFolderID: $favoritesFolderID
    ) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFavoritesFolderAthleteRelationByFolderId = /* GraphQL */ `
  subscription OnUpdateFavoritesFolderAthleteRelationByFolderId(
    $favoritesFolderID: ID!
  ) {
    onUpdateFavoritesFolderAthleteRelationByFolderId(
      favoritesFolderID: $favoritesFolderID
    ) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFavoritesFolderAthleteRelationByFolderId = /* GraphQL */ `
  subscription OnDeleteFavoritesFolderAthleteRelationByFolderId(
    $favoritesFolderID: ID!
  ) {
    onDeleteFavoritesFolderAthleteRelationByFolderId(
      favoritesFolderID: $favoritesFolderID
    ) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTeamById = /* GraphQL */ `
  subscription OnUpdateTeamById($id: ID!) {
    onUpdateTeamById(id: $id) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_stripe_id
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college {
        id
        name
        country
        state
        address
        logo
        teams {
          nextToken
        }
      }
      coaches {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      subscription_history {
        items {
          id
          start_date
          end_date
          suscription_name
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;