import { Auth, a } from 'aws-amplify'
const AWS = require('aws-sdk')

const signIn = async (email, password) => {
  try {
    const response = await Auth.signIn(email, password)
    if (
      response.attributes &&
      response.attributes['custom:disabledByUser'] === 'True'
    ) {
      const responseSignOut = await Auth.signOut()
      throw new Error('User does not exist.')
    }
    if (
      response.attributes &&
      response.attributes['custom:isActive'] === 'False'
    ) {
      const responseSingOut = await Auth.signOut()
      throw new Error('User is not actived.')
    }
    return response
  } catch (error) {
    console.log('error')
    console.log(error)
    throw new Error(error.message)
  }
}

const resendConfirmationCode = async (username) => {
  try {
    const response = await Auth.resendSignUp(username)
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

const signUp = async (username, password, profile_id) => {
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        'custom:isActive': 'False',
        'custom:profile_id': profile_id,
      },
    })
    return user
  } catch (error) {
    console.log('error signing up:', error)
    throw new Error(error.message)
  }
}

const confirmSignUp = async (email, code) => {
  try {
    const response = await Auth.confirmSignUp(email, code, {
      forceAliasCreation: true,
    })
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

const completeNewPassword = async (user, newPassword) => {
  try {
    const response = await Auth.completeNewPassword(user, newPassword)
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

const signOut = async () => {
  try {
    const response = await Auth.signOut()
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

const checkAuth = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser()
    const { attributes, signInUserSession } = response
    return { attributes, jwtToken: signInUserSession.accessToken.jwtToken }
  } catch (error) {
    throw new Error(error.message)
  }
}

const changeUserPassword = async (old_password, new_password) => {
  const response = await Auth.currentAuthenticatedUser()
    .then((user) => Auth.changePassword(user, old_password, new_password))
    .then((data) => data)
    .catch((error) => {
      throw new Error(error.message)
    })
}

const forgotPassword = async (email) => {
  try {
    await Auth.forgotPassword(email)
      .then((data) => data)
      .catch((error) => {
        throw new Error(error.message)
      })
  } catch (error) {
    throw new Error(error.message)
  }
}

const resetPassword = async (email, code, new_password) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, new_password)
      .then((data) => data)
      .catch((error) => {
        throw new Error(error.message)
      })
  } catch (error) {
    throw new Error(error.message)
  }
}

const changeIsActive = async (username, active) => {
  let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: 'eu-west-1',
    apiVersion: '2016-04-18',
  })
  const params = {
    UserAttributes: [
      {
        Name: 'custom:isActive',
        Value: active ? 'True' : 'False',
      },
    ],
    UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    Username: username,
  }
  try {
    const response = await cognitoidentityserviceprovider
      .adminUpdateUserAttributes(params)
      .promise()
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

const updateSDKToken = async (user, email = '', password = '') => {
  // Get idToken
  let idToken = user?.signInUserSession?.idToken?.jwtToken
  if (!user?.signInUserSession) {
    // get idToken from user but no login yet
    const userAuth = await Auth.signIn(email, password)
    idToken = userAuth.signInUserSession.idToken.jwtToken
    await Auth.signOut()
  }
  // console.log('idToken: ', idToken)
  // Get the id for user from identity pool
  const cognitoidentity = new AWS.CognitoIdentity({
    apiVersion: '2014-06-30',
    region: 'eu-west-1',
  })

  try {
    cognitoidentity.getId(
      {
        IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        Logins: {
          [`cognito-idp.eu-west-1.amazonaws.com/${process.env.REACT_APP_AWS_USER_POOLS_ID}`]:
            idToken,
        },
      },
      (err, data) => {
        if (err) {
          console.log('Error getting identity id: ', err, err.message)
          // Check if the error is token expired, then we need to refresh the token
          if (
            err.code === 'NotAuthorizedException' &&
            err.message.includes('expired')
          ) {
            Auth.currentAuthenticatedUser()
          }
        } else {
          // console.log('Data: ', data)
          cognitoidentity.getCredentialsForIdentity(
            {
              // CustomRoleArn: `arn:aws:iam::706639107723:role/${role}`, Change role from groups (User with 2+ groups)
              IdentityId: data.IdentityId,
              Logins: {
                [`cognito-idp.eu-west-1.amazonaws.com/${process.env.REACT_APP_AWS_USER_POOLS_ID}`]:
                  idToken,
              },
            },
            (error, data2) => {
              if (error) {
                console.log(
                  '[ERROR] Error getting credentials for identity id',
                  error,
                  error.message
                )
              } else {
                // console.log('[OK] Credentials: ', data2)
                // Update aws-sdk with credentials that allow to assume role
                AWS.config.update({
                  region: 'eu-west-1',
                  credentials: new AWS.Credentials(
                    data2.Credentials.AccessKeyId,
                    data2.Credentials.SecretKey,
                    data2.Credentials.SessionToken
                  ),
                })
              }
            }
          )
        }
      }
    )
  } catch (error) {
    console.log('Error updating SDK token: ', error)
  }
}

const checkExpiredAndRefreshToken = async (user) => {
  const cognitoidentity = new AWS.CognitoIdentity({
    apiVersion: '2014-06-30',
    region: 'eu-west-1',
  })

  if (user.signInUserSession) {
    cognitoidentity.getId(
      {
        IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        Logins: {
          [`cognito-idp.eu-west-1.amazonaws.com/${process.env.REACT_APP_AWS_USER_POOLS_ID}`]:
            user.signInUserSession.idToken.jwtToken,
        },
      },
      (err, data) => {
        if (err) {
          // Check if the error is token expired
          if (
            err.code === 'NotAuthorizedException' &&
            err.message.includes('expired')
          ) {
            updateSDKToken(user)
          }
        }
      }
    )
  }
}

export {
  signIn,
  resendConfirmationCode,
  signOut,
  checkAuth,
  signUp,
  confirmSignUp,
  completeNewPassword,
  forgotPassword,
  resetPassword,
  changeUserPassword,
  changeIsActive,
  updateSDKToken,
  checkExpiredAndRefreshToken,
}
