export const getCoach = /* GraphQL */ `
  query GetCoach($id: ID!) {
    getCoach(id: $id) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      is_active
      allow_whatsapp_contact
      deleteAppReason
      division
      role
      sport
      teamID
      favorites {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
        }
        nextToken
      }
      my_filters {
        items {
          id
          name
          filter_body
          type
          sport
          source_app
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      my_favorite_folders {
        items {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      my_notifications {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      showcases_interested_in {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      my_athlete_tags {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        had_trial
        team_logo
        conference
        division
        college_category
        url_domain
        collegeID
        subscription_history {
          items {
            id
            start_date
            end_date
            suscription_name
            teamID
            createdAt
            updatedAt
          }
          nextToken
        }
        college {
          id
          name
          country
          state
          address
          logo
        }
      }
    }
  }
`;

export const createCoachMessage = /* GraphQL */ `
  mutation CreateCoachMessage(
    $input: CreateCoachMessageInput!
  ) {
    createCoachMessage(input: $input) {
      id
	}
}
`;
export const createCoachAthleteFavorites = /* GraphQL */ `
mutation CreateCoachAthleteFavorites(
  $input: CreateCoachAthleteFavoritesInput!
) {
  createCoachAthleteFavorites(input: $input) {
	id
	athleteID
	coachID
  }
}
`;
export const deleteCoachAthleteFavorites = /* GraphQL */ `
  mutation DeleteCoachAthleteFavorites(
    $input: DeleteCoachAthleteFavoritesInput!
  ) {
    deleteCoachAthleteFavorites(input: $input) {
      id
      athleteID
      coachID
	}
}
`;
export const createSearchTerm = /* GraphQL */ `
  mutation CreateSearchTerm(
    $input: CreateSearchTermInput!
  ) {
    createSearchTerm(input: $input) {
      id
	}
}
`;

// export const disableCoachAccount = /* GraphQL */ `
//   mutation DisableCoachAccount(
//     $input: UpdateCoachInput!!
//   ) {
//     updateCoach(input: $input) {
// 		disabledByUser
// 		deleteAppReason
// 	}
// }
// `

export const disableCoachAccount = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
		disabledByUser
		deleteAppReason
    }
  }
`;

// //{deleteAppReason: "", disabledByUser: false, id: "af08263f-1e5e-4128-9c07-c45680a80daf"}
// export const updateCoach = /* GraphQL */ `
//   mutation UpdateCoach(
//     $input: UpdateCoachInput!
// {
// 	updateCoach(input: $input) {
// 		disabledByUser
// 		deleteAppReason
// 	}
// `;

export const createCoach = /* GraphQL */ `
  mutation CreateCoach(
    $input: CreateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    createCoach(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      is_active
      sport
      division
      role
      teamID
    }
  }
`;

export const updateCoach = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
      id
      disabledByUser
      deleteAppReason
    }
  }
`;

export const listCoachNotificationRelations = /* GraphQL */ `
  query ListCoachNotificationRelations(
    $filter: ModelCoachNotificationRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachNotificationRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        is_viewed
        status
        notificationID
        coachID
        notification {
          id
          title
          text
          status
          type
          pic
          attachment
          createdAt
          updatedAt
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const createFavoritesFolderAthleteRelation = /* GraphQL */ `
  mutation CreateFavoritesFolderAthleteRelation(
    $input: CreateFavoritesFolderAthleteRelationInput!
    $condition: ModelFavoritesFolderAthleteRelationConditionInput
  ) {
    createFavoritesFolderAthleteRelation(input: $input, condition: $condition) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFavoritesFolderAthleteRelation = /* GraphQL */ `
  mutation UpdateFavoritesFolderAthleteRelation(
    $input: UpdateFavoritesFolderAthleteRelationInput!
    $condition: ModelFavoritesFolderAthleteRelationConditionInput
  ) {
    updateFavoritesFolderAthleteRelation(input: $input, condition: $condition) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFavoritesFolderAthleteRelation = /* GraphQL */ `
  mutation DeleteFavoritesFolderAthleteRelation(
    $input: DeleteFavoritesFolderAthleteRelationInput!
    $condition: ModelFavoritesFolderAthleteRelationConditionInput
  ) {
    deleteFavoritesFolderAthleteRelation(input: $input, condition: $condition) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateCoachAthleteFavorites = /* GraphQL */ `
  mutation UpdateCoachAthleteFavorites(
    $input: UpdateCoachAthleteFavoritesInput!
    $condition: ModelCoachAthleteFavoritesConditionInput
  ) {
    updateCoachAthleteFavorites(input: $input, condition: $condition) {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          collegeID
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCoachTagsRelation = /* GraphQL */ `
  mutation CreateCoachTagsRelation(
    $input: CreateCoachTagsRelationInput!
    $condition: ModelCoachTagsRelationConditionInput
  ) {
    createCoachTagsRelation(input: $input, condition: $condition) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCoachTagsRelation = /* GraphQL */ `
  mutation UpdateCoachTagsRelation(
    $input: UpdateCoachTagsRelationInput!
    $condition: ModelCoachTagsRelationConditionInput
  ) {
    updateCoachTagsRelation(input: $input, condition: $condition) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoachTagsRelation = /* GraphQL */ `
  mutation DeleteCoachTagsRelation(
    $input: DeleteCoachTagsRelationInput!
    $condition: ModelCoachTagsRelationConditionInput
  ) {
    deleteCoachTagsRelation(input: $input, condition: $condition) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCoachTagsRelations = /* GraphQL */ `
  query ListCoachTagsRelations(
    $filter: ModelCoachTagsRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachTagsRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        coachID
        tagID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        tag {
          id
          text
          type
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listFavoritesFolders = /* GraphQL */ `
  query ListFavoritesFolders(
    $filter: ModelFavoritesFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoritesFolders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listFavoritesFolderAthleteRelations = /* GraphQL */ `
  query ListFavoritesFolderAthleteRelations(
    $filter: ModelFavoritesFolderAthleteRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoritesFolderAthleteRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        athleteID
        favoritesFolderID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          facebook_url
          twitter_user
          linkedin_url
          createdAt
          updatedAt
        }
        favorites_folder {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCoachAthleteFavoritess = /* GraphQL */ `
  query ListCoachAthleteFavoritess(
    $filter: ModelCoachAthleteFavoritesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachAthleteFavoritess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        athleteID
        coachID
        rating
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          facebook_url
          twitter_user
          linkedin_url
          showcases_participation {
            items {
              id
              showcaseID
              showcase {
                name
              }
              athleteID
              color
              number
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const createFavoritesFolder = /* GraphQL */ `
  mutation CreateFavoritesFolder(
    $input: CreateFavoritesFolderInput!
    $condition: ModelFavoritesFolderConditionInput
  ) {
    createFavoritesFolder(input: $input, condition: $condition) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          collegeID
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        createdAt
        updatedAt
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteFavoritesFolder = /* GraphQL */ `
  mutation DeleteFavoritesFolder(
    $input: DeleteFavoritesFolderInput!
    $condition: ModelFavoritesFolderConditionInput
  ) {
    deleteFavoritesFolder(input: $input, condition: $condition) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          collegeID
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        createdAt
        updatedAt
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
