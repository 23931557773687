import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../styles/theme.styles';

import {
  FormLayout,
  HeaderTitle,
  HeaderInfoText,
  BackLinkContainer,
  BackLink,
} from './OnBoarding.style';

function ConfirmationScreen({ setStep, step }) {
  const navigate = useNavigate();

  return (
    <FormLayoutLeft>
      <HeaderTitle>Congratulations!!!!</HeaderTitle>
      <HeaderInfoText>You have successfully signed up. Our team will take some time to review your data and will activate your account very soon. Once we do that, you will receive an e-mail letting you know you are ready to go and explore the student-athletes on the app.</HeaderInfoText>
      <BackLinkContainer>
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.415 1.415L6 0L0 6L6 12L7.415 10.585L2.83 6L7.415 1.415Z" fill="white" />
        </svg>
        <BackLink onClick={() => navigate('/login')}>Back to Login</BackLink>
      </BackLinkContainer>
    </FormLayoutLeft>
  );
}

const FormLayoutLeft = styled(FormLayout)`
    align-items: start;
    text-align: left;
    border: 1px solid ${theme.colors.green};
    background-color: ${theme.colors.transparentGreen};
    padding: 54px;
`;
export default ConfirmationScreen;
