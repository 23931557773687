export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
        }
        nextToken
      }
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCoachNotificationRelation = /* GraphQL */ `
  query GetCoachNotificationRelation($id: ID!) {
    getCoachNotificationRelation(id: $id) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCoachNotificationRelations = /* GraphQL */ `
  query ListCoachNotificationRelations(
    $filter: ModelCoachNotificationRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachNotificationRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        is_viewed
        status
        notificationID
        coachID
        notification {
          id
          title
          text
          status
          type
          pic
          attachment
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
        }
      }
      nextToken
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCoachNotificationRelation = /* GraphQL */ `
  mutation CreateCoachNotificationRelation(
    $input: CreateCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    createCoachNotificationRelation(input: $input, condition: $condition) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date  
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCoachNotificationRelation = /* GraphQL */ `
  mutation UpdateCoachNotificationRelation(
    $input: UpdateCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    updateCoachNotificationRelation(input: $input, condition: $condition) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoachNotificationRelation = /* GraphQL */ `
  mutation DeleteCoachNotificationRelation(
    $input: DeleteCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    deleteCoachNotificationRelation(input: $input, condition: $condition) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
        }
      }
      createdAt
      updatedAt
    }
  }
`;