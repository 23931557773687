const settingButtonTypes = {
  BASIC: 'BASIC',
  SWITCH: 'SWITCH',
  SHARE: 'SHARE',
  LOGOUT: 'LOGOUT',
  DELETE: 'DELETE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  PAY_SUBSCRIPTION: 'PAY_SUBSCRIPTION',
  PAY_SUBSCRIPTION_NEW: 'PAY_SUBSCRIPTION_NEW',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  LEGAL: 'LEGAL',
  PRICE_OPTION: 'PRICE_OPTION',
}

export default settingButtonTypes
