import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import theme from '../../styles/theme.styles';
import { phoneSplitToCountryCodeAndNumber } from '../../helpers';
import { getImageAtSize } from '../../helpers/cloudinaryHelpers';

function ListItem({ agency, path }) {
  const {
    name,
    logo,
    country,
    contact_phone,
    athleteCount,
  } = agency;
  const phone = phoneSplitToCountryCodeAndNumber(contact_phone);
  const imageLogo = getImageAtSize({
    url: logo, w: 143, h: 83, o: { cropMode: 'fit' },
  });
  return (
    <ListItemContainer>
      <SListItem to={path}>
        <BlockAvatar>
          <Avatar>
            <AvatarImage image={imageLogo} />
          </Avatar>
        </BlockAvatar>
        <Texts>
          <HeaderLine><strong>{name}</strong></HeaderLine>
          <TextLine>
            {country.join(' | ')}
          </TextLine>
          {/* They ask for hide phone number of agencies - 13/02 */}
          {/* {
            (phone) ? (
              <TextLine>
                (+
                {phone.countryCode}
                )
                {phone.number}
              </TextLine>
            ) : null
          } */}
          <AthleteCount>
            <svg width={13} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.625 3.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM2.625 3.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM12.247 9.144l-.398-3.98a.872.872 0 0 0-.87-.789H8.271a.872.872 0 0 0-.87.787l-.399 3.982a.44.44 0 0 0 .435.481h.479l.358 3.588c.045.448.42.787.872.787h.958c.452 0 .826-.34.87-.787l.36-3.588h.478a.435.435 0 0 0 .435-.481ZM5.247 9.144l-.398-3.98a.872.872 0 0 0-.87-.789H1.271a.872.872 0 0 0-.87.787L.001 9.145a.44.44 0 0 0 .435.481h.479l.358 3.588c.045.448.42.787.872.787h.958c.452 0 .826-.34.87-.787l.36-3.588h.478a.44.44 0 0 0 .435-.481Z"
                fill="#fff"
              />
            </svg>
            {athleteCount}
          </AthleteCount>
        </Texts>
      </SListItem>
    </ListItemContainer>
  );
}

ListItem.propTypes = {
  athlete: PropTypes.object,
};

ListItem.defaultProps = {
  athlete: {},
};

export default ListItem;

const ListItemContainer = styled.div`
    position: relative;
    margin-bottom: 16px;
`;
const SListItem = styled(NavLink)`
    display: flex;
    padding: 12px;
    border-radius: 13px;
    color: ${theme.colors.white};
    text-decoration: none;
    background-color: ${theme.colors.transparentWhite2};
`;
const BlockAvatar = styled.div`
    position: relative;
    width: 173px;
    height: 113px;
`;

const Avatar = styled.div`
    position: relative;
    width: 173px;
    height: 113px;
    border-radius: 10px;
    background-color: white;
`;
const AvatarImage = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${(props) => props.image});
`;

const Texts = styled.div`
    flex: 1;
    margin-left: 14px;

`;

const HeaderLine = styled.div`
    font-size: 21px;
    line-height: 30px;
    font-weight: 400;
    margin: 6px 52px 2px 0;
    & strong{
        font-size: 25px;
        font-weight: 700;
        margin-right: 6px;
    }

`;
const TextLine = styled.div`
    font-size: 13px;
    line-height: 26px;
    font-weight: 400;
    svg{
        vertical-align: middle;
        margin-right: 8px;
    }
`;
const AthleteCount = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 10px;
    background-color: rgba(255,255,255,0.1);
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    & svg{
        vertical-align: middle;
        margin-right: 8px;
    }
`;

/*
act_date: "1900-01-01"
act_score: null
agency: {id: '2e961d7e-8eeb-4e0c-aee8-95b8384e7fb1', name: 'Soccer & Education USA', logo: 'https://res.cloudinary.com/dsvz8cktr/image/upload/…04/agency_logos/SEUSA_transparent_logo_gqy0n9.png', country: Array(1), contact_email: 'seusa@soccerandeducationusa.com', …}
agencyID: "2e961d7e-8eeb-4e0c-aee8-95b8384e7fb1"
agency_assesment: "Arnar Eide Gardarsson is a top attacking player for 2022. Arnar is a very dynamic player and can play on both wings as well as a central midfielder and right back. He is athletic, technical and strong in the air. He is very calm and collected on the ball, strong with the ball to his feet, holds up the play well and looks to play forward. He has a great work rate, decent speed and stamina. He is very dangerous in and around the box, providing goals and assists, and has great crossing and finishing abilities.\n \nArnar has played over 60 league games for his team in Iceland and recently got promoted. He was chosen the best U-19 player in his region in 2019.\n \nArnar has proven experience at the senior level in Iceland despite his young age. He is an interesting prospect for college soccer!"
agentID: "b241e1f2-abdf-4a84-9784-5cc35cfe23a1"
birthdate: "2001-10-18"
budget: 9000
card_pic: "https://res.cloudinary.com/dsvz8cktr/image/upload/v1635376319/ppvqkrlwo5somojumaou.jpg"
compliance: (4) ['NCAA_DI', 'NCAA_DII', 'NCAA_DIII', 'NAIA']
contact_email: "arnareide@gmail.com"
contact_phone: null
country_of_competition: "Iceland"
createdAt: "2021-10-18T17:00:24.029Z"
current_club: "Höttur/Huginn"
current_status: "AVAILABLE"
dominant_foot: "right"
duolingo: null
duolingo_date: "1900-01-01"
elegibility_notes: null
enquiries: {nextToken: null}
estimated_gpa: null
facebook_url: null
favorited: {nextToken: null}
featured_video: null
gemat_gre_date: "1900-01-01"
gemat_gre_score: null
gender: "male"
height: 190
high_school_graduation_date: "1900-01-01"
id: "f90f2470-84a4-44ed-84a6-0a427e921b4f"
ielts: null
ielts_date: "1900-01-01"
intended_degree: null
intended_field_of_studies: null
isFavorited: false
isVisible: true
level_of_competition: null
linkedin_url: null
name: "Arnar Eide Garðarsson"
nationality: "Iceland"
other_nice_pics: null
other_videos: null
position: "LEFT_WINGER"
profile_pic: "https://res.cloudinary.com/dsvz8cktr/image/upload/v1635473269/t4bim2if1gx8tymsucum.png"
sat_date: "1900-01-01"
sat_score: null
second_position: "RIGHT_WINGER"
showcase_participation: null
sport: "Soccer"
sports_background: null
start_date: "FALL_2022"
tags: {nextToken: null}
target_division: (3) ['NCAA_DI', 'NCAA_DII', 'NAIA']
toefl_date: "1900-01-01"
toefl_score: null
twitter_user: null
type_of_admission: "FRESHMAN"
updatedAt: "2021-10-29T02:07:58.010Z"
weight: 76 */
