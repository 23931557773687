import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';
import SettingsButton from '../../components/Settings/SettingsButtons';
import RightColumn from '../../components/_Layout/RightColumn';
import settingButtonTypes from '../../components/Settings/SettingsButtons.types';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LegalText from '../OnBoarding/LegalText';
import Popup from '../../components/_FormElements/Popup';

function Settings({ children }) {
  const dataContext = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const { authSignOut } = dataContext;
  // const dispatch = useAuthDispatch()

  const handleSignOut = () => {
    confirmAlert({
      overlayClassName: 'overlay-confirm-modal',
      customUI: ({ onClose }) => (
        <CustomModalUI>
          <ModalTitle>Are you sure?</ModalTitle>
          <ModalSubTitle>I want to logout</ModalSubTitle>
          <ModalButtonsContainer>
            <StyledButton onClick={onClose}>No</StyledButton>
            <StyledButton
              onClick={() => {
                authSignOut();
                onClose();
              }}
            >
              Yes
            </StyledButton>
          </ModalButtonsContainer>
        </CustomModalUI>
      ),
    });
  };

  const navigate = useNavigate();

  const handleChangePassword = () => {
    navigate('/change-password');
  };
  const handleDeleteAccount = () => {
    navigate('/delete-account');
  };
  const handleLegalScreen = () => {
    setIsOpen(!isOpen);
  };

  const userSettingsDefaults = [
    {
      type: settingButtonTypes.LEGAL,
      heading: 'Legal info',
      line2: 'Terms and privacy policy',
      onClick: handleLegalScreen,
    },
    {
      type: settingButtonTypes.CHANGE_PASSWORD,
      heading: 'Change Password',
      line2: 'Change your password',
      onClick: handleChangePassword,
    },
    {
      type: settingButtonTypes.DELETE,
      heading: 'Delete Account',
      line2: 'This action cannot be undone!',
      onClick: handleDeleteAccount,
    },
    {
      type: settingButtonTypes.SUBSCRIPTION,
      heading: 'Your subscription',
      line2: 'Manage your subscription',
      onClick: ()=>{navigate('/payments')}
    },
    {
      type: settingButtonTypes.LOGOUT,
      heading: 'Log Out',
      line2: 'See you soon',
      onClick: handleSignOut,
    },
  ];

  return (
    <Layout>
      {isOpen && (
        <Popup
          isOpen={isOpen}
          bgcolor={theme.colors.brandDark}
          textAlign="left"
          content={<LegalText />}
          handleClose={() => setIsOpen(!isOpen)}
        />
      )}
      <RightColumn />
      <ContentWrapper>
        <FeedBackLayout>
          <HeaderSpacer />
          <Header>
            <Title>Settings</Title>
          </Header>
          {userSettingsDefaults.map((button, index) => (
            <SettingsButton
              key={index}
              type={button.type}
              heading={button.heading}
              line2={button.line2}
              onClick={button.onClick}
            />
          ))}
        </FeedBackLayout>
      </ContentWrapper>
    </Layout>
  );
}

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;

const FeedBackLayout = styled.div`
  position: relative;
`;

const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  width: 100% ;
  top: 0;
  left: 0;
  background: rgb(6,14,40);
  background: linear-gradient(180deg, rgba(6,14,40,1) 0%, rgba(6,14,40,1) 89%, rgba(6,14,40,0) 100%);
  z-index: 2;
  @media ${theme.mq.desktop} { 
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const ModalTitle = styled.h1`
  margin: 10px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const ModalSubTitle = styled.p`
  margin: 10px 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.374px;
`;
const StyledButton = styled.div`
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
  &:hover {
    background-color: ${theme.colors.transparentWhite2};
    border-color: ${theme.colors.white};
  }
`;
const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 55px;
  margin-top: 20px;
`;
const ConfirmAlertContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
`;

const CustomModalUI = styled.div`
  text-align: center;
  width: 500px;
  padding: 40px;
  background: ${theme.colors.royalBlue};
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
`;
