import styled from 'styled-components';
import theme from '../../styles/theme.styles';

export const Form = styled.form`
display: flex;
flex-direction: column;
${'' /* align-items: center;
justify-content: center; */}
width: 100%;
height: 100%;
padding: 0px;
margin-top: 20px;
`;
export const ErrorText = styled.p`
color: red;
margin: 5px 0 10px;
`;
export const Button = styled.button`
display: block;
width: 100%;
border-radius: 8px;
border-width: 0px;
cursor: pointer;
font-family: ${theme.fonts.family1};
font-size: 17px;
font-weight: 700;
line-height: 48px;
min-height: 48px;
text-align: center;
color: ${theme.colors.white};
margin-top: 20px;
${(props) => props.disabled && 'opacity: 0.4;'}
`;
export const LoginScreenLayout = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: auto;
width: 100vw;
min-height: 100vh;
${(props) => props.step === 0 && ` background-color: ${theme.colors.royalBlue}`};
${(props) => props.step !== 0 && ` background-color: ${theme.colors.brandDark}`};
${Button} {
    ${(props) => props.step === 0 && ` background-color: ${theme.colors.white}`};
    ${(props) => props.step !== 0 && ` background-color: ${theme.colors.royalBlue}`};
    ${(props) => props.step === 0 && ` color: ${theme.colors.brandDark}`};
    ${(props) => props.step !== 0 && ` color: ${theme.colors.white}`};
}
padding: 4%;
margin: 0;
border: 0;
box-sizing: border-box;
`;
export const LoginScreenLayoutContent = styled.div`
display: flex;
flex-direction: column;
flex: 1;

width: 55vw;
height: 80%;
justify-content: center;
align-items: center;
background-color: ${theme.colors.transparentWhite2};
border-radius: 13px;
flex-direction: column;
padding: 1% 0;
`;
export const FormLayout = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100%;
width: 70%;
padding: 30px 20px;
margin: 0;
border: 0;
box-sizing: border-box;
text-align: center;
`;
export const HeaderTitle = styled.h1`
font-size: 32px;
font-weight: 700;
line-height: 48px;
margin-bottom: 32px;
`;
export const HeaderInfoText = styled.h1`
font-size: 15px;
font-weight: 400;
line-height: 24px;
`;
export const RegisterText = styled.p`
font-family: GothamBold;
font-size: 32px;
color: ${theme.colors.white};
`;
export const OpenModal = styled.span`
cursor: pointer;
font:  ${theme.fonts.gotham15Regular};
font-size: 13px;
font-weight: 700;
line-height: 16px;
color: ${theme.colors.white};
display: inline-block;
vertical-align: middle;
`;
export const Text = styled.span`
vertical-align: middle;
`;
export const DisclaimerText = styled.p`
font-size: 14px;
color: #999999;
text-align: center;
`;
export const BackLink = styled.h1`
  display: inline;
  font-size: 16px;
  font-family: ${theme.fonts.gotham13Regular};
  text-decoration: none;
  color: ${theme.colors.white};
  font-weight: 700;
  cursor: pointer;
  margin-left: 10px;
`;

export const BackLinkContainer = styled.div`
    margin-top: 55px;
`;
