import { find } from 'lodash';

export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const positionAcronym = (position) => {
  // if(typeof position !== 'string') return ''
  // let positionSplit = position.split('_')
  // let positionInitialed = positionSplit.map(item => item[0])
  // return positionInitialed.join('')
  const acronymPositions = {
    GOALKEEPER: 'GK',
    CENTER_BACK: 'CB',
    RIGHT_BACK: 'RB',
    LEFT_BACK: 'LB',
    DEFENSIVE_MIDFIELDER: 'DCM',
    CENTER_MIDFIELDER: 'CM',
    ATTACKING_MIDFIELDER: 'ACM',
    RIGHT_WINGER: 'RW',
    LEFT_WINGER: 'LW',
    RIGHT: 'RW',
    FORWARD_STRIKER: 'FWD',
  };

  return acronymPositions[position] ? acronymPositions[position] : '';
};

export const positionHumanize = (position) => {
  const humanizedPositions = [
    ['GOALKEEPER', 'Goalkeeper'],
    ['CENTER_BACK', 'Center back'],
    ['RIGHT_BACK', 'Right back'],
    ['LEFT_BACK', 'Left back'],
    ['DEFENSIVE_MIDFIELDER', 'Defensive Midfielder'],
    ['CENTER_MIDFIELDER', 'Center Midfielder'],
    ['ATTACKING_MIDFIELDER', 'Attacking Midfielder'],
    ['RIGHT_WINGER', 'Right Winger'],
    ['LEFT_WINGER', 'Left Winger'],
    ['RIGHT', 'Right'],
    ['FORWARD_STRIKER', 'Forward'],
  ];
  const humanizedTextArray = find(humanizedPositions, (hum) => hum[0] === position);
  const humanizedText = humanizedTextArray ? humanizedTextArray[1] : position;
  return humanizedText;
};

export const phoneSplitToCountryCodeAndNumber = (fullNumber) => {
  if (fullNumber) {
    const numbArray = fullNumber.split('');
    if (fullNumber.indexOf('+1') >= 0) {
      return (
        {
          countryCode: '1',
          number: numbArray.slice(2).join(''),
        }
      );
    }
    if (fullNumber.indexOf('+1') == -1) {
      return (
        {
          countryCode: numbArray.slice(1, 3).join(''),
          number: numbArray.slice(3).join(''),
        }
      );
    }
  }
};

export const convertToUserUnitHeight = (value) =>
  // para proxima versión donde se pueda configurar la unidad del usuario
  cmToInch(value);

const cmToInch = (cm) => {
  // return Math.round(cm * 0.39370079 * 100) / 100
  const realFeet = ((cm * 0.393700) / 12);
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return `${feet}"${inches}'`;
};

export const shortenBody = (string, splitLength) => {
  if (string && string.split('').length > splitLength) {
    const shortString = string.split('').splice(0, splitLength).join('');
    return `${shortString}... `;
  }
  return null;
};

export const youtubeParser = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length == 11) ? match[7] : false;
};
