import { API } from 'aws-amplify';
import React, {  useState, useEffect } from 'react';
import styled from 'styled-components';
import { createAthleteTag, createAthleteTagsRelation, deleteAthleteTagsRelation } from "../../custom_graphql_queries/athletes"
import { createCoachTagsRelation } from "../../custom_graphql_queries/coachs"
import Button from '../_FormElements/Button';
import InputField from '../_FormElements/InputField';

const TagForm = ({athlete, athleteTags, setAthleteTags, userCoachData, coachTags}) => {

  const [text, setText] = useState('')
  const athleteID = athlete.id

  const createTag = async(tag) =>{
    try {
      const response = await API.graphql({ 
        query: createAthleteTag, 
        variables: { 
          input: {
            text: tag,
            type: 'Coach'
          }
        }
      })
      return response
    } catch (e) {
      console.log("TAG ERROR!!!!!");
      console.log(e);
      throw new Error('Error creating tag',e);
    }
  }
  const createAthleteTagRelation = async({tagID}) =>{
    try {
      const response = await API.graphql({ 
        query: createAthleteTagsRelation, 
        variables: { 
          input: {
            athleteID: athleteID,
            tagID: tagID
          }
        }
      })
      return response
    } catch (e) {
      console.log("TAG ERROR!!!!!");
      console.log(e);
      throw new Error('Error creating athlete tag relation',e);
    }
  }
  const createCoachTagRelation = async({tagID}) =>{
    try {
      const response = await API.graphql({ 
        query: createCoachTagsRelation, 
        variables: { 
          input: {
            coachID: userCoachData.id, 
            tagID: tagID
          }
        }
      })
      return response
    } catch (e) {
      console.log("TAG REL ERROR!!!!!");
      console.log(e);
      throw new Error('Error creating coach tag relation',e);
    }
  }
  const deleteAthleteTagRelation = async(AthleteTagRelationID) =>{
    try {
      const response = await API.graphql({ 
        query: deleteAthleteTagsRelation, 
        variables: { 
          input: {
            id: AthleteTagRelationID
          }
        }
      })
      
      return response
    } catch (e) {
      console.log("DELETE TAG RELATION ERROR!!!!!");
      console.log(e);
      throw new Error('Error deleting coach tag relation',e);
    }
  }

  const addTag = async ()=>{
    
    //setAthleteTags([...athleteTags, text])
    setText('')

    let tagExist = coachTags.find(item => item.tag.text.toLowerCase() === text.toLowerCase())
    if(!tagExist) {
      await createTag(text).then((res)=>{
        let tagID = res.data.createAthleteTag.id
        return createAthleteTagRelation({tagID}).then((res)=>{
          let RelationAthleteID = res.data.createAthleteTagsRelation.id
          return createCoachTagRelation({tagID}).then((res)=>{
            setAthleteTags([...athleteTags, {RelationID: RelationAthleteID, name: text}])
          })
        })
      })
    } else {
      let tagID = tagExist?.tagID
      let IDtagExist = coachTags.find(item => item.tag.tagID === tagID)
      await createAthleteTagRelation({tagID}).then((res)=>{
        let RelationAthleteID = res.data.createAthleteTagsRelation.id
        if(!IDtagExist){
          return createCoachTagRelation({tagID}).then((res)=>{
            setAthleteTags([...athleteTags, {RelationID: RelationAthleteID, name: text}])
          })
        } else {
          setAthleteTags([...athleteTags, {RelationID: RelationAthleteID, name: text}])
        }
      })
    }
  }

  const removeTag = async(tag) => {
    deleteAthleteTagRelation(tag.RelationID).then(res=>setAthleteTags(athleteTags.filter((item) => item !== tag)))
  }

  function onChangeText(text) {
    setText(text.target.value)
  }

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        addTag()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [text]);

  return (
    <TagContainer>
      <CreateTagTitle>Add tags to {athlete.name}</CreateTagTitle>
      <CreateTagText>Write a descriptive tag for this athlete</CreateTagText>
      <TagInputContainer>
        <InputField placeholder={'Your desired tag'} onChange={onChangeText} value={text}/>
      </TagInputContainer>
      <Button label={'Add tag'} onClick={addTag}/>
      <TagsAreaContainer>
        {
          athleteTags &&
          athleteTags.map((tag, index) => {
            return (
              <div style={{display: 'inline-block', margin: '5px 10px', border: '1px solid white', padding: 6, borderRadius: 5}} key={index}>
                {tag.name} 
                <span style={{cursor: 'pointer', marginLeft: 5}} onClick={()=>removeTag(tag)}>
                  <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.717 1.697L12.303 0.283001L7 5.586L1.697 0.283001L0.283005 1.697L5.58601 7L0.283005 12.303L1.697 13.717L7 8.414L12.303 13.717L13.717 12.303L8.414 7L13.717 1.697Z" fill="white"/>
                  </svg>
                </span>
              </div>
            )
          })
        }
      </TagsAreaContainer>

    </TagContainer>
  )
}

export default TagForm

const TagContainer = styled.div`
  min-width: 400px;
  max-width: 500px;
  min-height: 200px;
`
const TagInputContainer = styled.div`
  margin: 20px 0;
`
const TagsAreaContainer = styled.div`
  margin-top: 10px;
`
const CreateTagTitle = styled.h1`
  margin: 0 25px 20px 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.35px;
`;
const CreateTagText = styled.h1`
  margin: 0 25px 10px 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
`;

