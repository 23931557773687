import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';

function IconButton({
  icon, label, onClick, disabled, side,
}) {
  return (
    <>
      <StyledButton disabled={disabled} onClick={!disabled ? onClick : null}>
        {(icon && side === 'left') && icon}
        <span style={{margin:"0 10px"}}>{label}</span>
        {(icon && side === 'right') && icon}
      </StyledButton>
    </>
  );
}

IconButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  side: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

IconButton.defaultProps = {
  label: '',
  onClick: () => {},
  disabled: false,
  side: 'left',
};

export default IconButton;

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0 10px;
  width: 100%;
  border-radius: 8px;
  border-width: 1px;
  ${(props) => (props.disabled ? `cursor: not-allowed;`: `cursor: pointer;`)}
  ${(props) => props.disabled && `opacity: 0.4;`}
  & span{
    display: block;
    width: 100 %;
    font-family: ${theme.fonts.family1};
    font-size: 17px;
    font-weight: 700;
    line-height: 48px;
    min-height: 48px;
    text-align: center;
  }
  background-color: ${theme.colors.transparentWhite2};
  border: 0.2px solid ${theme.colors.transparentWhite3};
  span{
    color: ${theme.colors.white};
  }
  &:hover{
    background-color: ${theme.colors.transparentWhite3};
  }
`;
