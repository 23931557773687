import { useNavigate } from 'react-router-dom'
import Button from '../_FormElements/Button'
import image from '../../assets/images/subcription_types.png'

const PromotionSlider = ({ setModalVisible }) => {
  const navigate = useNavigate()

  const onImagePressed = () => {
    if (setModalVisible) {
      setModalVisible(false)
    }
    navigate('/payments')
  }

  return (
    <div
      style={{
        alignItems: 'center',
        padding: '10px',
        justifyContent: 'center',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: 'calc(100% * 0.75)',
          height: 'calc(100% * 0.47)',
          padding: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* <MediaBox featured_video={{url:'https://www.youtube.com/watch?v=P3SE_m4n47Y'}} /> */}
        <div style={{ margin: '20px' }}>
          <img
            width="854"
            height="480"
            src={image}
            alt={'subscription types'}
          />
        </div>
        {/* <iframe
          src="https://player.vimeo.com/video/799093865?h=bcc783bd5c"
          width="854"
          height="480"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        /> */}
        <br />
        <Button
          label="Click here for more information"
          style={1}
          onClick={onImagePressed}
        />
      </div>
    </div>
  )
}

export default PromotionSlider
