import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { API } from 'aws-amplify';
import theme from '../../styles/theme.styles';

import OptionSelect from '../_FormElements/OptionSelect';
import FormSlider from '../_FormElements/FormSlider';

import Button from '../_FormElements/Button';
import IconButton from '../_FormElements/IconButton';
import { DataContext } from '../../contexts/dataContext';
import { createSearchTerm } from '../../custom_graphql_queries/coachs';

function Filters({ filters, updateFilters, selectorsContent, filtersToSave, setFiltersToSave, setIsOpenSaveFilters, setIsOpenSelectFilters }) {
  const dataContext = useContext(DataContext);
  const isPremium =   (dataContext.userCoachData?.team?.suscription_name === 'PREMIUM_PASS' || (dataContext.userCoachData?.team?.suscription_name === 'SEASON_PASS' && dataContext.userCoachData?.team?.had_trial !== null))
  const [filtersToShow, setFiltersToShow] = useState(filters);
  const [filtersAreReset, setfiltersAreReset] = useState(true);
  const [value, setValue] = useState(false);
  const [sendFiltersToAPI, setSendFiltersToAPI] = useState({});
  const [canApplyFilters, setCanApplyFilters] = useState(false);


  useEffect(() => {
    setFiltersToShow(filters);
  }, [filters]);

  useEffect(() => {
    (Object.keys(filtersToShow.filters).length) ? setCanApplyFilters(true) : setCanApplyFilters(false);
    setFiltersToSave({...filtersToSave, filter:filtersToShow.filters});
  }, [filtersToShow]);

  const onChangeFilterData = (value, name) => {
    let tempFilters = { ...filtersToShow.filters };

    if (selectorsContent[name].type === 'slider') {
      if (value !== selectorsContent[name].min) {
        tempFilters = { ...tempFilters, [name]: { ge: value } };
        setSendFiltersToAPI({ ...sendFiltersToAPI, [name]: value });
      } else {
        delete tempFilters[name];
        delete sendFiltersToAPI[name];
      }
    }
    if (selectorsContent[name].type === 'select') {
      if (value !== null && value !== '---') {
        tempFilters = { ...tempFilters, [name]: { eq: value } };
        setSendFiltersToAPI({ ...sendFiltersToAPI, [name]: value });
      } else {
        delete tempFilters[name];
        delete sendFiltersToAPI[name];
      }
    }
    setFiltersToShow({ ...filters, filters: tempFilters });
  };

  const saveSearchToApi = async (filters) => {
    const values = [];
    Object.keys(sendFiltersToAPI).forEach((key) => {
      if (key === 'agencyID') {
        values.push(dataContext.agencies.find((agency) => agency.id === sendFiltersToAPI[key]).name);
      } else {
        values.push(sendFiltersToAPI[key]);
      }
    });

    try {
      await API.graphql({
        query: createSearchTerm,
        variables: {
          input: {
            search_term_text: values.join(),
            coachID: dataContext.user.attributes['custom:profile_id'],
            sport: 'Soccer',
            source_app: 'WebApp',
            search_type: 'FILTER_SEARCH',
          },
        },
      });
    } catch (e) {
      console.log('ERROR!!!!!');
      console.log(e);
    }
  };

  const applyFilters = () => {
    setfiltersAreReset(false);
    updateFilters(filtersToShow);
    saveSearchToApi(filters);
  };
  const resetFilters = () => {
    setfiltersAreReset(true);
    setValue(null);
    updateFilters({ ...filters, filters: {} });
  };

  return (
    <>
      {
          <IconButton
            label="Saved searches" 
            onClick={()=>isPremium ? setIsOpenSelectFilters(true) : dataContext.setOpenPromotionPremiumPass(true)} 
            icon={
              isPremium ? (
                <svg width="14" height="12" viewBox="0 0 305 270" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M151.622 47.9297C151.797 48.2509 152.134 48.4507 152.5 48.4507C152.866 48.4507 153.203 48.2509 153.378 47.9297C158.366 38.7886 166.823 26.2511 179.498 16.4215C192.702 6.1819 207.661 1 223.984 1C269.541 1 304 38.3534 304 88.1168C304 114.946 293.273 137.578 274.281 160.559C255.264 183.57 228.034 206.843 195.124 234.965C183.947 244.516 171.282 255.34 158.115 266.887L158.114 266.887C156.562 268.249 154.569 269 152.5 269C150.431 269 148.438 268.249 146.886 266.887L146.885 266.886C133.723 255.345 121.061 244.523 109.893 234.98L109.883 234.971C76.9696 206.846 49.738 183.571 30.7196 160.559C11.7267 137.578 1 114.946 1 88.1168C1 38.3534 35.4593 1 81.0156 1C97.3388 1 112.298 6.1819 125.502 16.4215C138.177 26.2511 146.634 38.788 151.622 47.9297Z" fill="white" stroke="white" strokeWidth="2" strokeLinejoin="round"/>
                </svg>
              ) : (
                <svg width="15" height="13" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 0C7.14567 0 4 3.14567 4 7V9H2C0.895 9 0 9.895 0 11V23C0 24.105 0.895 25 2 25H20C21.105 25 22 24.105 22 23V11C22 9.895 21.105 9 20 9H18V7C18 3.27158 15.0366 0.268565 11.3555 0.0722656C11.2424 0.0267105 11.1219 0.00221916 11 0V0ZM11 2C13.7737 2 16 4.22633 16 7V9H6V7C6 4.22633 8.22633 2 11 2Z" fill="white"/>
                </svg>
              )
            }
            side="left"
          >
            Saved searches
          </IconButton>
      }
      <FiltersContainer>
        {
          Object.keys(selectorsContent).map((name, idx) => {
            if (selectorsContent[name].type === 'select') {
              return (
                <FilterSelect key={idx} content={selectorsContent[name]} name={name} onChange={onChangeFilterData} resetFilters={filtersAreReset} setResetFilters={setfiltersAreReset} value={filtersToShow.filters[name] && Object.values(filtersToShow.filters[name])[0]} isPremiumFeature={selectorsContent[name].premium} disabled={!isPremium}/>
              );
            }
            if (selectorsContent[name].type === 'slider') {
              return (
                <FilterSlider key={idx} content={selectorsContent[name]} name={name} onChange={onChangeFilterData} value={filtersToShow.filters[name] && Object.values(filtersToShow.filters[name])[0]} resetFilters={filtersAreReset} setResetFilters={setfiltersAreReset} isPremiumFeature={selectorsContent[name].premium} disabled={!isPremium}/>
              );
            }
          })
        }
        <ActionContainer>
          <ButtonContainer>
            <Button label="Apply" onClick={applyFilters} disabled={!canApplyFilters}>Clear filters</Button>
          </ButtonContainer>
          <ButtonContainer>
            <Button label="Reset Filters" onClick={resetFilters} disabled={filtersAreReset}>Clear filters</Button>
          </ButtonContainer>
          {isPremium && (
            <ButtonContainer>
              <Button label="Save Filters" onClick={()=>setIsOpenSaveFilters(true)} disabled={!canApplyFilters}>Save filters</Button>
            </ButtonContainer>
          )}
        </ActionContainer>
      </FiltersContainer>
    </>
  );
}

function FilterSelect({
  content, name, onChange, value, resetFilters, setResetFilters, isPremiumFeature, disabled
}) {
  const onChangeSelect = (e) => {
    onChange(e, name);
  };

  const options = (isPremiumFeature && disabled) ? [] : [...content.valueSets];
  options.unshift(['---', content.placeholder]);

  return (
    <>
      <div style={{display:'flex', alignItems: 'baseline'}}>
        <SelectLabel>{content.label}</SelectLabel>
        { isPremiumFeature && (
            <svg width="15" height="13" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 0C7.14567 0 4 3.14567 4 7V9H2C0.895 9 0 9.895 0 11V23C0 24.105 0.895 25 2 25H20C21.105 25 22 24.105 22 23V11C22 9.895 21.105 9 20 9H18V7C18 3.27158 15.0366 0.268565 11.3555 0.0722656C11.2424 0.0267105 11.1219 0.00221916 11 0V0ZM11 2C13.7737 2 16 4.22633 16 7V9H6V7C6 4.22633 8.22633 2 11 2Z" fill="white"/>
              </svg>
        )}
      </div>
      {/* If the field is premium and user is not premium, disable select */}
      <OptionSelect 
        options={options} 
        onChange={onChangeSelect} 
        defaultValue={content.placeholder} 
        value={value ? options.find(it=> it[0] === value)[0] : content.placeholder} 
        resetFilters={resetFilters} 
        setResetFilters={setResetFilters} 
        disabled={isPremiumFeature && disabled} 
        isPremiumFeature={isPremiumFeature}
      />
    </>
  );
}

function FilterSlider({
  content, name, onChange, resetFilters, setResetFilters, value, isPremiumFeature, disabled
}) {
  const onChangeSelect = (e) => {
    onChange(e, name);
  };
  const {
    min, max, unit, step,
  } = content;
  return (
    <>
      <div style={{display:'flex', alignItems: 'baseline'}}>
        <SelectLabel>{content.label}</SelectLabel>
        { isPremiumFeature && (
            <svg width="15" height="13" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 0C7.14567 0 4 3.14567 4 7V9H2C0.895 9 0 9.895 0 11V23C0 24.105 0.895 25 2 25H20C21.105 25 22 24.105 22 23V11C22 9.895 21.105 9 20 9H18V7C18 3.27158 15.0366 0.268565 11.3555 0.0722656C11.2424 0.0267105 11.1219 0.00221916 11 0V0ZM11 2C13.7737 2 16 4.22633 16 7V9H6V7C6 4.22633 8.22633 2 11 2Z" fill="white"/>
              </svg>
        )}
      </div>
      <FormSlider 
        min={min} 
        max={max} 
        value={value ? value : min} 
        unit={unit} 
        step={step} 
        onChange={onChangeSelect} 
        resetFilters={resetFilters} 
        setResetFilters={setResetFilters}
        disabled={isPremiumFeature && disabled} 
        isPremiumFeature={isPremiumFeature}
      />
    </>
  );
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  selectorsContent: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

Filters.defaultProps = {};

export default Filters;

const FiltersContainer = styled.div`
  position: relative;
  padding-bottom: 34px;
  margin-top: 5px;
`;
const SelectLabel = styled.label`
  display: block;
  margin: 16px 10px 8px 0;
  font-size: 13px;
  font-weight: 400;
  color: ${theme.colors.transparentWhite4};
`;

const ActionContainer = styled.div`
  width: 100% ;
  margin: 30px 0 0 0;
`;

const ButtonContainer = styled.div`
  margin-bottom: 10px;
`;
