export const getStripeCustomerID = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_STRIPE_SK}`);
    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }; 

    
    try {
        const response = await fetch("https://api.stripe.com/v1/customers", requestOptions).then(response => response.json())
        return response 
    } catch (error) {
        throw new Error(error.message);
    }
        
}

export const listStripeSubscriptions = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_STRIPE_SK}`);
    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }; 

    try {
        const response = await fetch("https://api.stripe.com/v1/subscriptions", requestOptions).then(response => response.json())
        return response 
    } catch (error) {
        throw new Error(error.message);
    }
        
}

export const cancelStripeSubscriptions = async (subscriptionID) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_STRIPE_SK}`);
    let requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    }; 

    try {
        const response = await fetch(`https://api.stripe.com/v1/subscriptions/${subscriptionID}`, requestOptions).then(response => response.json())
        return response 
    } catch (error) {
        throw new Error(error.message);
    }
        
}