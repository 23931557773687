import React , { useState, useContext, useEffect } from 'react';
import { API } from "aws-amplify";
import { DataContext } from '../../contexts/dataContext';
import { updateCoachAthleteFavorites } from '../../custom_graphql_queries/coachs';
import styled from 'styled-components';
import theme from '../../styles/theme.styles';

const RatingStars = ({ 
  rating,
  relationID,
  athleteID, 
  coachID,
  ratingIsChanged,
  setRatingIsChanged
}) => {

	const dataContext = useContext(DataContext);
  const { totalFavorites, setTotalFavorites } = dataContext
  const [rate, setRate] = useState(rating)

  const changeRating = async (index) => {
    const rate_value =  (rate === index + 1 ) ? 0 :index + 1
    
    try {
			await API.graphql({
        query: updateCoachAthleteFavorites, 
        variables: { input: {
          id:  relationID, 
          athleteID: athleteID,
          coachID: coachID,
          rating: rate_value,
        }}
      }).then((res)=>{
        
        setTotalFavorites( totalFavorites.map(res => res.athleteID === athleteID ? {...res,  rating: rate_value } : res ))
        setRate(rate_value)
        setRatingIsChanged(!ratingIsChanged)
        
      }).catch((e)=>{
        console.log('error')
        console.log(e)
      })
			
		} catch (e) {
			console.log("ERROR!!!!!");
			console.log(e);
		}
  }

  useEffect(()=>{
    setRate(rating)
  }, [rating])
  
  return (
    <StarBox>
      { [1,2,3,4,5].map((item, index) => {
        return (
          <ClickableArea onClick={()=> changeRating(index) } key={index} >
            <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5 0.416687L15.625 10H25L17.2917 15.4167L20.4167 24.7917L12.5 18.9584L4.58333 24.7917L7.70833 15.4167L0 10H9.375L12.5 0.416687Z" fill={index < rate ? theme.colors.gold : theme.colors.transparentWhite4}/>
            </svg>
          </ClickableArea>
        )
      })}
    </StarBox>
  )
}

export default RatingStars;


const StarBox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`
const ClickableArea = styled.div`
  padding: 5px 5px 5px 0;
  cursor: pointer;
`