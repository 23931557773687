import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import logo from '../../assets/images/LoginLogo.png';
import InputField from '../../components/_FormElements/InputField';
import theme from '../../styles/theme.styles';

import {
  FormLayout,
  HeaderTitle,
  HeaderInfoText,
  Form,
  Button,
  ErrorText,
  BackLink,
  BackLinkContainer,
} from './OnBoarding.style';

function SignUpStep({
  setStep,
  step,
  data,
  setData,
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{
        scale: 0,
      }}
      animate={{
        scale: 1,
        transition: {
          duration: 0.3,
        },
      }}
      style={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      exit={{
        x: '50vh',
        transition: {

          duration: 6.3,
        },
      }}
    >

      <FormLayoutLeft>
        <img src={logo} alt="logo" style={{ margin: '0 auto' }} />
        <HeaderTitle>Sign up</HeaderTitle>
        <HeaderInfoText />

        <Formik
          initialValues={{
            firstname: data.firstname || '', email: data.email || '', password: data.password || '', passwordConfirm: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            const { firstname, email, password } = values;
            setData({
              ...data, firstname, email, password,
            });
            setSubmitting(false);
            setStep(step + 1);
          }}
          validate={(values) => {
            const errors = {};
            if (!values.firstname) {
              errors.firstname = 'Required';
            }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            if (values.password.length < 8) {
              errors.password = 'Password must be at least 8 characters';
            }
            if (values.passwordConfirmation !== values.password) {
              errors.passwordConfirmation = 'Passwords must match';
            }
            
            return errors;
          }}
        >
          {({
            handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched,
          }) => (

            <Form onSubmit={handleSubmit}>

              <InputField
                label="First name and last name"
                placeholder="Your first name and last name"
                value={values.firstname}
                onBlur={handleBlur('firstname')}
                onChange={handleChange('firstname')}
              />
              {touched.firstname && errors.firstname && (<ErrorTextLeft>{errors.firstname}</ErrorTextLeft>)}

              <InputField
                label="Email"
                placeholder="yourname@email.com"
                onChange={handleChange('email')}
                value={values.email}
                onBlur={handleBlur('email')}
              />
              {touched.email && errors.email && (<ErrorTextLeft>{errors.email}</ErrorTextLeft>)}

              <InputField
                label="Password"
                placeholder="your password"
                isPassword
                value={values.password}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
              />
              {touched.password && errors.password && (<ErrorTextLeft>{errors.password}</ErrorTextLeft>)}

              <InputField
                label="Repeat Password"
                placeholder="Repeat your password"
                isPassword
                value={values.passwordConfirmation}
                onChange={handleChange('passwordConfirmation')}
                onBlur={handleBlur('passwordConfirmation')}
              />
              { errors.passwordConfirmation && (<ErrorTextLeft>{errors.passwordConfirmation}</ErrorTextLeft>)}

              <Button type="submit" disabled={isSubmitting} step={step}>Next</Button>
            </Form>
          )}
        </Formik>
        <BackLinkContainer>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.415 1.415L6 0L0 6L6 12L7.415 10.585L2.83 6L7.415 1.415Z" fill="white" />
          </svg>
          <BackLink onClick={() => navigate('/login')}>Back to Login</BackLink>
        </BackLinkContainer>
      </FormLayoutLeft>
    </motion.div>
  );
}

const FormLayoutLeft = styled(FormLayout)`
    align-items: flex-start;
`;
const ErrorTextLeft = styled(ErrorText)`
    text-align: left;
`;
export default SignUpStep;
