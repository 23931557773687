import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme.styles';
import { phoneSplitToCountryCodeAndNumber } from '../../helpers';
import { getImageAtSize } from '../../helpers/cloudinaryHelpers';

function InfoSmallAgency({ agency }) {
  const {
    name,
    logo,
    country,
    contact_phone,
  } = agency;
  const phone = phoneSplitToCountryCodeAndNumber(contact_phone);
  const imageLogo = getImageAtSize({
    url: logo, w: 69, h: 69, o: { cropMode: 'fit' },
  });

  return (
    <InfoSmallAgencyContainer>
      <SInfoSmallAgency>
        <BlockAvatar>
          <Avatar>
            <AvatarImage image={imageLogo} />
          </Avatar>
        </BlockAvatar>
        <Texts>
          <HeaderLine><strong>{name}</strong></HeaderLine>
          <TextLine>
            {country.join(' | ')}
          </TextLine>
          {/* <TextLine>
            (+
            {phone.countryCode}
            )
            {phone.number}
          </TextLine> */}
        </Texts>
      </SInfoSmallAgency>
    </InfoSmallAgencyContainer>
  );
}

InfoSmallAgency.propTypes = {
  athlete: PropTypes.object,
};

InfoSmallAgency.defaultProps = {
  athlete: {},
};

export default InfoSmallAgency;

const InfoSmallAgencyContainer = styled.div`
    position: relative;
    margin-bottom: 16px;
`;
const SInfoSmallAgency = styled.div`
    display: flex;
    color: ${theme.colors.white};
    text-decoration: none;
`;
const BlockAvatar = styled.div`
    position: relative;
    width: 99px;
    height: 99px;
`;

const Avatar = styled.div`
    position: relative;
    width: 99px;
    height: 99px;
    border-radius: 10px;
    background-color: white;
`;
const AvatarImage = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${(props) => props.image});
`;

const Texts = styled.div`
    flex: 1;
    margin-left: 14px;

`;

const HeaderLine = styled.div`
    font-size: 21px;
    line-height: 30px;
    font-weight: 400;
    margin: 6px 52px 2px 0;
    & strong{
        font-size: 25px;
        font-weight: 700;
        margin-right: 6px;
    }

`;
const TextLine = styled.div`
    font-size: 13px;
    line-height: 26px;
    font-weight: 400;
    svg{
        vertical-align: middle;
        margin-right: 8px;
    }
`;
