export const listAuxCollegeCoachInfos = /* GraphQL */ `
  query ListAuxCollegeCoachInfos(
    $filter: ModelAuxCollegeCoachInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuxCollegeCoachInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conference
        state
        division
        uniqueID
        sport
        added
        removed
        school
        first_name
        last_name
        position
        contact_email
        contact_phone
        landing_pages
        individual_twitter
        team_twitter
        individual_facebook
        team_facebook
        individual_instagram
        team_instagram
        questionnaire
        school2
        state2
        city
        region
        size_of_city
        private_or_public
        religious
        hbcu
        average_gpa
        sat_reading
        sat_math
        act_composite
        acceptance_rate
        total_yearly_cost
        school3
        majors_offered
        number_of_undergrads
        us_ranking
        us_arts_ranking
        ipeds
        college_category
      }
      nextToken
    }
  }
`;