import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import ContentWrapper from '../../components/_Layout/ContentWrapper';
import Layout from '../../components/_Layout/Layout';
import { DataContext } from '../../contexts/dataContext';
import theme from '../../styles/theme.styles';
import NotificationsButton from '../../components/Notifications/NotificationButton';
import RightColumn from '../../components/_Layout/RightColumn';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LegalText from '../OnBoarding/LegalText';
import Popup from '../../components/_FormElements/Popup';
import { listCoachNotificationRelations } from '../../custom_graphql_queries/coachs';

function Notifications() {
  const dataContext = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const { user, authSignOut,notifications } = dataContext;
  // const [notifications, setNotifications] = useState([]);

  // const fetchNotifications = async ({ setNotifications, user }) => {
  //   try {
  //     const response = await API.graphql({
  //       query: listCoachNotificationRelations,
  //       variables: {
  //         filter: { coachID: {eq:user?.attributes['custom:profile_id']}},
  //       },
  //     });
  //     return response;
  //     // setNotifications(response.data.listCoachNotificationRelations.items);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  // useEffect(() => {
  //   fetchNotifications({ setNotifications, user }).then((res) => setNotifications(res.data.listCoachNotificationRelations.items));
  // }, []);
  

  return (
    <Layout>
      {isOpen && (
        <Popup
          isOpen={isOpen}
          bgcolor={theme.colors.brandDark}
          textAlign="left"
          content={<LegalText />}
          handleClose={() => setIsOpen(!isOpen)}
        />
      )}
      <RightColumn />
      <ContentWrapper>
        <FeedBackLayout>
          <HeaderSpacer />
          <Header>
            <Title>Notifications</Title>
          </Header>
          {notifications.sort((item1, item2) => new Date(item2.createdAt) - new Date(item1.createdAt)).map((notification, index) => (
            <NotificationsButton
              key={index}
              notification={notification}
            />
          ))}
        </FeedBackLayout>
      </ContentWrapper>
    </Layout>
  );
}

Notifications.propTypes = {};

Notifications.defaultProps = {};

export default Notifications;

const FeedBackLayout = styled.div`
  position: relative;
`;

const HeaderSpacer = styled.div`
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  @media ${theme.mq.desktop} { 
    height: height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Header = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: ${theme.sizes.header.heightBig.tabletPortrait}px;
  width: 100% ;
  top: 0;
  left: 0;
  background: rgb(6,14,40);
  background: linear-gradient(180deg, rgba(6,14,40,1) 0%, rgba(6,14,40,1) 89%, rgba(6,14,40,0) 100%);
  z-index: 2;
  @media ${theme.mq.desktop} { 
    height: ${theme.sizes.header.heightBig.desktop}px;
  }
`;
const Title = styled.h1`
  margin: 30px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const ModalTitle = styled.h1`
  margin: 10px 0 23px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.374px;
`;
const ModalSubTitle = styled.p`
  margin: 10px 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.374px;
`;
const StyledButton = styled.div`
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
  &:hover {
    background-color: ${theme.colors.transparentWhite2};
    border-color: ${theme.colors.white};
  }
`;
const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 55px;
  margin-top: 20px;
`;
const ConfirmAlertContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
`;

const CustomModalUI = styled.div`
  text-align: center;
  width: 500px;
  padding: 40px;
  background: ${theme.colors.royalBlue};
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
`;
