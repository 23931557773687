const selectorsContent = {
  showcase_participation: {
    label: 'Showcase',
    type: 'select',
    placeholder: 'Not important',
    premium: true,
    valueSets: [], // Comes from API
  },
  gender: {
    label: 'Gender',
    type: 'select',
    placeholder: 'Not relevant',
    valueSets: [
      ['female', 'Female'],
      ['male', 'Male'],
    ],
  },
  start_date: {
    label: 'Start date',
    type: 'select',
    placeholder: 'Any season',
    valueSets: [
      ['SPRING_2023', 'Spring 2023'],
      ['FALL_2023', 'Fall 2023'],
      ['SPRING_2024', 'Spring 2024'],
      ['FALL_2024', 'Fall 2024'],
      ['SPRING_2025', 'Spring 2025'],
      ['FALL_2025', 'Fall 2025'],
      ['SPRING_2026', 'Spring 2026'],
      ['FALL_2026', 'Fall 2026'],
    ],
  },
  target_division: {
    label: 'Target Divisions',
    type: 'select',
    placeholder: 'Any division',
    valueSets: [
      ['NCAA_DI', 'NCAA DI'],
      ['NCAA_DII', 'NCAA DII'],
      ['NCAA_DIII', 'NCAA DIII'],
      ['NAIA', 'NAIA'],
      ['NJCAA', 'NJCAA'],
      ['JUCO', 'JUCO'],
      ['USCAA', 'USCAA'],
      ['USPORT_CANADA', 'USPORT CANADA'],
    ],
  },
  position: {
    label: 'Primary position',
    type: 'select',
    placeholder: 'Any position',
    valueSets: [
      ['GOALKEEPER', 'Goalkeeper'],
      ['CENTER_BACK', 'Center back'],
      ['RIGHT_BACK', 'Right back'],
      ['LEFT_BACK', 'Left back'],
      ['DEFENSIVE_MIDFIELDER', 'Defensive Midfielder'],
      ['CENTER_MIDFIELDER', 'Center Midfielder'],
      ['ATTACKING_MIDFIELDER', 'Attacking Midfielder'],
      ['RIGHT_WINGER', 'Right Winger'],
      ['LEFT_WINGER', 'Left Winger'],
      ['FORWARD_STRIKER', 'Forward'],
    ],
  },
  dominant_foot: {
    label: 'Dominant foot',
    type: 'select',
    placeholder: 'Not relevant',
    valueSets: [
      ['right', 'Right foot'],
      ['left', 'Left foot'],
    ],
  },
  country_of_competition: {
    label: 'Competition Country',
    type: 'select',
    placeholder: 'Any country',
    premium: true,
    valueSets: [], // Comes from API
  },
  psawithvideos: {
    label: 'PSA with videos',
    type: 'select',
    placeholder: 'Not important',
    premium: true,
    valueSets: [
      ['Highlight Video', 'Highlight Video'],
      ['Other Videos', 'Other Videos'],
      ['Highlight and Other Videos', 'Highlight and Other Videos'],
    ],
  },
  current_status: {
    label: 'Current Status',
    type: 'select',
    placeholder: 'Not important',
    premium: true,
    valueSets: [
      ['COMMITTED', 'Committed'],
      ['ON_HOLD', 'On hold'],
      ['AVAILABLE', 'Available'],
    ],
  },
  agencyID: {
    label: 'Agency',
    type: 'select',
    placeholder: 'All agencies',
    premium: true,
    valueSets: [], // Comes from API
  },
  type_of_admission: {
    label: 'Type of admission',
    type: 'select',
    placeholder: 'Any type',
    premium: true,
    valueSets: [
      ['INTERNATIONAL_TRANSFER', 'International Transfer'],
      ['FRESHMAN', 'Freshman'],
      ['US_TRANSFER', 'US Transfer'],
      ['GRADUATE', 'Graduate'],
      ['OTHER', 'Other'],
    ],
  },
  budget: {
    label: 'Budget',
    type: 'slider',
    min: 0,
    max: 80000,
    step: 1000,
    unit: '',
    premium: true,
  },
  sat_score: {
    label: 'SAT Score',
    type: 'slider',
    min: 800,
    max: 1600,
    step: 50,
    unit: '',
  },
  toefl_score: {
    label: 'TOEFL Score',
    type: 'slider',
    min: 0,
    max: 120,
    step: 20,
    unit: '',
  },
  estimated_gpa: {
    label: 'GPA Score',
    type: 'slider',
    min: 0,
    max: 4,
    step: 0.01,
    unit: '',
  },
}

export default selectorsContent
