const theme = {
  mq: {
    tabletPortrait: '(min-width: 768px)',
    desktop: '(min-width: 1024px)',
  },
  colors: {
    black: 'rgba(0,0,0,1)',
    green: 'rgba(15,169,88,1)',
    alertPink: 'rgba(238,73,95,1)',
    royalBlue: 'rgba(32,73,218,1)',
    darkInactiveBlue: 'rgba(53, 65, 107, 1)',
    darkOrange: 'rgba(255,152,0,1)',
    // silver: 'rgba(178,184,180,1)',
    red: 'rgba(224, 48, 71, 1)',
    gold: 'rgba(255,211,55,1)',
    purple: 'rgba(120, 117, 249, 1)',
    midnightBlue: '#060E28',
    transparentGreen: 'rgba(15,169,88,0.3)',
    transparentBlack: 'rgba(6,14,40,0.2)',
    transparentBlack2: 'rgba(0,0,0,0.25)',
    transparentBlack3: 'rgba(0,0,0,0.1)',
    transparentBlack4: 'rgba(0,0,0,0.7)',
    transparentYellow: 'rgba(255, 211, 55, 0.06)',
    // transparentWhite: 'rgba(255,255,255,0.01)',
    transparentWhite2: 'rgba(255,255,255,0.06)',
    transparentWhite3: 'rgba(255,255,255,0.2)',
    transparentWhite4: 'rgba(255,255,255,0.6)',
    // transparentWhite5: 'rgba(255,255,255,0.4)',
    transparentWhite6: 'rgba(255,255,255,0.8)',
    white: '#fff',
    // white05: 'rgba(255,255,255,0.05)',
    // white50: 'rgba(255,255,255,0.5)',
    brandDark: 'rgba(6,14,40,1)',
    // inactiveText: '#868A97',
    profileSettings: 'rgba(176, 123, 243, 1)',
    profileFavorite: 'rgba(245, 144, 145, 1)',
    profileFeedback: 'rgba(120, 117, 249, 1)',
    buttonBackground: 'rgba(21, 28, 53, 0.64)',
  },
  sizes: {
    maxWidth: 1440,
    padding: {
      tabletPortrait: 24,
    },
    columns: {
      col1: {
        tabletPortrait: 192,
        desktop: 256,
      },
      col2: {
        tabletPortrait: 256,
        desktop: 304,
      },
    },
    header: {
      height: {
        tabletPortrait: 116,
        desktop: 116,
      },
      heightBig: {
        tabletPortrait: 180,
        desktop: 180,
      },
      paddingTop: {
        tabletPortrait: 26,
        desktop: 38,
      },
    },
  },

  fonts: {
    family1: 'Open Sans',
    gotham11Regular: {
      family: 'Open Sans',
      size: '11px',
      weight: '400',
      lineHeight: '17px',
    },
    gotham13Regular: {
      family: 'Open Sans',
      size: '13px',
      weight: '400',
      lineHeight: '26px',
    },
    gotham13RegularLine20: {
      family: 'Open Sans',
      size: '13px',
      weight: '400',
      lineHeight: '20px',
    },
    gotham15Regular: {
      family: 'Open Sans',
      size: '15px',
      weight: '400',
      lineHeight: 'normal',
    },
    gotham17Regular: {
      family: 'Open Sans',
      size: '17px',
      weight: '400',
      lineHeight: 'normal',
    },
    gotham21Bold: {
      family: 'Open Sans',
      size: '21px',
      weight: '700',
      lineHeight: '42px',
    },
    gotham21Regular: {
      family: 'Open Sans',
      size: '21px',
      weight: '400',
      lineHeight: '59px',
    },
    gotham25Bold: {
      family: 'Open Sans',
      size: '25px',
      weight: '700',
      lineHeight: '24px',
    },
    gotham25Regular: {
      family: 'Open Sans',
      size: '25px',
      weight: '400',
      lineHeight: '24px',
    },
    helveticaBold: {
      family: 'Helvetica',
      size: '24px',
      weight: '700',
      lineHeight: 'normal',
    },
    helveticaRegular: {
      family: 'Helvetica',
      size: '16px',
      weight: '400',
      lineHeight: '30px',
    },
    font17Regular: {
      size: '17px',
      weight: '400',
    },
    font15Regular: {
      size: '15px',
      weight: '400',
    },
    font13Regular: {
      size: '13px',
      weight: '400',
    },
    infoBitMain: {
      family: 'Open Sans',
      size: '25px',
      weight: '700',
      lineHeight: '28px',
    },
    infoBitTitle: {
      family: 'Open Sans',
      size: '12px',
      weight: '500',
      lineHeight: '16px',
    },
    searchTitle: {
      family: 'Open Sans',
      size: '32px',
      weight: '700',
      lineHeight: '32px',
    },
    valueBoxText: {
      family: 'Open Sans',
      size: '16px',
      weight: '500',
      lineHeight: '24px',
    },
  },
};

export default theme;
