import React, { useContext } from 'react'
import { createRoot } from 'react-dom/client'
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom'
import './styles/reset.css'

import Amplify from 'aws-amplify'

// Screens
import Root from './screens/root'
import LoginScreen from './screens/Login/Login'
import SearchResult from './screens/SearchResult/SearchResult'
import Agencies from './screens/Agencies/Agencies'
import Favorites from './screens/Favorites/Favorites'
import Feedback from './screens/Feedback/Feedback'
import Notifications from './screens/Notifications/Notifications'
import Settings from './screens/Settings/Settings'
import ChangePassword from './screens/Settings/ChangePassword'
import DeleteAccount from './screens/Settings/DeleteAccount'
import AthleteView from './screens/AthleteView/AthleteView'
import AgencyView from './screens/Agencies/AgencyView'
import ForgotPassword from './screens/ForgotPassword/ForgotPassword'
import ResetForgotPassword from './screens/ForgotPassword/ResetForgotPasswordForm'
import ChangePasswordConfirmation from './screens/ForgotPassword/ChangePasswordConfirmation'
import OnBoarding from './screens/OnBoarding/Onboarding'
import Payments from './screens/Payments/Payments'
import Cancel from './screens/Payments/Cancel'
import Success from './screens/Payments/Success'
import NewPasswordRequired from './screens/NewPasswordRequired/NewPasswordRequired'

// OnlyDesktop
import OnlyDesktop from './components/OnlyDesktop'

// Helpers
import Loading from './components/Loading'

import reportWebVitals from './reportWebVitals'

// For data
import DataContextProvider, { DataContext } from './contexts/dataContext'
import FavoritesFolder from './screens/Favorites/FavoritesFolder'
import SubscriptionProvider from './contexts/subscriptionProvider'
import ShowcaseFeedBack from './screens/Feedback/ShowcaseFeedback'

// Amplify
const awsmobile = {
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
  aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY,
}
Amplify.configure(awsmobile)

function TheRoutes() {
  const dataContext = useContext(DataContext)
  // We need to get the location to know if we are in the favorites folder or not
  const location = useLocation()
  const locationSection = location.pathname?.split('/')[2]?.split('_')[0]
  const { user } = dataContext
  if (!user) {
    return <Loading forceVisible />
  }

  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/login/" element={<LoginScreen />} />
      {user.challengeName === 'newPasswordRequired' && (
        <Route
          path="/new-password-required/"
          element={<NewPasswordRequired />}
        />
      )}
      {user !== 'notLoggedIn' &&
        user.challengeName !== 'newPasswordRequired' && (
          <>
            <Route path="/app/" element={<SearchResult />}>
              <Route path=":athleteId" element={<AthleteView />} />
            </Route>
            <Route path="/agencies/" element={<Agencies />}>
              <Route path=":agencyId" element={<AgencyView />} />
            </Route>
            <Route path="/favorites/" element={<Favorites />}>
              {/* If the subroute is folder render FavoritesFolder component, otherwise render AthleteView */}
              {locationSection === 'folder' ? (
                <Route path=":folderId" element={<FavoritesFolder />} />
              ) : (
                <Route path=":athleteId" element={<AthleteView />} />
              )}
            </Route>
            <Route path="/feedback/" element={<Feedback />} />
            <Route path="/notifications/" element={<Notifications />} />
            <Route path="/settings/" element={<Settings />} />
            <Route path="/payments/" element={<Payments />} />
            <Route path="/success/:coachId" element={<Success />} />
            <Route path="/cancel/" element={<Cancel />} />
            <Route path="/change-password/" element={<ChangePassword />} />
            <Route path="/delete-account/" element={<DeleteAccount />} />
            <Route path="/showcase-feedback/" element={<ShowcaseFeedBack />} />
            {/* <Route path="/settings/" element={<Settings />}>
            <Route path="change-password" element={<ChangePassword />} />
          </Route> */}
          </>
        )}
      <Route path="*" element={<Navigate to="/login/" replace />} />
      <Route path="/forgot-password/" element={<ForgotPassword />} />
      <Route path="/reset-password/" element={<ResetForgotPassword />} />
      <Route
        path="/password-confirmation/"
        element={<ChangePasswordConfirmation />}
      />
      <Route path="/onboarding/" element={<OnBoarding />} />
    </Routes>
  )
}

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <OnlyDesktop />
      <DataContextProvider>
        <SubscriptionProvider>
          <TheRoutes />
        </SubscriptionProvider>
      </DataContextProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
