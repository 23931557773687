import React, { useContext } from 'react';
import styled from 'styled-components';
import { DataContext } from '../../contexts/dataContext';

import Header from './Header';
import MainMenu from './MainMenu';
import theme from '../../styles/theme.styles';

// Helpers
import Loading from '../Loading';
import RightColumn from './RightColumn';
import NotificationButton from '../Notifications/NotificationButton';
import Sidebar from './Sidebar';

function Layout(props) {
  const dataContext = useContext(DataContext);
  const { isLoading } = dataContext;

  // User data
  const { userCoachData } = dataContext;
  const userName = userCoachData?.name;

  // Auth functions
  const { authSignOut, notifications, openSideBar, setOpenSideBar} = dataContext;

  const { children } = props;

  if (isLoading) return <Loading visible={isLoading} />;

  

  return (
    <SLayout>
      <Header userName={userName} onLogout={authSignOut} />
      {/* <RightColumn open={openSideBar}>
        {notifications.map(it=>{ return <NotificationButton notification={it} isMini={true}/>})}
      </RightColumn> */}
      <Sidebar isOpen={openSideBar}>
        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="currentColor" viewBox="0 0 16 16" onClick={()=>setOpenSideBar(!openSideBar)}> 
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white" />
        </svg>
        {
          notifications.length > 0 ? (
            notifications.filter(it=>it.status !== 'DELETED').sort((a,b) => { return new Date(b.createdAt) - new Date(a.createdAt) }).map((it, index)=>{ return <NotificationButton notification={it} isMini={true} key={index} />})
          ) : (
            <NoContentText>Sorry, we have no notifications for you for now.<br /><br /> Stay tuned!</NoContentText>
          )
        }
      </Sidebar>
      <MainMenu
        menuItems={mainMenuItems}
      />
      <main>{children}</main>
    </SLayout>
  );
}
export default Layout;

const SLayout = styled.div``;

const NoContentText = styled.div`
    padding-bottom: 18px;
    padding-left: 10px;
    font-size: 14px;
    line-height : 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${theme.colors.transparentWhite4};

`;

const mainMenuItems = [
  {
    label: 'Athletes',
    path: '/app/',
    color: theme.colors.royalBlue,
    icon:
		(
  <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.334 11.167c0 5.885 4.78 10.666 10.667 10.666 5.886 0 10.666-4.78 10.666-10.666S16.887.5 11.001.5.334 5.28.334 11.167zm2.407 0c0-4.113 3.029-7.538 6.97-8.16-.2 2.264-.853 4.364-1.949 6.267-1.1 1.913-2.607 3.531-4.479 4.834a8.21 8.21 0 01-.542-2.941zm8.25-2.925a17.173 17.173 0 01-2.546 4.391 17.155 17.155 0 015.093-.003 17.125 17.125 0 01-2.546-4.388zm7.725 5.873a8.21 8.21 0 00.544-2.948c0-4.117-3.034-7.545-6.982-8.161.2 2.264.854 4.365 1.95 6.269 1.103 1.915 2.612 3.535 4.488 4.84zM4.59 16.368a8.25 8.25 0 006.41 3.059 8.25 8.25 0 006.409-3.058c-2.059-.96-4.207-1.45-6.41-1.45-2.202 0-4.35.49-6.409 1.45z"
      fill="#fff"
    />
  </svg>
		),
  },
  {
    label: 'Agencies',
    path: '/agencies/',
    color: theme.colors.royalBlue,
    icon:
		(
  <svg width={22} height={23} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.001.833C5.111.833.334 5.609.334 11.5c0 5.89 4.776 10.666 10.667 10.666 5.892 0 10.667-4.775 10.667-10.666S16.893.833 11.001.833zm.424 4.267v1.723l3.544.982a1 1 0 01.732.964v1.67a7.315 7.315 0 01-1.442 4.371 7.669 7.669 0 01-2.834 2.348v1.809a9.328 9.328 0 004.213-3.19 8.935 8.935 0 001.763-5.339v-2.92a1 1 0 00-.733-.964L11.425 5.1zm-3.683 9.71a7.669 7.669 0 002.834 2.348v1.809a9.328 9.328 0 01-4.213-3.19A8.935 8.935 0 014.6 10.439v-2.92a1 1 0 01.732-.964L10.576 5.1v1.722l-3.543.983a1 1 0 00-.733.963v1.67c0 1.582.498 3.093 1.442 4.372z"
      fill="#fff"
    />
  </svg>
		),
  },
  {
    label: 'Favorites',
    path: '/favorites/',
    color: theme.colors.profileFavorite,
    icon:
		(
  <svg width={18} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.219.113c-.977 0-1.872.327-2.662.971C9.8 1.702 9.297 2.49 9 3.061c-.297-.572-.8-1.359-1.557-1.977C6.653.44 5.758.114 4.78.114 2.056.113 0 2.466 0 5.586c0 3.372 2.564 5.679 6.447 9.17.659.594 1.406 1.266 2.183 1.983a.545.545 0 00.74 0c.777-.717 1.524-1.39 2.184-1.982C15.436 11.265 18 8.959 18 5.588 18 2.466 15.944.112 13.219.112z"
      fill="#fff"
    />
  </svg>
		),
  },
  {
    label: 'Feedback',
    path: '/feedback/',
    color: theme.colors.profileFeedback,
    icon:
		(
  <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.487.833h-3.64C3.933.833 0 2.393 0 8.68c0 2.293.433 7.54 7.243 7.834-.23 1.71-1.475 2.576-1.53 2.615a.566.566 0 00.314 1.037c2.845 0 5.116-2.777 5.754-3.643 4.631-.094 7.552-2.439 7.552-7.843 0-6.286-3.933-7.847-7.846-7.847z"
      fill="#fff"
    />
  </svg>
		),
  },
  // {
  //   label: 'Notifications',
  //   path: '/notifications',
  //   color: theme.colors.gold,
  //   hasBudget: true,
  //   icon:
	// 	(
  // <svg width={22} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <path
  //     d="M16 7a5.38 5.38 0 0 0-4.46-4.85C11.6 1.46 11.53 0 10 0S8.4 1.46 8.46 2.15A5.38 5.38 0 0 0 4 7v6l-2 2v1h16v-1l-2-2zm-6 13a3 3 0 0 0 3-3H7a3 3 0 0 0 3 3z"
  //     fill="#fff"
  //   />
  // </svg>
	// 	),
  // },
  {
    label: 'Settings',
    path: '/settings',
    color: theme.colors.alertPink,
    icon:
		(
  <svg width={22} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.51 8.72l-2.574-.603a7.721 7.721 0 00-.541-1.255L19.71 4.77a.591.591 0 00-.09-.75l-1.83-1.748a.665.665 0 00-.787-.086L14.81 3.441a8.528 8.528 0 00-1.315-.517L12.878.468A.657.657 0 0012.246 0H9.668a.657.657 0 00-.632.468l-.618 2.456a8.53 8.53 0 00-1.315.517L4.91 2.186a.664.664 0 00-.786.086L2.295 4.02a.591.591 0 00-.09.75l1.314 2.092c-.219.406-.4.824-.541 1.255L.49 8.72a.613.613 0 00-.49.59v2.461c0 .284.206.53.49.591l2.488.603c.142.43.322.85.541 1.255l-1.315 2.092a.591.591 0 00.09.75l1.831 1.748a.664.664 0 00.786.086l2.192-1.255c.425.209.863.381 1.315.517l.618 2.374c.078.271.335.468.632.468h2.578a.657.657 0 00.632-.468l.618-2.374a8.52 8.52 0 001.315-.517l2.192 1.255c.258.148.58.11.786-.086l1.83-1.747a.591.591 0 00.09-.751l-1.314-2.092c.22-.406.4-.824.541-1.255l2.574-.603a.613.613 0 00.49-.59V9.31a.613.613 0 00-.49-.591zm-10.553 4.897c-1.779 0-3.223-1.378-3.223-3.076 0-1.698 1.444-3.076 3.223-3.076s3.223 1.378 3.223 3.076c0 1.698-1.444 3.076-3.223 3.076z"
      fill="#fff"
    />
  </svg>
		),
  },
  // {
  //   label: 'Payments',
  //   path: '/payments',
  //   color: theme.colors.gold,
  //   icon:
	// 	(
  //   <svg enable-background="new 0 0 780 500" height={22} viewBox="0 0 780 500" width={30} xmlns="http://www.w3.org/2000/svg"><path d="m293.2 348.73 33.359-195.76h53.358l-33.384 195.76zm246.11-191.54c-10.569-3.966-27.135-8.222-47.821-8.222-52.726 0-89.863 26.551-90.181 64.604-.297 28.129 26.515 43.822 46.754 53.185 20.771 9.598 27.752 15.716 27.652 24.283-.133 13.123-16.586 19.115-31.924 19.115-21.355 0-32.701-2.967-50.225-10.273l-6.878-3.111-7.487 43.822c12.463 5.467 35.508 10.199 59.438 10.445 56.09 0 92.502-26.248 92.916-66.885.199-22.27-14.016-39.215-44.801-53.188-18.65-9.056-30.072-15.099-29.951-24.269 0-8.137 9.668-16.838 30.56-16.838 17.446-.271 30.088 3.534 39.936 7.5l4.781 2.259zm137.31-4.223h-41.23c-12.772 0-22.332 3.486-27.94 16.234l-79.245 179.4h56.031s9.159-24.121 11.231-29.418c6.123 0 60.555.084 68.336.084 1.596 6.854 6.492 29.334 6.492 29.334h49.512l-43.187-195.64zm-65.417 126.41c4.414-11.279 21.26-54.724 21.26-54.724-.314.521 4.381-11.334 7.074-18.684l3.606 16.878s10.217 46.729 12.353 56.527h-44.293zm-363.3-126.41-52.239 133.5-5.565-27.129c-9.726-31.274-40.025-65.157-73.898-82.12l47.767 171.2 56.455-.063 84.004-195.39-56.524-.001" fill="#0e4595"/><path d="m146.92 152.96h-86.041l-.682 4.073c66.939 16.204 111.23 55.363 129.62 102.42l-18.709-89.96c-3.229-12.396-12.597-16.096-24.186-16.528" fill="#f2ae14"/></svg>		),
  // },
];
