import React from 'react'
import { Draggable } from "react-beautiful-dnd";
import styled from 'styled-components';
import ListItem from '../Athletes/ListItem';

const AthleteCard = ({ 
  item, 
  index,
  rating,
  relationID,
  athleteID, 
  coachID,
  ratingIsChanged,
  setRatingIsChanged,
  includeRatingStars,
  userCoachData,
  favoriteToggle,
  hasTags
}) => {
  return (
    <Draggable key={item.id} draggableId={item.athleteID} index={index}>
      {(provided, snapshot) => {
        return (
          <AthleteCardDiv
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <ListItem 
              athlete={item.athlete} 
              key={item.athlete.id} 
              path={`/favorites/athlete_${item.athlete.id}`} 
              rating={ rating }
              relationID= {relationID}
              athleteID={athleteID}
              coachID={coachID}
              ratingIsChanged={ratingIsChanged}
              setRatingIsChanged={setRatingIsChanged}
              includeRatingStars={includeRatingStars}
              userCoachData={userCoachData}
              favoriteToggle={favoriteToggle}
              hasTags={hasTags}
            />
          </AthleteCardDiv>
        );
      }}
    </Draggable>
  );
}

export default AthleteCard

const AthleteCardDiv = styled.div`
  userSelect: none;
  minHeight: 50px;
  color: white;
  border-radius: 4px;    
`